import PerfectScrollbar from 'react-perfect-scrollbar'
import InterestedListings from "./ListingSuggestions/InterestedListings";
import ViewedListings from "./ListingSuggestions/ViewedListings";
import MatchedListings from "./ListingSuggestions/MatchedListings";
import styles from './SuggestedListings.module.scss';
import SuggestedListingContext from '../Contexts/SuggestedListingsContext';
import { Component } from 'react';

export const SUGGESTED_LISTINGS_SECTIONS = {
  interested: 'interested',
  matched: 'matched',
  recentlyViewed: 'recentlyViewed',
}

class SuggestedListings extends Component {

  state = {
    selectedList: SUGGESTED_LISTINGS_SECTIONS.interested,
  };

  renderListOptions() {
    return (
      <div className="row px-0 mx-0 border-bottom">
        <div className="m-0 col-4 d-flex flex-column justify-content-start align-items-center">
          <button
            className={`btn btn-default border-top-0 border-right-0 border-left-0 rounded-0 ${this.state.selectedList === 'interested' ? styles['active-list'] : 'border-bottom-0'}`}
            onClick={() => this.setState({selectedList: SUGGESTED_LISTINGS_SECTIONS.interested})}
          >
            Interested
          </button>
        </div>
        <div className="m-0 col-4 d-flex flex-column justify-content-start align-items-center">
          <button
            className={`btn btn-default border-top-0 border-right-0 border-left-0 rounded-0 ${this.state.selectedList === 'matched' ? styles['active-list'] : 'border-bottom-0'}`}
            onClick={() => this.setState({selectedList: SUGGESTED_LISTINGS_SECTIONS.matched})}
          >
            Matched
          </button>
        </div>
        <div className="m-0 col-4 d-flex flex-column justify-content-start align-items-center">
          <button
            className={`btn btn-default border-top-0 border-right-0 border-left-0 rounded-0 ${this.state.selectedList === 'viewed' ? styles['active-list'] : 'border-bottom-0'}`}
            onClick={() => this.setState({selectedList: SUGGESTED_LISTINGS_SECTIONS.recentlyViewed})}
          >
            Viewed
          </button>
        </div>
      </div>
    )
  }

  renderList() {
    const listOptions = {
      [SUGGESTED_LISTINGS_SECTIONS.interested]: <InterestedListings />,
      [SUGGESTED_LISTINGS_SECTIONS.recentlyViewed]: <ViewedListings />,
      [SUGGESTED_LISTINGS_SECTIONS.matched]: <MatchedListings />
    };

    return (
      <PerfectScrollbar className="w-100 bg-light" style={{ height: this.props.height }}>
        {listOptions[this.state.selectedList]}
      </PerfectScrollbar>
    );
  }

  render() {
    return (
      <div className="card" id="suggested-listings">
        <div className="card-header">
          <h5 className="mb-2">{this.props.title}</h5>
        </div>
        <div className="card-body px-0 pb-0">
          <SuggestedListingContext.Provider
              value={{
                selectedIds: this.props.selectedIds,
                onSelect: this.props.onSelect,
                onUnselect: this.props.onUnselect,
              }}>
            {this.renderListOptions()}
            {this.renderList()}
          </SuggestedListingContext.Provider>
        </div>
      </div>
    );
  }
}

SuggestedListings.defaultProps = {
  height: 400,
}

export default SuggestedListings;
