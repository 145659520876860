import {
  IoIosStar, IoIosStarOutline
} from "react-icons/io";
import styles from './StarRating.module.scss';
import { Component } from 'react';
import { HbAnalyticsClass } from '../../Common/AnalyticClasses'

class StarRating extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rating: this.props.rating || 0,
      hoverRating: 0,
      hover: false
    };
  }

  componentDidUpdate(){
    if(this.props.rating && this.props.rating !== this.state.rating){
      this.setState({rating: this.props.rating})
    }
  }

  onStarSelect = (i) => {
    this.setState({rating: i})
    this.props.onStarSelect(i)
  }

  render(){
    const currentRating = this.state.hover ? this.state.hoverRating : this.state.rating
    return(
        <>
          {
            [1,2,3,4,5].map((i) => {
              const isSelected = currentRating >= i || this.props.loading;
              return(
                <button key={i} type="button"
                        className={`btn icon-btn btn-sm ${HbAnalyticsClass('star-button')} mx-1 ${isSelected ? `btn-warning ${styles.selected}` : `btn-outline-warning ${styles.unselected}`}`}
                        disabled={this.props.loading}
                        onClick={() => this.onStarSelect(i)}
                        onMouseEnter={() => this.setState({hover: true, hoverRating: i})}
                        onMouseLeave={() => this.setState({hover: false, hoverRating: 0})}>
                 {isSelected ? <IoIosStar style={{fontSize: 16, color: 'white'}} /> : <IoIosStarOutline style={{fontSize: 16, color: 'orange'}} />}
                </button>
              )
            })
          }
        </>
    )
  }
}

export default StarRating;
