import { Component, createRef } from 'react';
import Select from 'react-select-2';
import {FiltersContext} from './../../Contexts/FiltersContext';
import {
  maxPrices,
  minPrices,
} from './../../../Utils/SearchUtils';
import styles from './ExclusivesFilters.module.scss';
import CustomPriceSelector from "../../../Common/CustomPriceSelector";

class ExclusivesFilters extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  static contextType = FiltersContext;

  componentDidMount(){
    const node = document.getElementById('filtersDropdown');
    new MegaDropdown(node);
  }

  renderSelect = (fieldName, placeholder, options) => {
    const selectedOption = options.find(option => option.value === this.context.mapFilters[fieldName]);
    return <Select
        value={selectedOption}
        onChange={(selectedOption) => this.context.changeMapFilter({[fieldName]: selectedOption.value})}
        options={options}
        placeholder={placeholder}
        className="w-100"
        classNamePrefix={fieldName}
    />
  };

  render() {
    const {changeMapFilter, mapFilters} = this.context;

    return (
        <div className="input-group input-group-round">
          <div className={`btn-group ${styles['price-filter']}`}>
            <CustomPriceSelector
              fieldName='price_min'
              placeholder='Price Min'
              options={minPrices}
              currentFilterOption={this.context.mapFilters['price_min']}
              changeFilter={(value) => this.context.changeMapFilter({['price_min']: value})}
            />
          </div>
          &nbsp;
          <div className={`btn-group ${styles['price-filter']}`}>
            <CustomPriceSelector
              fieldName='price_max'
              placeholder='Price Max'
              options={maxPrices}
              currentFilterOption={this.context.mapFilters['price_max']}
              changeFilter={(value) => this.context.changeMapFilter({['price_max']: value})}
            />
          </div>
          &nbsp;
          <div className="mega-dropdown">
            <div className="btn btn-primary dropdown-toggle navbar-example-19-mega-dropdown" id="filtersDropdown"   href="#" data-toggle="mega-dropdown" ref={this.myRef} >
              Filters
            </div>
            <div className={`dropdown-menu p-4 ${styles['filter-dropdown']}`}>
              <div className="row mb-2">
                <div className="col d-flex flex-row">
                  <label className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input"
                           onChange={() => changeMapFilter({ no_price_filter: !mapFilters.no_price_filter})}
                           checked={mapFilters.no_price_filter}/>
                    <span className="custom-control-label">Include listings with undisclosed prices</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
    )
  }
}

export default ExclusivesFilters;
