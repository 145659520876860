import {useState, useContext} from "react"
import SplitLayout, {listingSearchFragment} from '../Common/SplitLayout';
import {gql, useQuery} from '@apollo/client';
import {FiltersContext} from './../Contexts/FiltersContext';
import {CurrentUserConsumer} from "../Contexts/CurrentUserContext";
import {Helmet} from "react-helmet";
import {SplitLayoutProvider} from "../Contexts/SplitLayoutContext";
import {reportError} from "../../Common/ErrorCapture";
import SignUpModal from "../Layout/SignUpModal";
import ExclusivesFilters from './Exclusives/ExclusivesFilters';

const sortOptions = [
  {label: 'Newest On Site', value: 'NEWEST' },
  {label: 'Lowest Price', value: 'PRICE_LOW' },
  {label: 'Highest Price', value: 'PRICE_HIGH'}
];

const EXCLUSIVES_QUERY = gql`query ExclusivesSearch($data: ExclusivesSearchInput!) {
    current_user {
        id
        searcher:exclusives_search(searcher_arguments: $data) {
            ...ListingSearch
        }
    }
}
${listingSearchFragment}
`

const Exclusives = () => {

  const filters = useContext(FiltersContext)

  const [bounds, setBounds] = useState({})
  const [selectedSort, setSelectedSort] = useState(sortOptions[0])

  const onMapMove = (bounds) => {
    setBounds(bounds)
    filters.setSearchBounds(bounds)
  };

  const onSortChange = (sortOption) => {
    setSelectedSort(sortOption)
  };

  const setGeoshape = ({current_user}) => {
    if (current_user?.searcher?.geo_shapes && current_user.searcher.geo_shapes.length > 0) {
      filters.setSearchGeoshape(current_user.searcher.geo_shapes[0])
    } else if (filters.searchGeoshape) {
      filters.setSearchGeoshape(null)
    }
  };


  const {loading, error, data, previousData} = useQuery(EXCLUSIVES_QUERY, {
    variables: {
      data: {
        sort: selectedSort.value,
        ...bounds,
        ...filters.exclusivesParams,
      }
    },
    onCompleted: setGeoshape,
    onError: reportError,
    fetchPolicy:"network-only",
    nextFetchPolicy:"cache-first"
  })

  let searchData = {listings: []}
  if(data && data.current_user){
    searchData = data.current_user.searcher
  }else if(previousData){
    searchData = previousData.current_user.searcher
  }

  return (
      <div>
        <Helmet title="Exclusives"/>
        <SplitLayoutProvider
            title="Exclusives"
                             searchData={searchData}
                             onMapMove={onMapMove}
                             sortOptions={sortOptions}
                             onSortChange={onSortChange}
                             selectedSort={selectedSort}
                             isExclusivesSearch={true}
                             loading={loading}>
          <SplitLayout>
            <ExclusivesFilters/>
          </SplitLayout>
        </SplitLayoutProvider>
        <CurrentUserConsumer>
          {({loggedIn, displayRules, showingSignUp}) => {
            if(!showingSignUp && !loggedIn && displayRules.exclusives_protected){
              return(
                  <SignUpModal showingSignUp={true} closeSignUp={() => {}} title={'Sign Up'} subtitle={'You must be logged in to view exclusive listings'}/>
              )
            }
          }}
        </CurrentUserConsumer>
      </div>
  );
}

export default Exclusives;