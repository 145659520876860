import RsBadge from '../../../../Common/UIComponents/Badge/RsBadge'

import {
  FOR_SALE,
  FOR_RENT,
  COMING_SOON,
  EXCLUSIVE,
  IN_CONTRACT,
  INQUIRE,
  SOLD,
  RENTED,
  UNKNOWN,
  OFF_MARKET,
} from '../../../../Common/Constants/PropertyConstants'

const DisplayStatusBadge = ({ displayStatus, combinedDisplayStatus, soldDate }) => {
  let color
  let text
  if ([FOR_SALE, FOR_RENT, COMING_SOON, EXCLUSIVE].includes(displayStatus)) {
    color = 'success'
    text = combinedDisplayStatus
  } else if ([IN_CONTRACT, INQUIRE].includes(displayStatus)) {
    color = 'warning'
    text = combinedDisplayStatus
  } else if ([UNKNOWN, OFF_MARKET].includes(displayStatus)) {
    color = 'error'
    text = combinedDisplayStatus
  } else if ([SOLD, RENTED].includes(displayStatus)) {
    color = 'error'
    text = `${combinedDisplayStatus} on: ${soldDate}`
  } else {
    color = 'primary'
    text = combinedDisplayStatus
  }

  return <RsBadge color={color} size="small" label={text} />
}

export default DisplayStatusBadge
