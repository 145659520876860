import moment from 'moment/moment'
import { gql, useQuery } from '@apollo/client'
import { CardContent, Skeleton, Typography, Divider, Stack, Box, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import ActivityOverTimeLineChart from '../../../Common/ActivityOverTimeLineChart'

const MARKET_ACTIVITY_BUYERS_QUERY = gql`
  query marketActivityBuyersQuery($marketAlertId: ID!, $start_time: Time, $interval: MetricIntervalEnum) {
    current_user {
      id
      timezone
      market_alert: market_alert_by_id(id: $marketAlertId) {
        id
        report(start_time: $start_time, interval: $interval) {
          active_buyer_count
          total_activity_count
          unique_users_over_time {
            metric_value
            start_time
          }
        }
      }
    }
  }
`

const MarketBuyerActivity = ({ marketAlertId, startTime }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const { data, loading } = useQuery(MARKET_ACTIVITY_BUYERS_QUERY, {
    variables: {
      marketAlertId,
      start_time: startTime.value,
      interval: startTime.interval,
    },
  })

  if (loading) {
    return (
      <RsCard sx={{ mb: 2 }}>
        <CardContent>
          <Skeleton variant="rectangular" height={400} />
        </CardContent>
      </RsCard>
    )
  }

  const marketAlertReport = data.current_user.market_alert.report

  const dateRange = {
    from: startTime.value,
    to: new moment().startOf('day').toDate(),
  }

  return (
    <>
      <RsCard title="Market Activity Statistics" cardSx={{ mb: 2 }}>
        <Stack justifyContent="space-around" alignItems="center" spacing={1} divider={<Divider orientation={isSmallerThanMd ? 'horizontal' : 'vertical'} flexItem />} direction={{ xs: 'column', md: 'row' }}>
          <Box
            sx={{
              width: '100%',
            }}
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography gutterBottom variant="h6">
                # of Active Buyers
              </Typography>
            </Box>
            <Typography variant="h5">{marketAlertReport.active_buyer_count}</Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography gutterBottom variant="h6">
                # of Buyer Interactions
              </Typography>
            </Box>
            <Typography variant="h5">{marketAlertReport.total_activity_count}</Typography>
          </Box>
        </Stack>
      </RsCard>

      {marketAlertReport.unique_users_over_time.length > 0 && (
        <RsCard title="Active Buyers Over Time" cardSx={{ mb: 2 }}>
          <CardContent>
            <ActivityOverTimeLineChart
              displayOnlyChart
              data={marketAlertReport.unique_users_over_time}
              chartTitle="Buyers"
              metricInterval={startTime.interval}
              dateRange={dateRange}
              shouldHideLegend
              timezone={data.current_user.timezone}
            />
          </CardContent>
        </RsCard>
      )}
    </>
  )
}

export default MarketBuyerActivity
