import { Component, createContext } from 'react';
import { CollectionContext } from './CollectionContext'

let SplitLayoutContext
const { Provider, Consumer } = (SplitLayoutContext = createContext())

class SplitLayoutProvider extends Component {
  static contextType = CollectionContext

  state = {
    selectedListing: null,
    mobileSelectedListing: null,
    mapRefreshNeeded: false,
    searchAreaLabel: null,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const listings = this.props.searchData.listings
    if (this.context.collection.length !== listings.length) {
      if (listings.length > 0) {
        this.setState({ selectedListing: listings[0] })
      }
      this.context.updateCollection(listings)
    } else {
      const doArraysMatch = listings.every((element, index) => {
        return element.id === this.context.collection[index].id
      })
      if (!doArraysMatch) {
        if (listings.length > 0) {
          this.setState({ selectedListing: listings[0] })
        }
        this.context.updateCollection(listings)
      }
    }
  }

  setSelectedListing = (listing, isMobile = false) => {
    if (isMobile) {
      this.setState({ mobileSelectedListing: listing })
    } else {
      this.setState({ selectedListing: listing })
    }
  }

  setSearchAreaLabel = label => {
    this.setState({ searchAreaLabel: label })
  }

  flagMapRefreshNeeded = refreshNeeded => {
    this.setState({ mapRefreshNeeded: refreshNeeded })
  }

  render() {
    return (
      <Provider
        value={{
          title: this.props.title,
          current_count: this.props.searchData.current_count,
          total_count: this.props.searchData.total_count,
          listings: this.props.searchData.listings,
          geo_shapes: this.props.searchData.geo_shapes,
          geo_circles: this.props.searchData.geo_circles,
          onMapMove: this.props.onMapMove,
          loading: this.props.loading,
          sortOptions: this.props.sortOptions,
          onSortChange: this.props.onSortChange,
          selectedSort: this.props.selectedSort,
          isSearchPage: this.props.isSearchPage,
          setSelectedListing: this.setSelectedListing,
          selectedListing: this.state.selectedListing,
          selectedListingId: this.state.selectedListing ? this.state.selectedListing.id : null,
          mobileSelectedListing: this.state.mobileSelectedListing,
          isExclusivesSearch: this.props.isExclusivesSearch,
          searchAreaLabel: this.state.searchAreaLabel,
          setSearchAreaLabel: this.setSearchAreaLabel,
          mapRefreshNeeded: this.state.mapRefreshNeeded,
          flagMapRefreshNeeded: this.flagMapRefreshNeeded,
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export { SplitLayoutProvider, Consumer as SplitLayoutConsumer, SplitLayoutContext }
