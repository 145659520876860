import {
  Container,
  Box
} from '@mui/material'

const PageSection = ({ containerWidth, whiteBg, children }) => {

  const makeWhiteBgStyles = (theme) => {
    return {backgroundColor: theme.colors.alpha.white[100]}
  }

  return (
    <Box sx={whiteBg ? makeWhiteBgStyles : {}}>
      <Container maxWidth={containerWidth || 'md'} sx={{py: 12}}>
        { children }
      </Container>
    </Box>
  )
}

export default PageSection
