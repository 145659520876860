import {
  IoIosHeart,
  IoMdHeartDislike,
  IoIosRemoveCircle
} from "react-icons/io";
import FeaturePreference from "../../Common/FeaturePreference";
import { Component } from 'react';

class UserSentiments extends Component {
  state = {
    isPopOverOpen: false,
  };

  sortFeaturePreferencesBySentiment(userFeaturePreferences) {
    return userFeaturePreferences.reduce((accum, current) => {
      if (current.name) {
        accum[current.sentiment].push(current.name);
      }
      return accum;
    }, {like: [], dislike: [], neutral: []});
  }

  render() {
    const sortedFeaturePreferencesBySentiment = this.sortFeaturePreferencesBySentiment(this.props.featurePreferences);

    return (
      <div className="card my-4" id="likes-dislikes">
        <div className="card-header">Likes and Dislikes</div>
        <div className="card-body">
          <div className="row">
            <div className="col-2 d-flex flex-row align-items-center text-success ">
              <strong>{sortedFeaturePreferencesBySentiment.like.length} Likes</strong>
            </div>
            <div className="col-9">
              <div className="row">
                {sortedFeaturePreferencesBySentiment.like.map(featurePreference => {
                  return (
                    <FeaturePreference
                      key={featurePreference}
                      featurePreference={featurePreference}
                      sentiment={'like'}
                      onMutationCompleted={(data) => this.props.updateFeaturePreferences(data.set_feature_preference_sentiment.user.feature_preferences)}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-2 d-flex flex-row align-items-center text-danger ">
              <strong>{sortedFeaturePreferencesBySentiment.dislike.length} Dislikes</strong>
            </div>
            <div className="col-9">
              <div className="row">
                {sortedFeaturePreferencesBySentiment.dislike.map(featurePreference => {
                  return (
                    <FeaturePreference
                      key={featurePreference}
                      featurePreference={featurePreference}
                      sentiment={'dislike'}
                      onMutationCompleted={(data) => this.props.updateFeaturePreferences(data.set_feature_preference_sentiment.user.feature_preferences)}
                    />
                  )
                })}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-2 d-flex flex-row align-items-center text-secondary ">
              <strong>{sortedFeaturePreferencesBySentiment.neutral.length} Neutral</strong>
            </div>
            <div className="col-9">
              <div className="row">
                {sortedFeaturePreferencesBySentiment.neutral.map(featurePreference => {
                  return (
                    <FeaturePreference
                      key={featurePreference}
                      featurePreference={featurePreference}
                      sentiment={'neutral'}
                      onMutationCompleted={(data) => this.props.updateFeaturePreferences(data.set_feature_preference_sentiment.user.feature_preferences)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      );
  }
}

export default UserSentiments;
