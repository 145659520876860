import { gql, useQuery } from '@apollo/client'
import HomeReportLayout, { HOME_REPORT_FULL_FRAGMENT, AGENT_FRAGMENT_FIELDS } from '../Pages/HomeReport/HomeReportLayout'
import LoadingIcon from '../../Legacy/RsLoadingCircle'

const HOME_REPORT_QUERY = gql`
  ${HOME_REPORT_FULL_FRAGMENT}
  ${AGENT_FRAGMENT_FIELDS}
  query homeReportQuery($userId: ID!, $homeReportId: ID!) {
    current_agent {
      ...AgentFields
      user: user_by_id(id: $userId) {
        id
        home_report: home_report_by_id(id: $homeReportId) {
          ...FullReportFragment
        }
      }
    }
  }
`

const HomeReport = ({ match }) => {
  const { userId, homeReportId } = match.params
  const { data, loading, error } = useQuery(HOME_REPORT_QUERY, {
    variables: {
      homeReportId,
      userId,
    },
  })

  if (loading) {
    return <LoadingIcon />
  }

  const agent = data.current_agent
  const homeReport = agent.user.home_report

  return <HomeReportLayout previewMode homeReport={homeReport} agent={agent} />
}

export default HomeReport
