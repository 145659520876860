import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import {
  Box,
  Button,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import RsLocationSelector from '@/components/Common/UIComponents/Input/RsLocationSelector'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'
import PriceSelector from '@/components/Common/PriceSelector'
import { bathChoices, bedChoices, maxPrices, minPrices } from '@/components/Utils/SearchUtils'
import { useInput } from '@/components/CustomHooks/InputHook'
import React, { useState } from 'react'
import {
  DEFAULT_SIMPLE_PROPERTY_TYPES,
  CLIENT_SCHEDULE_OPTIONS,
  DAILY,
} from '@/components/Common/Constants/ListingAlertConstants'
import { gql, useMutation, useQuery } from '@apollo/client'
import RsPageLoading from '@/components/Common/UIComponents/PageLayout/RsPageLoading'
import useMutationSnackbarMessages from '@/components/CustomHooks/MutationSnackbarHooks'

const CREATE_SAVED_SEARCH_MUTATION = gql`
  mutation createSavedSearch($input: CreateSavedSearchInput!) {
    create_saved_search(input: $input) {
      saved_search {
        id
      }
      errors {
        key
        message
      }
    }
  }
`

const SetupSearchForm = ({ searchableSimpleTypes, userId }) => {
  const [priceMin, setPriceMin] = useState(null)
  const [priceMax, setPriceMax] = useState(null)
  const [forRent, setForRent] = useState(false)
  const [forSale, setForSale] = useState(true)
  const [propertyTypes, setPropertyTypes] = useState(DEFAULT_SIMPLE_PROPERTY_TYPES)
  const [geoLocations, setGeoLocations] = useState([])
  const { value: bedsMin, bind: bindBedsMin } = useInput('')
  const { value: bathsMin, bind: bindBathsMin } = useInput('')
  const { value: schedule, bind: bindSchedule } = useInput(DAILY)

  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'create_saved_search',
    customSuccessMessage: 'Alert created! Redirecting...',
    afterCompletedFn: ({ saved_search }) => {
      if (saved_search) {
        window.location.href = `/homesearch/searches/${saved_search.id}`
      }
    },
  })

  const [createSavedSearch, { loading }] = useMutation(
    CREATE_SAVED_SEARCH_MUTATION,
    mutationSnackbarMessages
  )

  const togglePropertyType = pType => {
    let newPropTypes
    if (propertyTypes.includes(pType)) {
      newPropTypes = propertyTypes.filter(x => x !== pType)
    } else {
      newPropTypes = [...propertyTypes, pType]
    }
    setPropertyTypes(newPropTypes)
  }

  const toggleGeoLocation = gLocation => {
    let newGeoLocations
    if (geoLocations.map(gl => gl.value).includes(gLocation.value)) {
      newGeoLocations = geoLocations.filter(gl => gl.value !== gLocation.value)
    } else {
      newGeoLocations = [...geoLocations, gLocation]
    }
    setGeoLocations(newGeoLocations)
  }

  const onSave = () => {
    createSavedSearch({
      variables: {
        input: {
          user_id: userId,
          price_min: priceMin,
          price_max: priceMax,
          beds_min: bedsMin || null,
          baths_min: bathsMin || null,
          for_sale: forSale,
          for_rent: forRent,
          property_types: propertyTypes,
          geo_ids: geoLocations.map(gl => gl.value),
          schedule: schedule,
        },
      },
    })
  }

  return (
    <RsCard title="What kind of homes are you interested in?">
      <CardContent>
        <Stack divider={<Divider />} spacing={2}>
          <Box>
            <Typography variant="h6" mb={1}>
              Select Locations
            </Typography>
            <RsLocationSelector onSelect={(e, selected) => toggleGeoLocation(selected)} />
            <Box mt={2}>
              {geoLocations.map(gl => (
                <Chip
                  data-testid={gl.value}
                  key={gl.value}
                  label={gl.label}
                  sx={{ mr: 0.5, mb: 0.5 }}
                  onDelete={() => toggleGeoLocation(gl)}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" mb={2}>
              Price Range
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PriceSelector
                  fieldName="price_min"
                  id="price_min"
                  placeholder="Minimum Price"
                  options={minPrices}
                  currentFilterOption={priceMin}
                  changeFilter={value => setPriceMin(value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PriceSelector
                  fieldName="price_max"
                  id="price_max"
                  placeholder="Maximum Price"
                  options={maxPrices}
                  currentFilterOption={priceMax}
                  changeFilter={value => setPriceMax(value)}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6" mb={2}>
              Beds & Baths
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="beds-min-select-input-label">Bedrooms</InputLabel>
                  <Select
                    labelId="beds-min-select-label"
                    id="beds-min-select"
                    value={bedsMin}
                    label="Bedrooms"
                    {...bindBedsMin}
                  >
                    {bedChoices.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="baths-min-select-input-label">Bathrooms</InputLabel>
                  <Select
                    labelId="baths-min-select-label"
                    id="baths-min-select"
                    value={bathsMin}
                    label="Bathrooms"
                    {...bindBathsMin}
                  >
                    {bathChoices.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6">Status</Typography>
            <Grid container rowSpacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setForSale(!forSale)}
                      checked={forSale}
                      name="For Sale"
                    />
                  }
                  label="For Sale"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={() => setForRent(!forRent)}
                      checked={forRent}
                      name="For Rent"
                    />
                  }
                  label="For Rent"
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6">Property Types</Typography>
            <Grid container>
              {searchableSimpleTypes.map(simple_type => {
                if (simple_type.value === 'OTHER') return null

                return (
                  <Grid item xs={12} sm={6} key={simple_type.value}>
                    <FormControlLabel
                      sx={{ my: 0 }}
                      control={
                        <Checkbox
                          onChange={() => togglePropertyType(simple_type.value)}
                          checked={propertyTypes.includes(simple_type.value)}
                        />
                      }
                      label={simple_type.label}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Box>
          <Box>
            <Typography variant="h6" mb={2}>
              Receive emails about new listings
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="schedule-select-input-label">Schedule</InputLabel>
              <Select
                labelId="schedule-select-label"
                id="schedule-select"
                value={schedule}
                label="Schedule"
                {...bindSchedule}
              >
                {CLIENT_SCHEDULE_OPTIONS.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </CardContent>
      <RsCardFooterAction>
        <Button
          disabled={loading || geoLocations.length === 0}
          variant="contained"
          onClick={onSave}
          fullWidth
        >
          Save Search & View Results
        </Button>
      </RsCardFooterAction>
    </RsCard>
  )
}

const USER_BASICS_QUERY = gql`
  query agentUserBasicsQuery {
    current_user {
      id
    }
  }
`

const SetupSearch = ({ searchableSimpleTypes }) => {
  const { data, loading } = useQuery(USER_BASICS_QUERY)
  if (loading) return <RsPageLoading />
  return (
    <SetupSearchForm searchableSimpleTypes={searchableSimpleTypes} userId={data.current_user.id} />
  )
}
export default SetupSearch
