import {
  Box,
  Typography,
  ThemeProvider,
  Grid,
  Divider,
  Stack,
  Link,
  Alert,
  AlertTitle,
} from '@mui/material'
import { ApolloProvider } from '@apollo/client'
import { RealScoutTheme } from '../Theme'
import apolloClient from '../Apollo/HomebuyerClient'
import { SnackbarProvider } from 'notistack'
import Alerts from './Settings/Alerts'
import AccountSettings from './Settings/AccountSettings'
import Agent from './Settings/Agent'
import MuiErrorBoundarySimple from '../Common/MuiErrorBoundarySimple'

const Settings = ({ agent, user, unsubscribed }) => {
  return (
    <ThemeProvider theme={RealScoutTheme}>
      <SnackbarProvider maxSnack={3}>
        <ApolloProvider client={apolloClient}>
          <div>
            <Box style={{ backgroundColor: 'white' }}>
              <Box p={1}>
                <Link href="/homesearch/map">Back to full experience</Link>
              </Box>
              <Divider />
              <Box p={2} textAlign="center">
                <Typography variant="h1" mb={1}>
                  Account & Email Settings
                </Typography>
                <Typography variant="h6">Manage preferences for {user.email}</Typography>
              </Box>
              <Divider />
            </Box>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              paddingBottom={4}
              paddingX={1}
              paddingTop={2}
            >
              <Grid item xs={12} md={8} lg={6}>
                <Stack spacing={2}>
                  {unsubscribed && (
                    <Alert severity="success">
                      <AlertTitle>Unsubscribed</AlertTitle>You've successfully unsubscribed from
                      this email alert. Please allow up to 24 hours for this to take effect. You may
                      be subscribed to other alerts. Please review your settings below if you'd like
                      to make any other changes.
                    </Alert>
                  )}
                  <Agent agent={agent} />
                  <MuiErrorBoundarySimple>
                    <Alerts />
                  </MuiErrorBoundarySimple>
                  <AccountSettings userId={user.id} />
                </Stack>
              </Grid>
            </Grid>
          </div>
        </ApolloProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Settings
