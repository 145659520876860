import { useState } from 'react'
import { withCookies } from 'react-cookie'
import { alpha, Card, styled, Stack, Typography, Box, Button, Link } from '@mui/material'

const BannerWrapper = styled(Card)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 2)};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    background-color: ${alpha(theme.colors.primary.main, 0.95)};
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-radius: 0;
`
)

const TermsOfServiceBanner = ({ cookies }) => {
  const [hideBanner, setHideBanner] = useState(cookies.get('hideTOSBanner'))

  const onHideClick = () => {
    setHideBanner(true)
    cookies.set('hideTOSBanner', true, { path: '/' })
  }

  if (hideBanner) {
    return null
  }

  return (
    <BannerWrapper>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ width: '100%' }}
      >
        <Box>
          <Typography variant="h6" color="white">
            By using this site, you agree to our{' '}
            <Link href="/client-terms" target="_blank" color="white">
              <u>Terms of Service</u>
            </Link>{' '}
            &{' '}
            <Link href="/service-provider-privacy" target="_blank" color="white">
              <u>Privacy Policy</u>
            </Link>
            .
          </Typography>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onHideClick}
            sx={{
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white' },
            }}
          >
            Continue
          </Button>
        </Box>
      </Stack>
    </BannerWrapper>
  )
}

export default withCookies(TermsOfServiceBanner)
