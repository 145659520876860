import { createContext } from 'react'

export const ImagesContext = createContext(null)

const ImagesProvider = ({images, children}) => {
  return (
    <ImagesContext.Provider value={images}>
      { children }
    </ImagesContext.Provider>
  )
}

export default ImagesProvider
