import { useContext } from 'react'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'
import { Button } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import ListIcon from '@mui/icons-material/List'

export const VIEW_TYPE = {
  list: 'LIST',
  map: 'MAP',
}
const MapToggleButton = () => {
  const userContext = useContext(CurrentUserContext)

  const isMapView = userContext.mobileViewType === VIEW_TYPE.map
  const nextViewType = isMapView ? VIEW_TYPE.list : VIEW_TYPE.map
  const IconComponent = isMapView ? ListIcon : MapIcon
  const buttonText = isMapView ? 'List' : 'Map'

  return (
    <Button
      size="large"
      startIcon={<IconComponent size={'large'} />}
      variant="outlined"
      sx={{
        position: 'fixed',
        bottom: '30px',
        backgroundColor: '#FFF',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        '&:focus': { outline: 'none', backgroundColor: '#FFF' },
        '&:hover': { outline: 'none', backgroundColor: '#FFF' },
      }}
      onClick={() => userContext.changeViewType(nextViewType, true)}
    >
      {buttonText}
    </Button>
  )
}

export default MapToggleButton
