import {
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Box,
  Stack,
  Divider,
} from '@mui/material'
import HomeReportScheduleSelect from "../../../Common/ScheduleSelects/HomeReportScheduleSelect";
import RsCard from '../../../Common/UIComponents/Card/RsCard'

const HomeValueAlerts = ({ homeReports }) => {
  const alerts = homeReports.edges.map(homeReport => homeReport.node)
  return (
    <RsCard title="Home Value Alerts">
      <CardContent>
        <Stack divider={<Divider />} spacing={1}>
          {alerts.map(alert => {
            return (
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={alert.id}>
                <Box>
                  <Typography variant="body1">
                    <Link href={alert.client_url}>{alert.titleized_full_address}</Link>
                  </Typography>
                </Box>
                <Box width={150}>
                  <HomeReportScheduleSelect homeReport={alert} label="Frequency" excludeHandpickedOptions={true}/>
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </CardContent>
    </RsCard>
  )
}

export default HomeValueAlerts
