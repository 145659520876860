import { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Typography, CardContent, Grid } from '@mui/material'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import RsCard from '~/components/Common/UIComponents/Card/RsCard'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'
import LikeFeatureButton from './LikeFeatureButton'
import { LIKE } from '@/components/Common/Constants/FeaturePreferenceConstants'

const FEATURE_PREFERENCES_QUERY = gql`
  query FeaturePreferencesQuery {
    current_user {
      id
      feature_preferences {
        name
        sentiment
      }
    }
  }
`

export const LISTING_HIGHLIGHTS_FRAGMENT = gql`
  fragment listingHighlightsFragment on Property {
    id
    address
    tags
  }
`

const Highlights = ({ listingTags = [], address }) => {
  const { loggedIn } = useContext(CurrentUserContext)
  const { data } = useQuery(FEATURE_PREFERENCES_QUERY, {
    skip: !loggedIn,
  })

  const featurePreferences = data?.current_user?.feature_preferences || []
  const likedPreferences = featurePreferences
    .filter(featPref => featPref.sentiment === LIKE)
    .map(featPref => featPref.name)

  const isLiked = featPref => likedPreferences.includes(featPref)

  const sortedTags = listingTags.slice().sort()

  return (
    <RsCard
      cardProps={{ id: 'listing-highlights', sx: { boxShadow: 'none' } }}
      title={`${address} has these specific features, what do you think?`}
    >
      <CardContent>
        <Grid container spacing={1}>
          {sortedTags.map(featPref => (
            <Grid item xs={12} sm={6} key={featPref}>
              <LikeFeatureButton featureName={featPref} isLiked={isLiked(featPref)} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <RsCardFooterAction>
        <Typography variant="body2" color="text.secondary" px={1}>
          Features listed are aggregated by RealScout, and are not guaranteed to be accurate,
          up-to-date or complete.
        </Typography>
      </RsCardFooterAction>
    </RsCard>
  )
}

export default Highlights
