import isEqual from 'lodash/isEqual';
import {withRouter} from 'react-router-dom'
import {parseSearchUrl, parseSearchHash} from './../../Utils/SearchUtils';
import RsStorageUtils from "../../Utils/RsStorageUtils";
import store from 'store';
import { Component, createContext } from 'react';

let FiltersContext;
const {
  Provider,
  Consumer
} = (FiltersContext = createContext());

//Contains filters for both Search/Exclusives
const defaultFilters = {
  price_min: null,
  price_max: null,
  for_sale: true,
  for_rent: false,
  has_future_open_house_filter: false,
  beds_min: null,
  baths_min: null,
  property_types: ['CONDO', 'CONDOP', 'COOP', 'SFR', 'TC', 'TOWNHOUSE'],
  sqft_min: null,
  sqft_max: null,
  lot_min: null,
  lot_max: null,
  days_max: null,
  hoa_max: null,
  no_price_filter: true
};

class FiltersProvider extends Component {
  constructor(props){
    super(props);
    let mapFilters = Object.assign({}, defaultFilters);

    if(props.location.pathname === '/map' && props.location.search.length > 0){
      mapFilters = parseSearchUrl(mapFilters, props.location.search)
    }else if(props.location.pathname === '/map' && props.location.hash.length > 0){
     parseSearchHash(mapFilters, props.location.hash)
    }else {
      // Load Filters from LocalStorage
      const localStorageUpdatedFilters = Object.keys(defaultFilters).reduce((acc, currentKey) => {
        const searchCriteria = store.get('searchCriteria') || {};
        let value = searchCriteria[currentKey] || defaultFilters[currentKey];
        acc[currentKey] = value;
        return acc;
      }, {});
      mapFilters = localStorageUpdatedFilters
    }
    this.state = {
      mapFilters: mapFilters,
      isFilterDefaultFilter: true, // This will be overridden on componentDidMount
      searchBounds: null
    }
  }

  componentDidMount(){
    this.setState({
      isFilterDefaultFilter: this.isFilterDefaultFilter(this.state.mapFilters)
    })
  }

  onResetFilter = () => {
    store.remove('searchCriteria');
    this.setState({
      mapFilters: defaultFilters,
      isFilterDefaultFilter: true
    })
  };

  isFilterDefaultFilter = (newMapFilters) => {
    return Object.keys(newMapFilters).every((filterKey) => {
      if (Array.isArray(newMapFilters[filterKey])) {
        return isEqual(newMapFilters[filterKey].sort(), defaultFilters.property_types.sort());
      } else if (filterKey === 'price_min' || filterKey === 'price_max'|| filterKey === 'geo_id') {
        // Ignore these to only show icon if filters in dropdown change
        return true;
      } else {
        return newMapFilters[filterKey] === defaultFilters[filterKey];
      }
    })
  };

  changeLocalStorageMapFilter = (filterHash) => {
    let existingCriteria = store.get('searchCriteria') || {};
    const newCriteria = Object.assign({}, existingCriteria, filterHash);
    store.set('searchCriteria', newCriteria)
  };

  changeMapFilter = (filterHash) =>{
    const newMapFilters = Object.assign({}, this.state.mapFilters, filterHash);
    this.setState({
      mapFilters: newMapFilters,
      isFilterDefaultFilter: this.isFilterDefaultFilter(newMapFilters)
    }, () => this.changeLocalStorageMapFilter(filterHash))
  };

  setSearchBounds = (bounds) =>{
    this.setState({
      searchBounds: bounds
    })
  };

  setSearchGeoshape = (geoshape) =>{
    this.setState({
      searchGeoshape: geoshape
    })
  };

  togglePropertyType = (propType) => {
    let newPropTypes;
    const currentPropertyTypes = this.state.mapFilters.property_types;
    if(currentPropertyTypes.includes(propType)){
      newPropTypes = currentPropertyTypes.filter((x) => x !== propType)
    }else{
      newPropTypes = [...currentPropertyTypes, propType]
    }
    this.changeMapFilter({property_types: newPropTypes})
  };



  render() {
    //Assemble the exclusive params
    let exclusivesParams = {};
    ['price_min', 'price_max', 'geo_id', 'no_price_filter'].map((filterKey) => {
      if(this.state.mapFilters[filterKey]){
        exclusivesParams[filterKey] = this.state.mapFilters[filterKey];
      }
    });

    //Assemble the search params
    let searchParams =  Object.assign({}, this.state.mapFilters);
    delete searchParams['no_price_filter'];
    if(searchParams['polygon']){
      searchParams['geojson_polygons'] =[{
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [searchParams['polygon']['coordinates']]
        }
      }]
    }
    delete searchParams['polygon'];

    return (
        <Provider
            value={{
              mapFilters: this.state.mapFilters,
              searchParams: searchParams,
              exclusivesParams: exclusivesParams,
              changeMapFilter: this.changeMapFilter,
              searchableSimpleTypes: this.props.searchable_simple_types,
              onResetFilter: this.onResetFilter,
              togglePropertyType: this.togglePropertyType,
              isFilterDefaultFilter: this.state.isFilterDefaultFilter,
              setSearchBounds: this.setSearchBounds,
              searchBounds: this.state.searchBounds,
              setSearchGeoshape: this.setSearchGeoshape,
              searchGeoshape: this.state.searchGeoshape
            }}
        >
          {this.props.children}
        </Provider>
    );
  }
}


class FiltersProviderWrapper extends Component {
  render(){
    return(
        <FiltersProvider {...this.props}>
          {this.props.children}
        </FiltersProvider>
    )
  }
}

const FiltersProviderWithRouter = withRouter(FiltersProviderWrapper)

export {
  FiltersProvider,
  FiltersProviderWithRouter,
  Consumer as FiltersConsumer,
  FiltersContext
};
