import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from "@apollo/client/link/error";
import * as Sentry from '@sentry/react';
import cachePolicies from "./CachePolicies";

const httpLink = createHttpLink({uri: '/graphql-homebuyer', credentials: 'same-origin'});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  let operationName = '';
  let responseStatus = 'None'
  if(operation){
    operationName = operation.operationName;
  }
  if(operation && operation.getContext() && operation.getContext().response) {
    responseStatus = operation.getContext().response.status
  }
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, uuid }) => {
      Sentry.withScope(scope => {
        scope.setExtra("Message", message);
        if(uuid){
          scope.setExtra("HoneyBadger UUID", uuid)
        }
        scope.setExtra("Operation Name", operationName);
        scope.setExtra("Response Status", responseStatus)
        Sentry.captureException(new Error(`GraphQL error: ${operationName}`));
      });
    });
  } else if (networkError){
    Sentry.withScope(scope => {
      if (operation){
        scope.setExtra("Operation Name", operationName)
        scope.setExtra("Response Status", responseStatus)
      }
      Sentry.captureException(new Error(`[Network error]: ${networkError}`));
    });
  }
});

let authToken = document.getElementsByName('csrf-token');
authToken = authToken[0] ? authToken[0].content : null;

const middlewareLink = new ApolloLink((operation, forward) => {
  if(!operation.getContext()['headers']) {
    operation.setContext({
      headers: {
        'X-CSRF-Token': authToken
      }
    })
  }
  return forward(operation);
});

const link = ApolloLink.from([middlewareLink, errorLink, httpLink]);
const cache = new InMemoryCache(cachePolicies);

export default new ApolloClient({
  link,
  cache
});
