import React from 'react'
import { Typography, Stack } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import SchoolIcon from '@mui/icons-material/School'
import HouseIcon from '@mui/icons-material/House'
import { OMNI_RESULTS_CATEGORIES } from '@/components/Common/Constants/OmnisearchConstants'

const CategoryIcon = ({ groupCategory }) => {
  switch (groupCategory) {
    case OMNI_RESULTS_CATEGORIES.PLACES:
      return <PlaceIcon fontSize="small" />
    case OMNI_RESULTS_CATEGORIES.SCHOOLS:
      return <SchoolIcon fontSize="small" />
    case OMNI_RESULTS_CATEGORIES.ADDRESSES:
      return <HouseIcon fontSize="small" />
    default:
      return <HouseIcon fontSize="small" />
  }
}

const GroupHeader = ({ groupParams }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={{ my: 1 }}>
      <CategoryIcon groupCategory={groupParams.group} />
      <Typography variant="h6">{groupParams.group}</Typography>
    </Stack>
  )
}

export default GroupHeader
