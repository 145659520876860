import { FiltersContext } from '../../Contexts/FiltersContext'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { maxPrices, minPrices } from '../../../Utils/SearchUtils'
import PriceSelector from '../../../Common/PriceSelector'
import { useContext, useState } from 'react'
import { Box, Divider, Button, Drawer, Stack } from '@mui/material'
import CommonFilters from './Filters/CommonFilters'

// This component is rendered for tablet and desktop browser windows.
// Very small devices will instead see MobileSearchFilters.jsx
const SearchFilters = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const filtersContext = useContext(FiltersContext)
  const splitContext = useContext(SplitLayoutContext)

  const { onResetFilter } = filtersContext
  return (
    <div>
      <Stack direction="row" spacing={1} sx={{ pt: 0.3 }}>
        <Box sx={{ width: 120 }}>
          <PriceSelector
            fieldName="price_min"
            id="page_price_min"
            placeholder="Price Min"
            size="small"
            options={minPrices}
            currentFilterOption={filtersContext.mapFilters['price_min']}
            changeFilter={value => filtersContext.changeMapFilter({ ['price_min']: value })}
          />
        </Box>
        <Box sx={{ width: 120 }}>
          <PriceSelector
            fieldName="price_max"
            id="page_price_max"
            placeholder="Price Max"
            options={maxPrices}
            size="small"
            currentFilterOption={filtersContext.mapFilters['price_max']}
            changeFilter={value => filtersContext.changeMapFilter({ ['price_max']: value })}
          />
        </Box>
        <Button variant="contained" size="small" onClick={() => setModalOpen(true)} sx={{ py: 1, px: 2 }}>
          More Filters
        </Button>
      </Stack>

      <Drawer
        anchor="right"
        id="search-filters-drawer"
        PaperProps={{
          sx: { width: '50%' },
        }}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box sx={{ p: 2, mb: 8 }}>
          {/* These filters are shared with MobileSearchFilters.jsx */}
          <CommonFilters />
        </Box>
        <Box sx={{ position: 'fixed', bottom: 0, backgroundColor: 'white', width: '100%', zIndex: 2 }}>
          <Divider />
          <Box padding={1}>
            <Button variant="contained" onClick={() => setModalOpen(false)}>
              See {splitContext.total_count || splitContext.current_count} Listings
            </Button>
            <Button variant="text" size="small" sx={{ ml: 1 }} onClick={onResetFilter}>
              Reset Filters
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

export default SearchFilters
