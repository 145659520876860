import { gql, useMutation } from '@apollo/client'
import { Alert, Box, Button, Card, CardHeader, Divider, Link, useTheme, Stack } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSnackbar } from 'notistack'
import AgentValuation, { PrimaryValuation, ZestimateValuation, BlankAgentValuation } from './Valuations'
import ValuationDifferenceDisclaimer from './ValuationDifferenceDisclaimer'
import LoadingIcon from '../../../Legacy/RsLoadingCircle'

export const REQUEST_VALUATION_ESTIMATE = gql`
  mutation requestValuationEstimate($input: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
      }
    }
  }
`

export const CORE_VALUATION_FIELDS = gql`
  fragment CoreValuationFields on HomeReport {
    agent_valuation
    agent_valuation_updated_at
    agent_value_low
    agent_value_high
    valuation
    value_low
    value_high
    valuation_source
    valuation_source_logo
    percent_valuation_change
    previous_valuation_updated_at
    show_primary_valuation
    show_primary_valuation_range
    zestimate_valuation
    zestimate_value_high
    zestimate_value_low
    zestimate_property_url
    show_zestimate
    show_zestimate_range
    valuation_difference_disclaimer
    valuation_disclaimer
  }
`

const HomeValuation = ({
  valuation,
  valueLow,
  valueHigh,
  percentValuationChange,
  valuationChangeDate,
  valuationSource,
  valuationSourceLogo,
  showPrimaryValuation,
  showPrimaryValuationRange,
  agentValuation,
  agentValuationUpdatedAt,
  agentValueLow,
  agentValueHigh,
  agent,
  homeReportId,
  address,
  refreshingValuations,
  previewMode,
  zestimateValuation,
  zestimateValueHigh,
  zestimateValueLow,
  showZestimate,
  showZestimateRange,
  valuationDifferenceDisclaimer,
  valuationDisclaimer,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const theme = useTheme()
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'))

  const onCompleted = data => {
    const result = data.create_conversation_message
    if (result?.errors?.length) {
      const errorMessages = result.errors.map(e => `${e.key}: ${e.message}`).join(' ')
      enqueueSnackbar(`Errors: ${errorMessages}`, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }

    if (result.conversation) {
      enqueueSnackbar('Your message has been sent', {
        variant: 'success',
        autoHideDuration: 3000,
        action: (
          <Button href={`/homesearch/conversations/${result.conversation.id}`} style={{ color: 'white' }}>
            View Message
          </Button>
        ),
      })
    }
  }

  const [requestValuation, { loading }] = useMutation(REQUEST_VALUATION_ESTIMATE, {
    variables: {
      input: {
        attachment_ids: [homeReportId],
        body: `Valuation request for ${address}`,
        recipient: agent.id,
      },
    },
    onCompleted: data => onCompleted(data),
  })

  const requestValuationClick = () => {
    if (previewMode) return null
    requestValuation()
  }

  function showAgentValuation() {
    return agentValuation || (agentValueLow && agentValueHigh)
  }

  const externalValuations = [
    {
      key: 'primary',
      show: valuation && (showPrimaryValuation || showPrimaryValuationRange),
      component: (
        <PrimaryValuation
          {...{
            valuation,
            valuationSource,
            valuationSourceLogo,
            showValuation: showPrimaryValuation,
            valueHigh,
            valueLow,
            showRange: showPrimaryValuationRange,
            refreshingValuations,
            percentValuationChange,
            valuationChangeDate,
          }}
        />
      ),
    },
    {
      key: 'zestimate',
      show: showZestimate || showZestimateRange,
      component: (
        <ZestimateValuation
          {...{
            zestimateValuation,
            showZestimate,
            zestimateValueHigh,
            zestimateValueLow,
            showZestimateRange,
            refreshingValuations,
          }}
        />
      ),
    },
  ]

  const externalValuationsToDisplay = externalValuations.filter(valuation => valuation.show)

  const outerStackDirection = () => {
    let direction = ''

    if (xsOnly) {
      direction = 'column'
    } else if (externalValuationsToDisplay.length > 1) {
      direction = 'column'
    } else if (externalValuationsToDisplay.length === 1) {
      direction = 'row'
    }

    return direction
  }

  const innerStackDirection = () => {
    let direction = ''

    if (xsOnly) {
      direction = 'column'
    } else if (externalValuationsToDisplay.length > 1) {
      direction = 'row'
    } else if (externalValuationsToDisplay.length === 1) {
      direction = 'row'
    }

    return direction
  }

  const hasPrimaryValuation = (valuation && showPrimaryValuation) || (valueLow && valueHigh && showPrimaryValuationRange)
  const hasZestimateValuation = (zestimateValuation && showZestimate) || (zestimateValueLow && zestimateValueHigh && showZestimateRange)
  const showValuationErrorMessage = !hasPrimaryValuation && !hasZestimateValuation

  if (refreshingValuations) {
    return (
      <Card variant="outlined">
        <CardHeader title="Estimated Home Value" />
        <Divider />
        <LoadingIcon />
      </Card>
    )
  }

  return (
    <Card variant="outlined">
      <CardHeader title="Estimated Home Value" />
      <Divider />
      <Stack direction={outerStackDirection()} divider={<Divider orientation={outerStackDirection() === 'column' ? 'horizontal' : 'vertical'} />}>
        <Box flex={1}>
          {showAgentValuation() ? (
            <AgentValuation
              {...{
                agentValuation,
                agentValueHigh,
                agentValueLow,
                agentValuationUpdatedAt,
                requestValuationClick,
                loading,
                agent,
              }}
            />
          ) : (
            <BlankAgentValuation {...{ agent, loading, requestValuationClick }} />
          )}
          {showValuationErrorMessage && (
            <Alert severity="error" sx={{ m: 1 }}>
              Our 3rd party data providers do not have an automated valuation for this property. However, as an expert in your market, your agent can provide a complete report of your property value and market conditions.{' '}
              <Link
                underline="always"
                onClick={requestValuationClick}
                color="primary"
                sx={theme => ({
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  color: `${theme.colors.primary.main} !important`,
                  textDecoration: 'underline !important',
                })}
              >
                Contact Agent
              </Link>
            </Alert>
          )}
        </Box>

        {externalValuationsToDisplay.length && (
          <Stack flex={1} direction={innerStackDirection()} divider={<Divider orientation={innerStackDirection() === 'column' ? 'horizontal' : 'vertical'} />}>
            {externalValuationsToDisplay.map(valuation => (
              <Box key={valuation.key} flex={1}>
                {valuation.component}
              </Box>
            ))}
          </Stack>
        )}
      </Stack>

      {(valuationDifferenceDisclaimer || valuationDisclaimer) && (
        <Box px={xsOnly ? 2 : 10} py={2}>
          <ValuationDifferenceDisclaimer largeDifferenceDisclaimerText={valuationDifferenceDisclaimer} defaultDisclaimerText={valuationDisclaimer} onRequestValuation={requestValuationClick} loading={loading} />
        </Box>
      )}
    </Card>
  )
}

export default HomeValuation
