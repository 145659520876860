import React, { useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import BottomPopper from '../../../Common/UIComponents/Input/BottomPopper'
import './RsLocationSelectorStyles.css'
import { OMNI_TYPES } from '@/components/Common/Constants/OmnisearchConstants'
import { debounce } from '@/components/Utils/DebounceUtils'

const RsLocationSelector = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState('')

  const [omniQuery, { loading, data, error }] = useLazyQuery(OMNISEARCH_QUERY, {
    variables: { query: inputValue },
    skip: !inputValue || inputValue?.length < 3,
  })

  const onSearchInputChange = debounce((_event, newInputValue, reason) => {
    if (reason === 'input') {
      setInputValue(newInputValue)
      if (newInputValue?.length > 2) {
        omniQuery({ variables: { query: newInputValue } })
      }
    }
  }, 500)

  const options = data
    ? data.search_location.edges.map(edge => {
        const { es_id, name, sub_name, state, __typename } = edge.node
        const label = `${name}${sub_name ? `, ${sub_name}` : ''}${state ? `, ${state}` : ''}`
        return {
          value: es_id,
          label: label,
          type: OMNI_TYPES[__typename],
        }
      })
    : []

  // We don't fire the query until they type 3 characters, but show loading state as soon as they start typing
  const showLoadingState = loading || (inputValue?.length > 0 && inputValue?.length < 3)

  return (
    <Autocomplete
      value={inputValue}
      disableClearable
      fullWidth
      blurOnSelect
      onBlur={() => setInputValue('')}
      id="omnisearch"
      options={options}
      PaperComponent={props => <Paper {...props} style={{ overflow: 'auto' }} />}
      loading={showLoadingState}
      error={error}
      noOptionsText={inputValue?.length > 0 ? 'No results found' : 'Start typing to search'}
      loadingText="Searching..."
      onChange={onSelect}
      onInputChange={onSearchInputChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            sx={theme => ({
              backgroundColor: theme.colors.alpha.white[100],
            })}
            variant="outlined"
            label={null}
            className="mui-omnisearch-without-label"
            size={'small'}
            placeholder={'City, Zip, Neighborhood, School'}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '16px' }, // 16px (or greater) prevents iOS from auto-zooming
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={theme => ({ color: theme.colors.alpha.black[50] })}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {showLoadingState ? (
                    <CircularProgress
                      sx={theme => ({ color: theme.colors.alpha.black[30], marginRight: 5 })}
                      size={15}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
      renderOption={(props, option) => (
        <MenuItem {...props} key={`${option.value}-${option.label}`}>
          <Typography variant="subtitle1">{option.label}</Typography>
        </MenuItem>
      )}
      PopperComponent={props => <BottomPopper {...props} />}
    />
  )
}

export default RsLocationSelector

const OMNISEARCH_QUERY = gql`
  query locationSearch($query: String!) {
    search_location(query: $query, first: 50) {
      edges {
        node {
          __typename
          ... on City {
            id
            name
            state
            es_id
          }
          ... on Township {
            id
            name
            state
            es_id
          }
          ... on Neighborhood {
            id
            name
            sub_name
            es_id
          }
          ... on PublicSchool {
            id
            name
            sub_name
            state
            es_id
          }
          ... on Zipcode {
            id
            name
            es_id
          }
        }
      }
    }
  }
`
