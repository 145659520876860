import { CardContent, Divider, Typography, Box, Skeleton, Stack } from '@mui/material'
import RsCard from '../../Common/UIComponents/Card/RsCard'
import ListingAlerts from './Alerts/ListingAlerts'
import { gql, useQuery } from '@apollo/client'
import OtherEmailSettings from './Alerts/OtherEmailSettings'
import MarketAlerts from './Alerts/MarketAlerts'
import HomeValueAlerts from './Alerts/HomeValueAlerts'

const ALERTS_QUERY = gql`
  query userSettingsQuery {
    user: current_user {
      id
      send_saved_property_alerts
      auto_engagement_enabled
      saved_searches(exclude_handpicked: true) {
        total_count
        edges {
          node {
            id
            search_name
            schedule
            is_active
            client_url
            client_edit_url
          }
        }
      }
      market_alerts {
        total_count
        edges {
          node {
            id
            schedule
            market_name
            client_url
          }
        }
      }
      home_reports {
        total_count
        edges {
          node {
            id
            schedule
            titleized_full_address
            client_url
          }
        }
      }
    }
  }
`

const Alerts = () => {
  const { loading, data, refetch } = useQuery(ALERTS_QUERY)

  if (loading) {
    return <Skeleton variant="rectangular" height={100} />
  }

  const user = data.user

  return (
    <Stack spacing={2}>
      {user.saved_searches.total_count > 0 && <ListingAlerts savedSearches={user.saved_searches} />}
      {user.market_alerts.total_count > 0 && <MarketAlerts marketAlerts={user.market_alerts} />}
      {user.home_reports.total_count > 0 && (
        <HomeValueAlerts homeReports={user.home_reports} />
      )}
      <OtherEmailSettings user={user} />
    </Stack>
  )
}
export default Alerts
