import React, { useState, useContext, useCallback } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import { FiltersContext } from '../../Contexts/FiltersContext'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { OMNI_RESULTS_CATEGORIES } from '@/components/Common/Constants/OmnisearchConstants'
import {
  Autocomplete,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import BottomPopper from '../../../Common/UIComponents/Input/BottomPopper'
import GroupHeader from './GroupHeader'
import { categorizeAndFormatSearchResults } from './utils'
import './styles.css'
import { debounce } from '@/components/Utils/DebounceUtils'

const Omnisearch = ({ isMobile }) => {
  const [inputValue, setInputValue] = useState('')
  const filtersContext = useContext(FiltersContext)
  const splitContext = useContext(SplitLayoutContext)
  const history = useHistory()
  const location = useLocation()

  const [omniQuery, { loading, data, error }] = useLazyQuery(OMNISEARCH_QUERY, {
    variables: { query: inputValue },
    skip: !inputValue || inputValue?.length < 3,
  })

  const onSearchInputChange = debounce((_event, newInputValue, reason) => {
    if (reason === 'input') {
      setInputValue(newInputValue)
      if (newInputValue?.length > 2) {
        omniQuery({ variables: { query: newInputValue } })
      }
    }
  }, 500)

  const onSelect = useCallback(
    (event, selectedItem) => {
      if (selectedItem.type === OMNI_RESULTS_CATEGORIES.ADDRESSES) {
        history.push(`/listings/${selectedItem.slug}`)
      } else {
        if (isMobile) {
          splitContext.flagMapRefreshNeeded(true)
        }
        filtersContext.changeMapFilter({ geo_id: selectedItem.value })
        splitContext.setSearchAreaLabel(selectedItem.label)
        const searchPath = '/map'
        if (!location.pathname.startsWith(searchPath)) {
          history.push(searchPath)
        }
      }
    },
    [filtersContext, history, location.pathname]
  )

  const options = data ? categorizeAndFormatSearchResults(data) : []
  // We don't fire the query until they type 3 characters, but show loading state as soon as they start typing
  const showLoadingState = loading || (inputValue?.length > 0 && inputValue?.length < 3)

  return (
    <Autocomplete
      value={inputValue}
      disableClearable
      blurOnSelect
      onBlur={() => setInputValue('')}
      id="omnisearch"
      options={options}
      PaperComponent={props => <Paper {...props} style={{ overflow: 'auto' }} />}
      loading={showLoadingState}
      error={error}
      groupBy={option => option.type}
      renderGroup={params => {
        let shouldRenderDivider = params.key !== 0
        return (
          <li key={params.key}>
            {shouldRenderDivider && <Divider sx={{ my: 2 }} />}
            <GroupHeader groupParams={params} />
            <ul style={{ padding: 0 }}>{params.children}</ul>
          </li>
        )
      }}
      noOptionsText={inputValue?.length > 0 ? 'No results found' : 'Start typing to search'}
      loadingText="Searching..."
      onChange={onSelect}
      onInputChange={onSearchInputChange}
      renderInput={params => {
        return (
          <TextField
            {...params}
            sx={theme => ({
              backgroundColor: theme.colors.alpha.white[100],
            })}
            variant="outlined"
            label={null}
            className="mui-omnisearch-without-label"
            size={'small'}
            placeholder={'City, Zip, Neighborhood, Address, School'}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: '16px' }, // 16px (or greater) prevents iOS from auto-zooming
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={theme => ({ color: theme.colors.alpha.black[50] })}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {showLoadingState ? (
                    <CircularProgress
                      sx={theme => ({ color: theme.colors.alpha.black[30], marginRight: 5 })}
                      size={15}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
      renderOption={(props, option) => (
        <MenuItem {...props} key={`${option.id}-${option.label}`}>
          <Typography variant="subtitle1">{option.label}</Typography>
        </MenuItem>
      )}
      PopperComponent={props => <BottomPopper {...props} />}
    />
  )
}

export default Omnisearch

const OMNISEARCH_QUERY = gql`
  query locationSearch($query: String!) {
    search_location(query: $query, first: 50) {
      edges {
        node {
          __typename
          ... on City {
            id
            name
            state
            es_id
          }
          ... on Township {
            id
            name
            state
            es_id
          }
          ... on Neighborhood {
            id
            name
            sub_name
            es_id
          }
          ... on PublicSchool {
            id
            name
            sub_name
            state
            es_id
          }
          ... on Zipcode {
            id
            name
            es_id
          }
        }
      }
    }
    properties_by_query(query: $query) {
      edges {
        node {
          id
          address
          city
          mls_number
          slug
        }
      }
    }
  }
`
