import { Component } from 'react';
import Modal from 'react-modal';
import ListingDetail from './../Pages/ListingDetail';
import {CollectionContext} from './../Contexts/CollectionContext';
import { forceCheck } from 'react-lazyload';
import ListingModalButtons from "./ListingModal/ListingModalButtons";

class ListingModal extends Component {
  static contextType = CollectionContext;
  contentRef;

  componentDidMount() {
    this.context.setCurrentListing(this.props.match.params.id);
  }
  componentDidUpdate() {
    if (this.context.currentListingIdentifier !== this.props.match.params.id) {
      this.context.setCurrentListing(this.props.match.params.id);
    }
    if (this.contentRef) {
      this.contentRef.addEventListener('scroll', () => forceCheck())
    }
  }

  componentWillUnmount() {
    this.contentRef.removeEventListener('scroll', () => forceCheck())
  }

  back = e => {
    if (e) e.stopPropagation();
    this.setState({isOpen: false});
    this.props.history.push(this.props.previousLocation.pathname);
  };

  state = {
    isOpen: true
  };

  customStyles = {
    content : {
      top: '20px',
      left                  : '10%',
      right                 : '10%',
      bottom                : 'auto',
      borderRight: 'none',
      borderTop: 'none',
      borderBottom: 'none',
      borderRadius: 0,
      height: '100vh',
      overflow: 'scroll',
      backgroundColor: '#f8f8f8',
      padding: 0
    },
    overlay: {
      zIndex: 1000
    }
  };
  render() {
    return (
        <Modal
            isOpen={this.state.isOpen}
            onRequestClose={this.back}
            style={this.customStyles}
            contentLabel="Listing Modal"
            closeTimeoutMS={200}
            appElement={document.getElementById('layout-content')}
            contentRef={node => this.contentRef = node}
        >
          <ListingModalButtons
            closeModal={() => this.back()}
          />
          <ListingDetail match={this.props.match} isModal/>
        </Modal>
    );
  }
}

export default ListingModal;
