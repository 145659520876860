import { gql, useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, CircularProgress, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Link } from '@mui/material'
import Sticky from 'react-stickynode'
import { useState, useMemo, useEffect } from 'react'
import moment from 'moment/moment'
import MarketBuyerActivity from './MarketActivityAlert/MarketBuyerActivity'
import MarketActivityListings from './MarketActivityAlert/MarketActivityListings'
import MarketActivityHeatmap from './MarketActivityAlert/MarketActivityHeatmap'
import AgentChatWidget from './MarketActivityAlert/AgentChatWidget'
import PageHeader from '../../Common/PageHeader'
import MuiErrorBoundarySimple from '../../Common/MuiErrorBoundarySimple'
import { numberToCurrency } from '../../Utils/NumberUtils'

const MARKET_ACTIVITY_ALERT_BASICS_QUERY = gql`
  query marketActivityAlertBasicsQuery($marketAlertId: ID!) {
    current_user {
      id
      timezone
      market_alert: market_alert_by_id(id: $marketAlertId) {
        id
        market_geo_json
        market_name
        price_min
        price_max
      }
      agent {
        id
        first_name
        last_name
        photo_url
        email
        phone_number
        broker_logo_url
        company_name
        suppress_maa_data_widgets
      }
    }
  }
`

const RECORD_MARKET_ALERT_VIEW = gql`
  mutation RecordMarketAlertView($data: RecordMarketAlertViewInput!) {
    record_market_alert_view(input: $data) {
      errors {
        key
        message
      }
    }
  }
`

const MarketActivityAlert = () => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const marketAlertTimeframeOptions = useMemo(() => {
    return [
      { label: 'Last 30 days', value: moment().subtract(30, 'days').startOf('day').unix(), interval: 'DAY' },
      { label: 'Last 60 days', value: moment().subtract(60, 'days').startOf('day').unix(), interval: 'WEEK' },
      { label: 'Last 90 days', value: moment().subtract(90, 'days').startOf('day').unix(), interval: 'WEEK' },
      { label: 'Last 180 days', value: moment().subtract(180, 'days').startOf('day').unix(), interval: 'MONTH' },
      { label: 'Last 365 days', value: moment().subtract(365, 'days').startOf('day').unix(), interval: 'MONTH' },
    ]
  }, [])

  const [startTime, setStartTime] = useState(marketAlertTimeframeOptions[2])

  const { id } = useParams()
  const { data, loading } = useQuery(MARKET_ACTIVITY_ALERT_BASICS_QUERY, {
    variables: {
      marketAlertId: id,
      start_time: startTime.value,
      interval: startTime.interval,
    },
  })

  const [recordView] = useMutation(RECORD_MARKET_ALERT_VIEW)

  const marketAlertId = data?.current_user?.market_alert?.id
  useEffect(() => {
    if (marketAlertId) {
      recordView({
        variables: {
          data: {
            market_alert_id: data.current_user?.market_alert?.id,
          },
        },
      })
    }
  }, [marketAlertId])

  if (loading) {
    return (
      <Box m={3} textAlign="center">
        <CircularProgress />
      </Box>
    )
  }

  if (data.current_user && !data.current_user.market_alert) {
    return (
      <Box textAlign="center" p={2}>
        <Typography variant="h3" m={3}>
          Market Report Not Available
        </Typography>
        <Typography variant="h6">
          You may be trying to view a market report that you don't have access to or has been deleted.
          <br />
          <br />
          If you believe this to be in error, or have any questions, please <Link href={'mailto:support@realscout.com?subject=Issue viewing Market Activity Report'}>contact us</Link> for more information
        </Typography>
      </Box>
    )
  }

  const marketAlert = data.current_user.market_alert
  const agent = data.current_user.agent
  const showDataWidgets = !data.current_user.agent.suppress_maa_data_widgets

  const subHeader = (priceMin, priceMax) => {
    let priceMessage = ''
    if (priceMin && priceMax) {
      priceMessage = ` for listings between ${numberToCurrency(priceMin)} and ${numberToCurrency(priceMax)}`
    } else if (priceMin) {
      priceMessage = ` for listings over ${numberToCurrency(priceMin)}`
    } else if (priceMax) {
      priceMessage = ` for listings under ${numberToCurrency(priceMax)}`
    }

    let header = `See what's been happening recently`

    return header.concat(priceMessage)
  }

  const TimeframeSelector = () => {
    return (
      <Box>
        <FormControl>
          <InputLabel id="timeframe-select-label">Timeframe</InputLabel>
          <Select
            labelId="timeframe-select-label"
            id="timeframe-select"
            value={startTime.value}
            label="Timeframe"
            size="small"
            onChange={e => {
              const selectedOption = marketAlertTimeframeOptions.find(opt => opt.value === e.target.value)
              setStartTime(selectedOption)
            }}
          >
            {marketAlertTimeframeOptions.map(opt => (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  return (
    <MuiErrorBoundarySimple>
      <PageHeader>
        <Box>
          <Typography variant="h3" color="text.secondary" fontWeight="normal">
            Market Activity Report for <strong>{marketAlert.market_name}</strong>
          </Typography>
          <Typography variant="h6" color="text.secondary" fontWeight="normal">
            {subHeader(marketAlert.price_min, marketAlert.price_max)}
          </Typography>
        </Box>
        {!isSmallerThanMd && <TimeframeSelector />}
      </PageHeader>
      <Grid container spacing={2} sx={isSmallerThanMd ? { pb: 3 } : { px: 3, pb: 3 }}>
        <Grid item container xs={12} md={8} rowSpacing={2}>
          {isSmallerThanMd && (
            <Grid item xs={12} sx={isSmallerThanMd ? { px: 3, textAlign: 'right' } : {}}>
              <TimeframeSelector />
            </Grid>
          )}
          {showDataWidgets && (
            <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
              <MarketActivityHeatmap marketAlertId={marketAlert.id} startTime={startTime} marketGeoJson={marketAlert.market_geo_json} />
            </Grid>
          )}
          {isSmallerThanMd && (
            <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
              <AgentChatWidget agent={agent} />
            </Grid>
          )}
          <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
            {showDataWidgets && (
              <MuiErrorBoundarySimple>
                <MarketBuyerActivity startTime={startTime} marketAlertId={marketAlert.id} />
              </MuiErrorBoundarySimple>
            )}
            <MuiErrorBoundarySimple>
              <MarketActivityListings startTime={startTime} marketAlertId={marketAlert.id} />
            </MuiErrorBoundarySimple>
          </Grid>
        </Grid>
        {!isSmallerThanMd && (
          <Grid item xs={12} md={4}>
            <Sticky top={20}>
              <AgentChatWidget agent={agent} />
            </Sticky>
          </Grid>
        )}
      </Grid>
    </MuiErrorBoundarySimple>
  )
}

export default MarketActivityAlert
