import {
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Box,
  Stack,
  Divider,
} from '@mui/material'
import MarketAlertScheduleSelect from "../../../Common/ScheduleSelects/MarketAlertScheduleSelect";
import RsCard from '../../../Common/UIComponents/Card/RsCard'

const MarketAlerts = ({ marketAlerts }) => {
  const alerts = marketAlerts.edges.map(marketAlert => marketAlert.node)
  return (
    <RsCard title="Market Alerts">
      <CardContent>
        <Stack divider={<Divider />} spacing={1}>
          {alerts.map(alert => {
            return (
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={alert.id}>
                <Box>
                  <Typography variant="body1">
                    <Link href={alert.client_url}>{alert.market_name}</Link>
                  </Typography>
                </Box>
                <Box width={150}>
                  <MarketAlertScheduleSelect marketAlert={alert} label="Frequency" isClientFacing={true}/>
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </CardContent>
    </RsCard>
  )
}

export default MarketAlerts
