import {
  Typography,
  Card,
  Avatar,
  Grid
} from "@mui/material";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const Features = () => {
  const items = [
    {
      title: 'Get Your Home Value Instantly',
      icon: <HomeOutlinedIcon fontSize='large' />,
      description: 'Access your home dashboard at any time - receive monthly updates',
    },
    {
      title: "Compare Your Home's Value",
      icon: <LocationOnOutlinedIcon fontSize='large' />,
      description: 'Browse listings and compare your home to real properties from the MLS',
    },
    {
      title: 'See Demand In Your Area',
      icon: <AssignmentOutlinedIcon fontSize='large' />,
      description: 'Analyze buyer demand by price range to see the demand for homes in your area',
    }
  ]

  return (
    <>
      <Typography variant='h1' textAlign='center' color='text.primary'>
        Receive an Estimate and Explore Your Home's Value
      </Typography>
      <Grid container columnSpacing={4} rowSpacing={4} sx={{pt: 6}}>
        {
          items.map((i) => {
            return (
              <Grid item xs={12} md={4} key={i.title}>
                <Card
                  variant='outlined'
                  sx={{p: 3}}
                >
                  <Avatar
                    variant='rounded'
                    sx={(theme) => ({
                      backgroundColor: theme.palette.getContrastText(theme.colors.primary.main),
                      color: theme.colors.primary.main,
                      border: `1.5px solid ${theme.colors.primary.main}`,
                      width: theme.spacing(7),
                      height: theme.spacing(7),
                      boxShadow: theme.colors.shadows.primary,
                    })}
                  >
                    { i.icon }
                  </Avatar>
                  <Typography
                    variant='h3'
                    sx={{pt: 2, pb: 1}}
                  >
                    {i.title}
                  </Typography>
                  <Typography
                    variant='h5'
                    fontWeight='normal'
                    color='text.secondary'
                    sx={{height: '100px'}}
                  >
                    {i.description}
                  </Typography>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </>
  )
}

export default Features
