import { Component } from 'react';
import Omnisearch from './Omnisearch'
import styles from './Header.module.scss'
import { CurrentUserContext } from './../Contexts/CurrentUserContext'
import { IoIosMenu } from 'react-icons/io'
import { Desktop, MobileAndTablet } from '../../Common/ResponsiveComponents'

class Header extends Component {
  static contextType = CurrentUserContext

  onMenuClick() {
    window.layoutHelpers.toggleCollapsed()
  }
  render() {
    const { agent } = this.context

    return (
      <div>
        <Desktop>
          <nav
            className={`layout-navbar navbar navbar-expand-lg align-items-lg-center bg-navbar-theme pl-0 ${styles.navbar}`}
            aria-label="Page Header Navigation"
            id="layout-navbar"
          >
            <div className="col-lg-8 col-xl-6">
              <Omnisearch />
            </div>
            <div className="col-lg-4 col-xl-6 text-right">
              <div className="w-100">
                {agent.logo && (
                  <img src={agent.logo} className={`ml-4 ${styles.logo}`} alt={agent.office_name} />
                )}
              </div>
            </div>
          </nav>
        </Desktop>
        <MobileAndTablet>
          <nav
            className={`layout-navbar navbar navbar-expand-lg align-items-lg-center bg-navbar-theme pl-0 ${styles['navbar-mobile']}`}
            id="layout-navbar"
          >
            <div className="col-3 d-flex flex-row justify-content-start align-items-center">
              <button
                className="btn btn-default menu-button"
                aria-label="Expand Menu"
                onClick={this.onMenuClick}
              >
                <IoIosMenu className={styles['menu-button-mobile']} />
              </button>
            </div>
            <div className="col-9 d-flex flex-row align-items-center justify-content-end">
              {agent.logo && (
                <img
                  src={agent.logo}
                  className={`${styles['mobile-logo']}`}
                  alt={agent.office_name}
                />
              )}
              {agent.photo && (
                <img src={agent.photo} className="ui-w-50 rounded-circle ml-2" alt={agent.name} />
              )}
            </div>
          </nav>
        </MobileAndTablet>
      </div>
    )
  }
}

export default Header
