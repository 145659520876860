import { gql, useQuery, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import HomeReportLayout, { HOME_REPORT_FULL_FRAGMENT, AGENT_FRAGMENT_FIELDS } from './HomeReport/HomeReportLayout'
import LoadingIcon from '../../Legacy/RsLoadingCircle'

const HOME_REPORT_QUERY = gql`
  ${HOME_REPORT_FULL_FRAGMENT}
  ${AGENT_FRAGMENT_FIELDS}
  query homeReportQuery($homeReportId: ID!) {
    current_user {
      id
      agent {
        ...AgentFields
      }
      home_report_by_id(id: $homeReportId) {
        ...FullReportFragment
      }
    }
  }
`

const RECORD_HOME_REPORT_VIEW = gql`
  mutation RecordHomeReportView($data: RecordHomeReportViewInput!) {
    record_home_report_view(input: $data) {
      errors {
        key
        message
      }
    }
  }
`

const HomeReport = ({ match }) => {
  const homeReportId = match.params.id

  const [recordView] = useMutation(RECORD_HOME_REPORT_VIEW, {
    variables: {
      data: {
        home_report_id: homeReportId,
      },
    },
  })

  const { data, loading, error } = useQuery(HOME_REPORT_QUERY, {
    variables: {
      homeReportId,
    },
  })

  useEffect(() => {
    recordView()
  }, [homeReportId])

  if (loading) {
    return <LoadingIcon />
  }

  const homeReport = data.current_user.home_report_by_id

  return <HomeReportLayout homeReport={homeReport} agent={data.current_user.agent} />
}

export default HomeReport
