import React, { useContext, useEffect } from 'react'
import SplitCard from './SplitCard'
import styles from './SplitCardsContainer.module.scss'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { FiltersContext } from '../../Contexts/FiltersContext'
import { Grid, Typography, Box, Chip, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import SkeletonCard from './SkeletonCard'
import Disclaimers from './Disclaimers'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'
import RestrictedSplitCard from './RestrictedSplitCard'
import {Mobile, MobileAndTablet} from '../../../Common/ResponsiveComponents'

const SplitCardsContainer = () => {
  const theme = useTheme()
  const splitContext = useContext(SplitLayoutContext)
  const userContext = useContext(CurrentUserContext)
  const filtersContext = useContext(FiltersContext)
  const { selectedListingId, listings, loading } = splitContext

  //When a new region has been selected - we need to trigger a refetch with onMapMove
  useEffect(() => {
    if (splitContext.geo_shapes?.length === 1) {
      let bounds = L.geoJSON(splitContext.geo_shapes[0].geo_json).getBounds()
      splitContext.onMapMove({
        ne_lat: bounds.getNorthEast().lat,
        ne_lon: bounds.getNorthEast().lng,
        sw_lat: bounds.getSouthWest().lat,
        sw_lon: bounds.getSouthWest().lng,
      })
    }
  }, [splitContext.geo_shapes])

  const onClearGeo = () => {
    // Remove geo_id
    filtersContext.changeMapFilter({ geo_id: null })
    // Nullify bounds & searchAreaLabel
    splitContext.onMapMove(null)
    splitContext.setSearchAreaLabel(null)
  }

  return (
    <div className={styles.container} id="split-cards">
      <Grid container spacing={1} padding={1}>
        {splitContext.searchAreaLabel && (
          <MobileAndTablet>
            <Grid item>
              <Chip label={`Searching in ${splitContext.searchAreaLabel}`} onDelete={onClearGeo} />
            </Grid>
          </MobileAndTablet>
        )}
        {loading &&
          [1, 2, 3, 4, 5, 6].map(i => {
            return (
              <Grid
                item
                xs={12}
                md={6}
                className={`split-card`}
                id={`skeleton-listing-card-${i}`}
                key={i}
              >
                <SkeletonCard />
              </Grid>
            )
          })}
        {!loading &&
          listings.length > 0 &&
          listings.map(listing => {
            return (
              <Grid
                item
                xs={12}
                md={6}
                className={`split-card`}
                id={`listing-card-${listing.id}`}
                key={listing.id}
              >
                {userContext.blockData ? (
                  <RestrictedSplitCard
                    listing={listing}
                    isSelected={listing.id === selectedListingId}
                  />
                ) : (
                  <SplitCard listing={listing} isSelected={listing.id === selectedListingId} />
                )}
              </Grid>
            )
          })}
        {!loading && listings.length === 0 && (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <FmdBadIcon fontSize="large" sx={{ mb: 2 }} />
            <Typography variant="h3" sx={{ mb: 1 }}>
              No listings found
            </Typography>
            <Typography variant="body1">
              Try changing location, zooming out to include more results or adjusting your search
              criteria.
            </Typography>
          </Box>
        )}
        {
          <Mobile>
            <Grid item>
              <Disclaimers />
            </Grid>
          </Mobile>
        }
      </Grid>
    </div>
  )
}

export default SplitCardsContainer
