import {Alert, Link} from "@mui/material";
import React from "react";

const ValuationDifferenceDisclaimer = ({largeDifferenceDisclaimerText, defaultDisclaimerText, onRequestValuation, loading}) => {

  const handleClick = () => {
    if (!loading) {
      onRequestValuation()
    }
  }

  const disclaimerInfo = largeDifferenceDisclaimerText ? { color: 'warning', text: largeDifferenceDisclaimerText } : { color: 'info', text: defaultDisclaimerText }

  return (
    <Alert severity={disclaimerInfo.color}>
      { disclaimerInfo.text } <Link
        underline="always"
        onClick={handleClick}
        color='primary'
        sx={(theme) => ({
          '&:hover': {
            cursor: 'pointer'
          },
          color: `${theme.colors.primary.main} !important`,
          textDecoration: 'underline !important'
        })
        }
      >
        Contact Agent
      </Link>
    </Alert>
  )
}

export default ValuationDifferenceDisclaimer
