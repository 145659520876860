import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import QuickMessageCard from '../../../Common/QuickMessageCard'
import { displayMessageResultSnackbar } from '../../../Common/Conversations/MessageResultSnackbar'
import { CardContent, Typography } from '@mui/material'
import Chat from './Chat'

const LEGACY_MESSAGE_MUTATION = gql`
  mutation create_message($input: CreateMessageInput!) {
    create_message(input: $input) {
      message {
        id
        properties {
          edges {
            node {
              id
              messages {
                ...Message
              }
            }
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
  ${Chat.fragments.messageFragment}
`

const MessageHistoryFooter = () => {
  return (
    <CardContent>
      <Typography variant={'body2'}>You have sent a message about this listing.</Typography>
    </CardContent>
  )
}

const BrokerageChatWidget = ({ userId, listingId, listingConversation }) => {
  const { enqueueSnackbar } = useSnackbar()

  // This legacy message mutation is used by the brokerage UI
  const [sendMessageWithLegacySystem, { loading: sendingMessage, data: legacyMessageData }] =
    useMutation(LEGACY_MESSAGE_MUTATION, {
      onCompleted: data => displayMessageResultSnackbar(data.create_message, enqueueSnackbar),
    })

  const submitMessage = message => {
    sendMessageWithLegacySystem({
      variables: {
        input: {
          message_text: message,
          user_id: userId,
          property_id: listingId,
          message_type: 'GENERAL',
        },
      },
    })
  }

  const hasMessageHistory = !!listingConversation || legacyMessageData?.create_message?.message

  return (
    <QuickMessageCard
      agentMessage={'Have any questions about this listing?'}
      quickMessages={[]}
      submitMessage={submitMessage}
      textFieldId={'ldp-quick-message'}
      sendButtonId={'ldp-quick-message-send'}
      sendingMessage={sendingMessage}
      renderFooterComponent={hasMessageHistory ? () => <MessageHistoryFooter /> : null}
    />
  )
}

export default BrokerageChatWidget
