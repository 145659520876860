export const OMNI_RESULTS_CATEGORIES = {
  PLACES: 'PLACES',
  SCHOOLS: 'SCHOOLS',
  ADDRESSES: 'LISTINGS',
}

export const OMNI_TYPES = {
  City: OMNI_RESULTS_CATEGORIES.PLACES,
  Township: OMNI_RESULTS_CATEGORIES.PLACES,
  Neighborhood: OMNI_RESULTS_CATEGORIES.PLACES,
  Zipcode: OMNI_RESULTS_CATEGORIES.PLACES,
  PublicSchool: OMNI_RESULTS_CATEGORIES.SCHOOLS,
}
