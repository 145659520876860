import { CardContent, Skeleton, Stack } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import Listings, { LISTINGS_FRAGMENT } from '../../../Common/MarketActivityReport/Listings'
import { shortPrice } from '../../../Utils/NumberUtils'
import { LISTINGS_METRICS_FRAGMENT } from '../../../Common/MarketActivityReport/ListingsMetrics'

const MARKET_ACTIVITY_ALERT_LISTINGS_QUERY = gql`
  query marketActivityListingsQuery($marketAlertId: ID!, $start_time: Time, $interval: MetricIntervalEnum) {
    current_user {
      id
      market_alert: market_alert_by_id(id: $marketAlertId) {
        id
        market_geo_json
        report(start_time: $start_time, interval: $interval) {
          ...Listings
          ...ListingsMetrics
        }
      }
    }
  }
  ${LISTINGS_FRAGMENT}
  ${LISTINGS_METRICS_FRAGMENT}
`

const MarketActivityListings = ({ marketAlertId, startTime }) => {
  const { data, loading } = useQuery(MARKET_ACTIVITY_ALERT_LISTINGS_QUERY, {
    variables: {
      marketAlertId,
      startTime: startTime.value,
      interval: startTime.interval,
    },
  })

  if (loading) {
    return (
      <RsCard>
        <CardContent>
          <Skeleton variant="rectangular" height={400} />
        </CardContent>
      </RsCard>
    )
  }

  const { top_viewed_listings, recently_sold_listings, recently_updated_listings } = data.current_user.market_alert.report
  const marketReport = data.current_user.market_alert.report
  const marketGeoJson = data.current_user.market_alert.market_geo_json

  const metricsForRecentlyListed = [
    {
      key: 'recently-listed',
      title: 'Listings',
      value: marketReport.recently_updated_listings_total_count,
    },
    {
      key: 'avg-list-price',
      title: 'Avg List Price',
      value: shortPrice(marketReport.avg_list_price),
    },
    {
      key: 'avg-list-price-per-sqft',
      title: 'Avg List Price / Sqft',
      value: `$${marketReport.avg_listing_price_per_sqft}`,
    },
  ]

  const metricsForRecentlySold = [
    {
      key: 'recently-sold',
      title: 'Listings',
      value: marketReport.recently_sold_listings_total_count,
    },
    {
      key: 'avg-sold-price',
      title: 'Avg Sold Price',
      value: shortPrice(marketReport.avg_sold_price),
    },
    {
      key: 'avg-sold-price-per-sqft',
      title: 'Avg Sold Price / Sqft',
      value: `$${marketReport.avg_sold_price_per_sqft}`,
    },
  ]

  return (
    <Stack spacing={2} sx={{ mb: 2 }}>
      {top_viewed_listings.length > 0 && <Listings title="Top Viewed Listings" testId="top-viewed" listings={top_viewed_listings.map(l => l.listing)} marketGeoJson={marketGeoJson} listingsPath="homebuyer" />}
      {recently_updated_listings.length > 0 && (
        <Listings title="Recently Listed / Updated Listings" testId="recently-listed" listings={recently_updated_listings} metrics={metricsForRecentlyListed} marketGeoJson={marketGeoJson} />
      )}
      {recently_sold_listings.length > 0 && <Listings title="Recently Sold Listings" testId="recently-sold" listings={recently_sold_listings} metrics={metricsForRecentlySold} marketGeoJson={marketGeoJson} />}
    </Stack>
  )
}

export default MarketActivityListings
