import React from 'react'
import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import Geosuggest from 'react-geosuggest';
import {toast} from "react-toastify";
import {COMMUTE_TYPES} from "../../../Utils/CommuteUtils";
import { Component } from 'react';
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

const CREATE_COMMUTE_MUTATION = gql`
  mutation createCommute($input: CreateCommuteInput!){
    create_commute(input: $input){
      user{
        id
        commutes:points_of_interest { 
          edges { 
            node { id commute_type address lat lng } 
          } 
        }
      }
      errors{
        key
        message
      }
    }
  }
`;

class Commutes extends Component {

  state = {
    commuteType: 'DRIVE',
    latitude: null,
    longitude: null,
    commuteAddress: null
  }

  onGeoSelect = (placeData) => {
    this.setState({
      latitude: placeData.location.lat,
      longitude: placeData.location.lng,
      commuteAddress: placeData.gmaps.formatted_address
    })
  }

  createCommute = (createCommuteMutation) => {
    createCommuteMutation({
      variables: {
        input: {
          address: this.state.commuteAddress,
          lat: this.state.latitude,
          lng: this.state.longitude,
          commute_type: this.state.commuteType
        }
      }
    })
  }

  onCommuteAdded = (data) => {
    if(data.create_commute.error) {
      toast.error('Error, please try again')
    }else {
      this.setState({latitude: null, longitude: null, commuteAddress: null})
      this.geosuggest.clear();
    }
  };

  render(){
    return(
      <Mutation mutation={CREATE_COMMUTE_MUTATION}
                onError={(error) => toast.error('Error, please try again')}
                onCompleted={this.onCommuteAdded}
      >
        {(createCommute, {data, error, loading, called}) => {
          return (
              <div>
                <div className="form-group">
                  <label className="form-label">Address</label>
                    <Geosuggest placeholder="Type an address"
                                onSuggestSelect={this.onGeoSelect}
                                inputClassName="form-control"
                                autoActivateFirstSuggest
                                autoComplete={'nope'}
                                ref={(ref) => this.geosuggest = ref}
                    />
                </div>
                <div className="form-group">
                  <label className="form-label">Commute Type</label>
                  <select className="custom-select" onChange={(e) => this.setState({commuteType: e.target.value})}>
                    {COMMUTE_TYPES.map((commuteType) => {
                      return <option key={commuteType.value} value={commuteType.value}>{commuteType.label}</option>
                    })}
                  </select>
                </div>
              <button className={`btn btn-primary ${HbAnalyticsClass('add-commute', 'profile')}`} type="button" disabled={!this.state.commuteAddress || loading} onClick={() => this.createCommute(createCommute)}>Add Commute</button>
            </div>
          )
        }}
      </Mutation>
    )
  }
}

export default Commutes;
