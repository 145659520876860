import { Avatar, Box, Container, CssBaseline, Link, Stack, Typography } from '@mui/material'
import MuiErrorBoundarySimple from '@/components/Common/MuiErrorBoundarySimple'
import { ApolloProvider } from '@apollo/client'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import apolloClient from '../Apollo/HomebuyerClient'
import { RealScoutTheme } from './../Theme'
import { Helmet } from 'react-helmet'
import { CurrentUserProvider } from '@/components/Homebuyer/Contexts/CurrentUserContext'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import SetupAccount from '@/components/Homebuyer/Pages/Onboarding/SetupAccount'
import SetupSearch from '@/components/Homebuyer/Pages/Onboarding/SetupSearch'
import PrivateRoute from '@/components/Homebuyer/Layout/PrivateRoute'
import OnboardingFooter from '@/components/Homebuyer/Pages/Onboarding/OnboardingFooter'
import RsPageContent from '@/components/Common/UIComponents/PageLayout/RsPageContent'

const OnboardingApp = props => {
  return (
    <ThemeProvider theme={RealScoutTheme}>
      <SnackbarProvider maxSnack={3}>
        <ApolloProvider client={apolloClient}>
          <Router basename="/onboarding">
            <CurrentUserProvider {...props}>
              <CssBaseline />
              <Helmet title="RealScout | Complete Your Profile" />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  backgroundColor: '#FFF',
                  minHeight: '100vh',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: '10px',
                    backgroundColor: 'white',
                  }}
                >
                  <Avatar
                    src={props.agent.photo}
                    alt="Agent Logo"
                    sx={{ width: 100, height: 100 }}
                    my={2}
                  />
                  <Typography
                    variant="h2"
                    mt={2}
                    mx={2}
                    mb={1}
                    sx={{
                      display: 'flex',
                      textAlign: 'center',
                      alignItems: 'top',
                      justifyContent: 'center',
                    }}
                  >
                    Get Started with {props.agent.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      display: 'flex',
                      textAlign: 'center',
                      alignItems: 'top',
                      justifyContent: 'center',
                    }}
                  >
                    {props.agent.office_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#F2F5FA',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    flex: 1,
                  }}
                >
                  <RsPageContent containerMaxWidth="sm">
                    <MuiErrorBoundarySimple>
                      <Route path="/" exact component={SetupAccount} />
                      <PrivateRoute
                        redirectTo="/"
                        path="/requirements"
                        component={() => (
                          <SetupSearch searchableSimpleTypes={props.searchable_simple_types} />
                        )}
                      />
                    </MuiErrorBoundarySimple>
                  </RsPageContent>
                </Box>
                <OnboardingFooter rsLogoUrl={props.images.realscout_logo} />
              </Box>
            </CurrentUserProvider>
          </Router>
        </ApolloProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default OnboardingApp
