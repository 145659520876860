import React from 'react'
import {
  Map,
  TileLayer,
  Marker,
  FeatureGroup,
  GeoJSON,
  Circle,
  ZoomControl,
  LayersControl,
} from 'react-leaflet'
import { divIcon } from 'leaflet'
import { shortPrice } from './../../../Utils/NumberUtils'
import { EditControl } from 'react-leaflet-draw'
import styles from './SplitLayoutMap.module.scss'
import { CurrentUserConsumer } from './../../Contexts/CurrentUserContext'
import { MAP_ATTRIBUTION, TILE_URL, SATELLITE_TILE_URL } from '../../../Utils/MapUtils'
import { getMapSettings } from '../../../Utils/SearchUtils'
import { FiltersContext } from '../../Contexts/FiltersContext'
import Control from 'react-leaflet-control'
import { IoMdCreate } from 'react-icons/io'
import { SplitLayoutConsumer } from '../../Contexts/SplitLayoutContext'
import { withRouter } from 'react-router-dom'
import SaveSearch from '../../Pages/Search/SaveSearch'
import Disclaimers from './Disclaimers'
import store from 'store'
import { Button, Box } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SplitLayoutMapSelectedListing from './SplitLayoutMapSelectedListing'
import { Component } from 'react';
import { TabletAndDesktop, Mobile } from '../../../Common/ResponsiveComponents'

class SplitLayoutMap extends Component {
  constructor(props) {
    super(props)
    const mapSettings = getMapSettings(props.location.search)
    this.state = {
      lat: mapSettings.center_lat || props.defaultMapLocation[0],
      lng: mapSettings.center_lon || props.defaultMapLocation[1],
      zoom: mapSettings.zoom || 10,
      isDrawing: false,
      hasDrawing: false,
    }
  }

  static contextType = FiltersContext

  _editableFG = null

  updateBounds = () => {
    let bounds = this.refs.map.leafletElement.getBounds().pad(-0.05)
    this.props.onMapMove({
      ne_lat: bounds.getNorthEast().lat,
      ne_lon: bounds.getNorthEast().lng,
      sw_lat: bounds.getSouthWest().lat,
      sw_lon: bounds.getSouthWest().lng,
    })
    if (this.props.isSearchPage || this.props.isExclusivesSearch) {
      const zoom = this.refs.map.leafletElement.getZoom()
      const center = bounds.getCenter()
      store.set('mapSettings', { center_lat: center.lat, center_lon: center.lng, zoom: zoom })
    }
  }

  handleMoveend = () => {
    if (this.state.setBoundsOnListingLoad) {
      this.setState({ setBoundsOnListingLoad: false })
    } else {
      this.updateBounds()
    }
  }

  componentDidMount() {
    if (this.props.isSearchPage) {
      this.updateBounds()
    } else {
      this.setState({
        setBoundsOnListingLoad: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.setBoundsOnListingLoad &&
      this.props.listings &&
      this.props.listings.length > 0
    ) {
      const listingsWithLatLng = this.props.listings.filter(
        listing => listing.latitude && listing.latitude !== 0
      )
      if (listingsWithLatLng.length > 0) {
        const markers = listingsWithLatLng.map(listing =>
          L.marker([listing.latitude, listing.longitude])
        )
        const group = new L.featureGroup(markers)
        const groupBounds = group.getBounds().pad(0.1)
        // Protection against all the markers being in the same lat/lng, as bounds can't be fit, so we need to center on the first point
        let boundsEdgesEqual = groupBounds.getSouthWest().equals(groupBounds.getNorthEast())
        if (boundsEdgesEqual) {
          const firstMarker = listingsWithLatLng[0]
          this.setState({
            lat: firstMarker.latitude,
            lng: firstMarker.longitude,
            setBoundsOnListingLoad: false,
          })
        } else {
          this.refs.map.leafletElement.fitBounds(groupBounds)
        }
      }
    }

    // Fake way of checking if Geojson has changed:
    if (
      (this.props.isSearchPage || this.props.isExclusivesSearch) &&
      this.props.geo_shapes &&
      this.props.geo_shapes.length > 0
    ) {
      const newGeoShapeIds = this.props.geo_shapes.map(shape => shape.id)
      const oldGeoShapeIds = (prevProps.geo_shapes || []).map(shape => shape.id)
      const shapesChanged = JSON.stringify(oldGeoShapeIds) !== JSON.stringify(newGeoShapeIds)
      const shouldFitBounds = shapesChanged || this.props.mapRefreshNeeded
      if (shouldFitBounds && this.props.geo_shapes.length === 1) {
        this.clearPolygon()
        let bounds = L.geoJSON(this.props.geo_shapes[0].geo_json).getBounds()
        this.refs.map.leafletElement.fitBounds(bounds)
        this.props.flagMapRefreshNeeded(false)
      }
    }

    if (
      this.props.isSearchPage &&
      this.context.mapFilters.polygon &&
      this._editableFG.leafletElement.getLayers().length === 0
    ) {
      this.setState({ hasDrawing: true })
      let coordinates = this.context.mapFilters.polygon.coordinates.map(coord => [
        coord[1],
        coord[0],
      ])
      L.polygon(coordinates, this.getDrawOpts().polygon.shapeOptions).addTo(
        this._editableFG.leafletElement
      )
      this.refs.map.leafletElement.fitBounds(this._editableFG.leafletElement.getBounds())
    }
  }

  getDrawOpts = () => {
    return {
      rectangle: false,
      circle: false,
      polyline: false,
      marker: false,
      polygon: {
        allowIntersection: true, // Restricts shapes to simple polygons
        drawError: {
          color: '#e1e100', // Color the shape will turn when intersects
          message: '<strong>Unable to draw intersecting lines<strong>', // Message that will show when intersect
        },
        shapeOptions: {
          color: '#35B575',
        },
      },
    }
  }

  onDrawingStart = () => {
    const map = this.refs.map.leafletElement
    const currentDrawing = new L.Draw.Polygon(map, this.getDrawOpts().polygon)
    this.currentDrawing = currentDrawing
    currentDrawing.enable()
    map.dragging.disable()
    this.setState({ isDrawing: true })
  }

  onCancel = () => {
    const map = this.refs.map.leafletElement
    this.currentDrawing.disable()
    map.dragging.enable()
    this.setState({ isDrawing: false })
  }

  onClear = () => {
    this.clearPolygon()
    this.props.setSearchAreaLabel(null) //This clears the label on the mobile list view
    if (this.context.mapFilters.geo_id) {
      this.context.changeMapFilter({ geo_id: null })
    }
  }

  clearPolygon = () => {
    if (this._editableFG) {
      this._editableFG.leafletElement.clearLayers()
      this.setState({ hasDrawing: false })
      this.context.changeMapFilter({ polygon: null })
    }
  }

  _onCreated = e => {
    this.setState({ isDrawing: false })
    const map = this.refs.map.leafletElement
    map.dragging.enable()
    this.setState({ hasDrawing: true })
    this.context.changeMapFilter({
      polygon: { coordinates: e.layer.toGeoJSON().geometry.coordinates[0] },
    })
  }

  _setEditableFeatureGroup = reactFGref => {
    this._editableFG = reactFGref
  }

  countsMessage() {
    if (this.props.loading) {
      return 'Loading'
    } else if (this.props.total_count) {
      return `Showing ${this.props.current_count} of ${this.props.total_count} listings`
    } else if (this.props.current_count) {
      return `Showing ${this.props.current_count} listings`
    } else {
      return 'No listings found'
    }
  }

  canClear = () => {
    return this.context.mapFilters.geo_id || this.state.hasDrawing
  }

  canDraw = () => {
    return !this.context.mapFilters.geo_id && !this.state.hasDrawing && !this.state.isDrawing
  }

  renderMapControls = () => {
    return (
      <TabletAndDesktop>
        {this.canDraw() && (
          <button
            className={`leaflet-control-layers ${styles.fauxLeafletButton}`}
            onClick={this.onDrawingStart}
          >
            <IoMdCreate className={styles['map-icon']} />
            <br />
            Draw
          </button>
        )}
        {this.canClear() && (
          <button
            className={`leaflet-control-layers ${styles.fauxLeafletButton}`}
            onClick={this.onClear}
          >
            <IoMdCreate className={styles['map-icon']} />
            <br />
            Clear
          </button>
        )}
        {this.state.isDrawing && (
          <button
            className={`leaflet-control-layers ${styles.fauxLeafletButton}`}
            onClick={this.onCancel}
          >
            <IoMdCreate className={styles['map-icon']} />
            <br />
            Cancel
          </button>
        )}
      </TabletAndDesktop>
    )
  }

  render() {
    const position = [this.state.lat, this.state.lng]
    return (
      <div className={styles['map-container']} id="split-map">
        <Map
          ref="map"
          center={position}
          zoom={this.state.zoom}
          minZoom={5}
          className={`${styles.map} split-map`}
          onMoveend={this.handleMoveend}
          zoomControl={false}
          onClick={this.props.onMapClick}
        >
          <ZoomControl position="topright" />
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Streets" checked={true}>
              <TileLayer attribution={MAP_ATTRIBUTION} url={TILE_URL} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer attribution={MAP_ATTRIBUTION} url={SATELLITE_TILE_URL} />
            </LayersControl.BaseLayer>
          </LayersControl>

          {this.props.geo_shapes &&
            this.props.geo_shapes.map(shape => {
              return (
                <GeoJSON
                  key={shape.id}
                  style={() => ({ color: 'black', fillOpacity: 0, weight: 2 })}
                  data={shape.geo_json}
                />
              )
            })}
          {this.props.geo_circles &&
            this.props.geo_circles.map((circle, i) => {
              return (
                <Circle
                  key={circle.radius}
                  color="black"
                  fillOpacity={0}
                  weight={2}
                  center={circle.coordinates}
                  radius={circle.radius}
                />
              )
            })}
          <FeatureGroup ref="markers">
            {!this.props.loading &&
              this.props.listings.map(listing => {
                const icon = divIcon({
                  type: 'div',
                  className: `search-marker ${
                    this.props.selectedListingId === listing.id ? styles['selected-marker'] : null
                  }`,
                  popupAnchor: [2, -25],
                  iconSize: [64, 20],
                  iconAnchor: [32, 20],
                  html:
                    '<i>' +
                    (this.props.blockData ? '-' : shortPrice(listing.display_price)) +
                    '</i>',
                })
                return (
                  <Marker
                    key={listing.id}
                    onClick={() => this.props.selectListing(listing)}
                    position={[listing.latitude, listing.longitude]}
                    icon={icon}
                  />
                )
              })}
          </FeatureGroup>

          {this.props.geo_shapes?.length && (
            <Control position="topleft">
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.onClear}
                  endIcon={<HighlightOffIcon />}
                >
                  Searching in {this.props.geo_shapes[0].name}
                </Button>
              </Box>
            </Control>
          )}

          <TabletAndDesktop>
            {this.props.isSearchPage && (
              <Control position="topleft">
                <Box
                  sx={theme => ({
                    backgroundColor: theme.colors.alpha.white[100],
                    borderRadius: 1,
                  })}
                >
                  <SaveSearch />
                </Box>
              </Control>
            )}
          </TabletAndDesktop>

          <FeatureGroup ref={reactFGref => this._setEditableFeatureGroup(reactFGref)}>
            <EditControl
              position="topleft"
              onCreated={this._onCreated}
              draw={{
                rectangle: false,
                polyline: false,
                circle: false,
                marker: false,
                circlemarker: false,
                polygon: false,
              }}
              edit={{
                edit: false,
                remove: false,
              }}
            />
          </FeatureGroup>

          <Control position="topright">
            {this.props.isSearchPage && this.renderMapControls()}
          </Control>
        </Map>

        <Mobile>
          {this.props.mobileSelectedListing && (
            <SplitLayoutMapSelectedListing listing={this.props.mobileSelectedListing} />
          )}
        </Mobile>
        <TabletAndDesktop>
          <div className={styles.attribution}>
            <Disclaimers />
          </div>
        </TabletAndDesktop>
      </div>
    )
  }
}

const SplitLayoutMapWithConsumer = React.forwardRef((props, ref) => (
  <CurrentUserConsumer>
    {({ blockData, agent }) => (
      <SplitLayoutConsumer>
        {splitLayoutProps => (
          <SplitLayoutMap
            loading={splitLayoutProps.loading}
            listings={splitLayoutProps.listings}
            onMapMove={splitLayoutProps.onMapMove}
            isSearchPage={splitLayoutProps.isSearchPage}
            geo_shapes={splitLayoutProps.geo_shapes}
            geo_circles={splitLayoutProps.geo_circles}
            current_count={splitLayoutProps.current_count}
            total_count={splitLayoutProps.total_count}
            selectedListingId={splitLayoutProps.selectedListingId}
            mobileSelectedListing={splitLayoutProps.mobileSelectedListing}
            isExclusivesSearch={splitLayoutProps.isExclusivesSearch}
            defaultMapLocation={agent.default_map_location}
            blockData={blockData}
            mapRefreshNeeded={splitLayoutProps.mapRefreshNeeded}
            flagMapRefreshNeeded={splitLayoutProps.flagMapRefreshNeeded}
            setSearchAreaLabel={splitLayoutProps.setSearchAreaLabel}
            ref={ref}
            {...props}
          />
        )}
      </SplitLayoutConsumer>
    )}
  </CurrentUserConsumer>
))

export default withRouter(SplitLayoutMapWithConsumer)
