import { useContext } from 'react'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { Typography, Box, Button, Grid } from '@mui/material'
import Omnisearch from '../../Layout/Omnisearch/Omnisearch'
import SaveSearch from '../../Pages/Search/SaveSearch'

const MobileSearchHeader = ({ setFilterModalOpen, setSelectedListing }) => {
  const splitContext = useContext(SplitLayoutContext)

  if (splitContext.isSearchPage) {
    return (
      <Box m={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Omnisearch isMobile />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ '&:focus': { outline: 'none' } }}
              onClick={() => {
                setFilterModalOpen(true)
                setSelectedListing(null, true) // Dismiss the listing so it doesn't block the filter modal
              }}
            >
              Filters
            </Button>
          </Grid>
          <Grid item xs={8}>
            <SaveSearch isMobile />
          </Grid>
        </Grid>
      </Box>
    )
  } else {
    return (
      <Box padding={1} marginY={1} sx={{ minHeight: '45px' }}>
        <Typography variant="h4">{splitContext.title}</Typography>
      </Box>
    )
  }
}

export default MobileSearchHeader
