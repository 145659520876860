import {
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
  Box,
  Stack,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material'
import ListingAlertScheduleSelect from '../../../Common/ScheduleSelects/ListingAlertScheduleSelect'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import React, { useState } from 'react'

const ListingAlerts = ({ savedSearches }) => {
  const [showDisabled, setShowDisabled] = useState(false)
  let searches = savedSearches.edges.map(savedSearch => savedSearch.node)
  const disabledAlertCount = searches.filter(savedSearch => !savedSearch.is_active).length
  const activeAlertCount = searches.filter(savedSearch => savedSearch.is_active).length

  const isShowDisabledChecked = disabledAlertCount === 0 ? false : showDisabled

  if (!showDisabled) {
    searches = searches.filter(savedSearch => savedSearch.is_active)
  }

  return (
    <RsCard
      title="Listing Alerts"
      headerRightSlot={
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              disabled={disabledAlertCount === 0}
              checked={isShowDisabledChecked}
              onChange={() => setShowDisabled(!showDisabled)}
            />
          }
          label={`Show Disabled Alerts? (${disabledAlertCount})`}
        />
      }
    >
      <CardContent>
        {!showDisabled && activeAlertCount === 0 && <Box>No Active Listing Alerts</Box>}
        <Stack divider={<Divider />} spacing={1}>
          {searches.map(search => {
            return (
              <Stack direction="row" justifyContent="space-between" alignItems="center" key={search.id}>
                <Box>
                  <Typography variant="body1">
                    <Link href={search.client_url}>{search.search_name}</Link>
                  </Typography>
                  <Link href={search.client_edit_url}>Edit</Link>
                </Box>
                <Box width={150}>
                  <ListingAlertScheduleSelect
                    listingAlert={search}
                    label="Frequency"
                    isClientFacing={true}
                  />
                </Box>
              </Stack>
            )
          })}
        </Stack>
      </CardContent>
    </RsCard>
  )
}

export default ListingAlerts
