import { useContext } from 'react'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'
import CreateAccount from '@/components/Homebuyer/Pages/Onboarding/SetupAccount/CreateAccount'
import UpdateAccount from '@/components/Homebuyer/Pages/Onboarding/SetupAccount/UpdateAccount'

const SetupAccount = () => {
  const { loggedIn } = useContext(CurrentUserContext)

  if (loggedIn) {
    return <UpdateAccount />
  } else {
    return <CreateAccount />
  }
}
export default SetupAccount
