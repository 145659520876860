import ErrorBoundarySimple from "../../Common/ErrorBoundarySimple"
import PageSection from "./HomeReportLandingPage/PageSection";
import HeaderContent from './HomeReportLandingPage/HeaderContent'
import Features from './HomeReportLandingPage/Features'
import Faq from './HomeReportLandingPage/Faq'
import AgentContactInfo from './HomeReportLandingPage/AgentContactInfo'
import { useContext } from 'react'
import { ImagesContext } from '../Contexts/ImagesContext'

import {
  Box
} from "@mui/material"

const BgBannerImg = ({children}) => {
  const imageContext = useContext(ImagesContext)
  return (
    <Box
      component="div"
      sx={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${imageContext.create_hva_bg_url})`,
        width: '100%'
      }}
    >
      { children }
    </Box>
  )
}

const HomeReportLandingPage = () => {
  return (
    <ErrorBoundarySimple>
      <BgBannerImg>
        <PageSection>
          <HeaderContent />
        </PageSection>
      </BgBannerImg>

      <PageSection whiteBg>
        <Features />
      </PageSection>

      <PageSection>
        <Faq />
      </PageSection>

      <PageSection whiteBg>
        <AgentContactInfo />
      </PageSection>
    </ErrorBoundarySimple>
  )
}

export default HomeReportLandingPage
