import {
  Route,
  Redirect,
} from "react-router-dom";

function RedirectRoute({redirectTo, ...rest}) {
  return (
      <Route
          {...rest}
          render={props =>
              <Redirect
                  to={{pathname: redirectTo}}
              />
          }
      />)
}

export default RedirectRoute;
