import { useContext } from 'react'
import { Typography, Link, CardContent } from '@mui/material'
import { gql } from '@apollo/client'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'

export const AGENT_REMARKS_FRAGMENT = gql`
  fragment agentRemarksFragment on Property {
    id
    agent_comment {
      id
      comment
    }
  }
`

const AgentRemarks = ({ comment }) => {
  const { loggedIn, agent, showSignUp } = useContext(CurrentUserContext)

  return (
    <RsCard
      title={`${agent.name} left a comment about this listing`}
      cardProps={{ sx: { boxShadow: 'none' } }}
    >
      <CardContent>
        {loggedIn ? (
          <Typography color="text.secondary">
            <em>{comment}</em>
          </Typography>
        ) : (
          <Typography>
            <Link
              href="#"
              onClick={e => {
                e.preventDefault()
                showSignUp()
              }}
            >
              Sign up
            </Link>{' '}
            to see what they wrote.
          </Typography>
        )}
      </CardContent>
    </RsCard>
  )
}

export default AgentRemarks
