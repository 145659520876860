import { Component } from 'react';
import SplitLayout, {listingSearchFragment} from '../Common/SplitLayout';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import {Helmet} from "react-helmet";
import {SplitLayoutProvider} from "../Contexts/SplitLayoutContext";

const sortOptions = [
  {label: 'Most Recent', value: 'MOST_RECENT' },
  {label: 'Lowest Price', value: 'PRICE_LOW' },
  {label: 'Highest Price', value: 'PRICE_HIGH'}
];

class Interested extends Component {

  state = {
    selectedSort: sortOptions[0]
  }

  onSortChange = (sortOption) => {
    this.setState({selectedSort: sortOption})
  };

  onMapMove = (bounds) => {
    this.setState({bounds: bounds})
  }

  render() {
    const bounds = this.state.bounds || {};
    return (
        <div>
          <Helmet title="Interested Homes"/>
          <Query
              query={gql`query VerdictSearch($verdict_type: String!, $data: SearcherInput!) {
                        current_user {
                          id
                          searcher:verdict_search(verdict_type: $verdict_type, searcher_arguments: $data) {
                            ...ListingSearch
                          }
                        }
                      }
                      ${listingSearchFragment}
                    `}
              variables={{
                verdict_type: 'saved',
                data: {
                  sort: this.state.selectedSort.value,
                  ...bounds
                }
              }}
              fetchPolicy="network-only"
              nextFetchPolicy="cache-first"
              notifyOnNetworkStatusChange
          >
            {({ loading, error, data, refetch, networkStatus }) => {
              const searchData = (data && data.current_user) ? data.current_user : {};

              return(
                  <SplitLayoutProvider title="Interested"
                               searchData={searchData.searcher || {listings: []}}
                               onMapMove={this.onMapMove}
                               sortOptions={sortOptions}
                               onSortChange={this.onSortChange}
                               selectedSort={this.state.selectedSort}
                               loading={loading}
                  >
                    <SplitLayout/>
                  </SplitLayoutProvider>
              )
            }}
          </Query>
        </div>
    );
  }
}

export default Interested;
