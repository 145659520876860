import { gql, useQuery } from '@apollo/client'
import { Typography, Box, Stack, Link, Divider, Avatar } from '@mui/material'
import ErrorBoundarySimple from '@/components/Common/ErrorBoundarySimple'
import { toTitleCase } from '@/components/Utils/StringUtils'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'

const SCHOOLS_QUERY = gql`
  query SchoolsQuery($id: ID!) {
    current_user {
      id
      property: property_by_id(id: $id) {
        id
        public_schools {
          id
          name
          district
          education_level
          school_type
          great_school_rating
          great_school_url
        }
      }
    }
  }
`

const Schools = ({ propertyId }) => {
  const { data, loading } = useQuery(SCHOOLS_QUERY, {
    variables: {
      id: propertyId,
    },
  })

  const schools =
    data?.current_user?.property?.public_schools?.filter(
      school => school.school_type === 'Regular'
    ) || []

  if (!schools.length || schools.length > 10) {
    return null
  }

  const makeImgSrc = greatSchoolRating => {
    let iconFileName = 'Unrated_icon.png'
    if (greatSchoolRating) {
      iconFileName = `${greatSchoolRating}_icon.png`
    }
    return `https://d2ygk8gs8v5c6g.cloudfront.net/vendor_logos/greatschools/${iconFileName}`
  }

  return (
    <ErrorBoundarySimple>
      <RsCard title="Nearby Schools" cardProps={{ sx: { boxShadow: 'none' } }}>
        <Stack direction="column" divider={<Divider />}>
          {schools.map(school => (
            <Box key={school.id} sx={{ py: 1.25, px: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Link rel="no follow noreferrer" href={school.great_school_url} target="_blank">
                  <Avatar src={makeImgSrc(school.great_school_rating)} alt="School rating icon" />
                </Link>
                <Box>
                  {school.great_school_url ? (
                    <Link
                      rel="no follow noreferrer"
                      href={school.great_school_url}
                      target="_blank"
                      className="link"
                    >
                      {toTitleCase(school.name)}
                    </Link>
                  ) : (
                    <Typography>{toTitleCase(school.name)}</Typography>
                  )}
                  <Typography color="text.secondary" variant="body2">
                    {school.education_level}
                    {school.district && `, ${toTitleCase(school.district)}`}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          ))}
        </Stack>

        <RsCardFooterAction>
          <Typography variant="body2" color="text.secondary" p={1}>
            <strong>Disclaimer:</strong> Ratings provided by{' '}
            <Link rel="no follow noreferrer" target="_blank" href="https://www.greatschools.org">
              GreatSchools.org
            </Link>
            . School attendance data provided by{' '}
            <Link target="_blank" href="https://www.precisely.com/" rel="noreferrer">
              Precisely
            </Link>{' '}
            and subject to change. Check with the applicable school district prior to making a
            decision based on these boundaries. School data is not guaranteed to be accurate, up to
            date or complete.
          </Typography>
        </RsCardFooterAction>
      </RsCard>
    </ErrorBoundarySimple>
  )
}

export default Schools
