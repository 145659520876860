import RsCard from "../../Common/UIComponents/Card/RsCard";
import {Avatar, Box, CardContent, Stack, Typography} from "@mui/material";

const Agent = ({agent}) => {
    return(
        <RsCard title={`Questions? Contact ${agent.name}`}>
            <CardContent>
                <Stack direction="row" spacing={2}>
                    <Box>
                        <Avatar src={agent.photo} sx={{ width: 80, height: 80 }} />
                    </Box>
                    <Box>
                        <Typography variant="h3">{agent.name}</Typography>
                        <Typography variant="body1">{agent.email}</Typography>
                        <Typography variant="body1">{agent.phone_number}</Typography>
                    </Box>
                </Stack>
            </CardContent>
        </RsCard>
    )
}

export default Agent;