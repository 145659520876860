import { FormControlLabel, Switch, CardContent, Typography, Box } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import RsCard from '../../../Common/UIComponents/Card/RsCard'
import { useSnackbar } from 'notistack'
import {useState} from "react";

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    update_user(input: $input) {
      user {
        id
        send_saved_property_alerts
        auto_engagement_enabled
      }
      errors {
        key
        message
      }
    }
  }
`

const OtherEmailSettings = ({ user }) => {
  const { enqueueSnackbar } = useSnackbar()
    const [showAutoEngagementCheckbox, _irrelevant] = useState(user.auto_engagement_enabled)
  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER_MUTATION, {
    onError: () =>
      enqueueSnackbar('Error: Unable to update settings.', {
        variant: 'error',
        autoHideDuration: 3000,
      }),
    onCompleted: data =>
      data.update_user && data.update_user.errors
        ? enqueueSnackbar('Error: Unable to update settings.', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        : enqueueSnackbar('Settings Updated', {
            variant: 'success',
            autoHideDuration: 3000,
          }),
  })
  return (
    <RsCard title="Other Email Alerts">
      <CardContent>
        <FormControlLabel
          sx={{ justifyContent: 'space-between', fontWeight: '500' }}
          disableTypography
          control={
            <Switch
              id="send-saved-property-alerts"
              disabled={updateUserLoading}
              checked={user.send_saved_property_alerts}
              onChange={e =>
                updateUser({
                  variables: {
                    input: {
                      id: user.id,
                      send_saved_property_alerts: e.target.checked,
                    },
                  },
                })
              }
            />
          }
          label="Send price/status updates about listings I'm interested in"
          labelPlacement="end"
        />
        {showAutoEngagementCheckbox &&
          <FormControlLabel
            sx={{ justifyContent: 'space-between', fontWeight: '500' }}
            disableTypography
            control={
              <Switch
                id="auto-engagement-enabled"
                disabled={updateUserLoading}
                checked={user.auto_engagement_enabled}
                onChange={e =>
                  updateUser({
                    variables: {
                      input: {
                        id: user.id,
                        auto_engagement_enabled: e.target.checked,
                      },
                    },
                  })
                }
              />
            }
            label="Send tips and tricks on getting started with RealScout"
            labelPlacement="end"
          />
        }
      </CardContent>
    </RsCard>
  )
}

export default OtherEmailSettings
