import Select from "react-select-2";
import { BarChart, XAxis, YAxis, Tooltip, Bar, ReferenceLine, LabelList, Cell, ResponsiveContainer } from 'recharts';
import {numberToCurrency} from '../Utils/NumberUtils';
import sortBy from 'lodash/sortBy';
import { gql } from '@apollo/client';
import numeral from 'numeral';
import {Card, CardHeader, Typography} from "@mui/material";
import { Component } from 'react';

class ClientPriceChart extends Component {
  static fragments = {
    testPrice: gql`
      fragment TestPriceMetrics on ListingReport{
        test_price_metrics{
          count
          test_price
        }
      }
  `,
    claimedListingTestPrice: gql`
      fragment ClaimedListingTestPriceMetrics on ClaimedListingReport{
        test_price_metrics{
          count
          test_price
        }
      }
  `,
  };

  tooltipFormatter(value, currentPriceNumberOfBuyers){
    let toolTipVal;
    if (currentPriceNumberOfBuyers > value) {
      toolTipVal = `${Math.floor(((currentPriceNumberOfBuyers - value) / currentPriceNumberOfBuyers) * 100)}% fewer buyers at this price point`
    } else if (currentPriceNumberOfBuyers === value) {
      toolTipVal = "No change in buyers at this price point";
    } else {
      toolTipVal = `${Math.floor(((value - currentPriceNumberOfBuyers) / currentPriceNumberOfBuyers) * 100)}% more buyers at this price point`
    }
    return [toolTipVal, null];
  }

  percentChange(value, currentPriceNumberOfBuyers){
    let percentChangeVal;
    if (currentPriceNumberOfBuyers > value) {
      percentChangeVal = `-${Math.floor(((currentPriceNumberOfBuyers - value) / currentPriceNumberOfBuyers) * 100)}%`
    } else if (currentPriceNumberOfBuyers === value) {
      percentChangeVal = null
    } else {
      percentChangeVal = `+${Math.floor(((value - currentPriceNumberOfBuyers) / currentPriceNumberOfBuyers) * 100)}%`
    }
    return percentChangeVal;
  }

  // renderIncrementSelector() {
  //   return (
  //     <Rs.TabletAndDesktop>
  //       {(matches) => {
  //         return (
  //           <div className={`${matches ? 'w-25' : 'w-100'}`}>
  //             <Select
  //               options={[
  //                 {label: '2% Increment', value: .02},
  //                 {label: '5% Increment', value: .05},
  //                 {label: '10% Increment', value: .1}
  //               ]}
  //               value={this.props.selectedInterval}
  //               className="w-100 border-0 text-center"
  //               style={{ zIndex: 1000 }}
  //               onChange={(selectedIncrement) => {
  //                 this.props.updateInterval(selectedIncrement);
  //               }}
  //               simpleValue
  //             />
  //           </div>
  //         )
  //       }}
  //     </Rs.TabletAndDesktop>
  //   );
  // }

  renderBody() {
    const sortedTestPriceMetrics = sortBy(this.props.testPriceMetrics, 'test_price');
    const currentPriceNumberOfBuyers = sortedTestPriceMetrics.find((el) => {
      return el.test_price === this.props.selectedPrice
    }).count;
    const formattedTestPriceMetrics = sortedTestPriceMetrics.map((testPriceMetric) => {
      return Object.assign({}, {priceLabel: numberToCurrency(testPriceMetric.test_price)}, testPriceMetric)
    });

    return(
        <>
          <div className="card-body">
            <div className="row mt-4">
              <ResponsiveContainer width={'100%'} height={300}>
                <BarChart
                    data={formattedTestPriceMetrics}
                    margin={{
                      top: 15, right: 5, left: 5, bottom: 5,
                    }}
                >
                  <XAxis dataKey="priceLabel" />
                  <Tooltip
                      formatter={(value) => this.tooltipFormatter(value, currentPriceNumberOfBuyers)}
                  />
                  <ReferenceLine y={currentPriceNumberOfBuyers} stroke="#235D89" strokeDasharray="3 3"/>
                  <Bar
                      dataKey="count"
                      fill="#235D89"
                  >
                    {
                      formattedTestPriceMetrics.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.test_price == this.props.selectedPrice ? "#235D89" : "rgba(35, 93, 137, 0.6)"}/>))
                    }
                    <LabelList
                        formatter={(val) => {
                          return this.percentChange(val, currentPriceNumberOfBuyers) || '-'
                        }}
                        dataKey="count"
                        position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            {/*<div className="row">*/}
            {/*  <div className="col">*/}
            {/*    <p className="mb-2 text-center">*/}
            {/*      At a {this.props.isListPrice ? 'list price' : 'target price'} of <strong> {numberToCurrency(this.props.selectedPrice)}</strong> there are currently <strong>{numeral(currentPriceNumberOfBuyers).format('0,0')}</strong> buyer{currentPriceNumberOfBuyers === 1 ? '' : 's'} who may be interested in a property like this one, <br/> based on buyer activity in the last 90 days.*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </>
    )
  }

  render() {
      return(
          <Card variant="outlined">
            <div className="card-header d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <Typography variant="h4">
                  {this.props.title ? this.props.title : 'Matched Buyers Pricing Estimator'}
                </Typography>
                <div className="text-muted">
                  {this.props.subtitle ? this.props.subtitle : 'See how price changes affect how many buyers on RealScout will match your listing'}
                </div>
              </div>
              {/*{this.renderIncrementSelector()}*/}
            </div>
            {this.renderBody()}
          </Card>
      )
  }
}


export default ClientPriceChart;
