import {
  Box,
  Typography,
  Card,
} from '@mui/material'

import CreateHomeReportForm from './CreateHomeReport/CreateHomeReportForm'

const HeaderContent = () => {
  const cardStyle = (theme) => {
    return {
      boxShadow: 'none',
      backgroundColor: theme.colors.alpha.white[100],
      p: { xs: 3, md: 6 },
      mx: { sm: 0, md: 8 }
    }
  }

  return (
    <Card sx={cardStyle}>
      <Typography variant='h1' fontSize='48px' color='text.primary'>
        What's my home worth?
      </Typography>

      <Typography variant='h3' color='text.secondary' sx={{pt: 1}}>
        Get a free home value estimate instantly
      </Typography>

      <Box pt={4}>
        <Typography variant='overline' color='text.disabled' fontSize='14px'>
          Home Address
        </Typography>
        <CreateHomeReportForm />
      </Box>
    </Card>
  )
}

export default HeaderContent
