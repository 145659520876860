import { Box, Button, CardActionArea, CardContent, Typography, Card, Stack, CircularProgress } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TimeFormatter from '../../../Utils/TimeUtils'
import { numberToCurrency } from '../../../Utils/NumberUtils'
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

const ValuationCard = ({ valuation, showValuation, valueLow, valueHigh, showRange, topText, refreshingValuations, percentValuationChange, valuationChangeDate, children }) => {
  let valuationForDisplay
  let valuationRangeForDisplay
  let valuationChangeForDisplay
  if (valuation && showValuation) {
    valuationForDisplay = numberToCurrency(valuation)
  }
  if (valuationForDisplay && percentValuationChange && valuationChangeDate) {
    valuationChangeForDisplay = `${percentValuationChange}% since ${TimeFormatter.format(valuationChangeDate * 1000, 'monthDayYear')}`
  }

  if (valueLow && valueHigh && showRange) {
    valuationRangeForDisplay = `${numberToCurrency(valueLow)} - ${numberToCurrency(valueHigh)}`
  }

  let valuationHeader
  let valuationSubtitle
  if (valuationForDisplay) {
    valuationHeader = valuationForDisplay
    valuationSubtitle = valuationRangeForDisplay
  } else {
    valuationHeader = valuationRangeForDisplay
  }

  return (
    <Card elevation={0}>
      <CardContent>
        {refreshingValuations ? (
          <Box display="flex" justifyContent="center" alignItems="center" py={4}>
            <CircularProgress sx={theme => ({ color: theme.colors.alpha.black[30] })} size={24} />
          </Box>
        ) : (
          <Box my={2}>
            {topText && (
              <Typography variant="subtitle2" fontSize={10} textAlign="center">
                {topText}
              </Typography>
            )}
            <Stack direction="column" alignItems="center" justifyContent="center">
              {valuationHeader && (
                <Typography textAlign="center" variant="h1" mb={1}>
                  {valuationHeader}
                </Typography>
              )}
              {valuationChangeForDisplay && (
                <Typography variant="body1" color={percentValuationChange > 0 ? 'success.main' : 'error.main'} sx={{ mt: 0, mb: 1 }}>
                  {percentValuationChange > 0 ? <TrendingUpIcon /> : <TrendingDownIcon />} {valuationChangeForDisplay}
                </Typography>
              )}
              {valuationSubtitle && (
                <Typography textAlign="center" variant="subtitle2" fontSize={18} color="text.secondary">
                  {valuationSubtitle}
                </Typography>
              )}
            </Stack>
          </Box>
        )}

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
          {children}
        </Box>
      </CardContent>
    </Card>
  )
}

const ValuationLogo = props => (
  <Box
    component="img"
    sx={{
      width: 150,
      display: 'block',
    }}
    {...props}
  />
)

const AgentValuation = ({ agentValuation, agentValueHigh, agentValueLow, agentValuationUpdatedAt, requestValuationClick, loading, agent }) => (
  <ValuationCard valuation={agentValuation} showValuation={true} valueLow={agentValueLow} valueHigh={agentValueHigh} showRange={true}>
    <Typography textAlign="center">
      Price opinion by <br /> {agent.first_name} {agent.last_name} on {TimeFormatter.format(agentValuationUpdatedAt * 1000, 'monthDayYear')}
    </Typography>
    <Box mt={2}>
      <Button variant="contained" onClick={requestValuationClick} disabled={!!loading} startIcon={<RefreshIcon />} endIcon={loading && <CircularProgress sx={theme => ({ color: theme.colors.alpha.black[30] })} size={15} />}>
        Request Valuation Update
      </Button>
    </Box>
  </ValuationCard>
)

export default AgentValuation

export const BlankAgentValuation = ({ agent, loading, requestValuationClick }) => (
  <Card elevation={0}>
    <CardContent>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Typography variant="h3" textAlign="center" pb={2}>
          Get an expert opinion on your home’s value from {agent.first_name} {agent.last_name}
        </Typography>
        <Button
          variant="contained"
          className={`${HbAnalyticsClass('agent-valuation-request-sent', 'home-report-agent-valuation')}`}
          onClick={requestValuationClick}
          disabled={!!loading}
          endIcon={loading && <CircularProgress sx={theme => ({ color: theme.colors.alpha.black[30] })} size={15} />}
          startIcon={<RefreshIcon />}
        >
          Request Valuation Estimate
        </Button>
      </Box>
    </CardContent>
  </Card>
)

export const PrimaryValuation = ({ valuation, showValuation, valueHigh, valueLow, showRange, valuationSource, valuationSourceLogo, refreshingValuations, percentValuationChange, valuationChangeDate }) => (
  <ValuationCard
    valuation={valuation}
    showValuation={showValuation}
    valueLow={valueLow}
    valueHigh={valueHigh}
    showRange={showRange}
    percentValuationChange={percentValuationChange}
    valuationChangeDate={valuationChangeDate}
    topText={`Provided by ${valuationSource}`}
    refreshingValuations={refreshingValuations}
  >
    <ValuationLogo alt={`${valuationSource} Logo`} src={valuationSourceLogo} />
  </ValuationCard>
)

export const ZestimateValuation = ({ zestimateValuation, showZestimate, zestimateValueLow, zestimateValueHigh, showZestimateRange, refreshingValuations }) => (
  <ValuationCard
    valuation={zestimateValuation}
    showValuation={showZestimate}
    valueLow={zestimateValueLow}
    valueHigh={zestimateValueHigh}
    showRange={showZestimateRange}
    topText="Provided by the Zestimate API"
    refreshingValuations={refreshingValuations}
  >
    <Typography variant="subtitle2" fontSize={12} textAlign="center">
      Zestimate® home valuation*
    </Typography>
    <CardActionArea
      href="https://www.zillow.com/"
      target="_blank"
      sx={{
        color: 'transparent',
        '&.MuiButtonBase-root:hover': {
          color: 'transparent',
        },
      }}
    >
      <ValuationLogo alt="Real Estate on Zillow" src="https://d2ygk8gs8v5c6g.cloudfront.net/logos/zillow_zestimate.png" mx="auto" />
    </CardActionArea>
  </ValuationCard>
)
