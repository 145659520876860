import { CardContent, Typography, Stack, Grid } from '@mui/material'
import { gql } from '@apollo/client'
import RsCard from '~/components/Common/UIComponents/Card/RsCard'
import LikeDislikeButtons from './LikeDislikeButtons'
import { DISLIKE, LIKE } from '@/components/Common/Constants/FeaturePreferenceConstants'

export const LISTING_FEEDBACK_FRAGMENT = gql`
  fragment listingFeedbackFragment on Property {
    id
    address
    user_interactions {
      id
      feedback {
        id
        category
        sentiment
      }
    }
  }
`

const FEEDBACK_CATEGORIES = [
  { label: 'Aesthetics / Style', value: 'aesthetics / style' },
  { label: 'Condition', value: 'condition' },
  { label: 'Features / Amenities', value: 'features / amenities' },
  { label: 'Floorplan / Layout', value: 'floorplan / layout' },
  { label: 'Kitchen', value: 'kitchen' },
  { label: 'Location', value: 'location' },
  { label: 'Neighborhood', value: 'neighborhood' },
  { label: 'Price', value: 'price' },
  { label: 'Schools', value: 'schools' },
  { label: 'Size', value: 'size' },
  { label: 'View', value: 'view' },
  { label: 'Yard', value: 'yard' },
]

const Feedback = ({ listingId, feedback, address }) => {
  const likedFeedbacks = feedback.filter(fb => fb.sentiment === LIKE).map(fb => fb.category)

  const dislikedFeedbacks = feedback.filter(fb => fb.sentiment === DISLIKE).map(fb => fb.category)

  const isLiked = value => likedFeedbacks.includes(value)
  const isDisliked = value => dislikedFeedbacks.includes(value)

  return (
    <RsCard
      cardProps={{ id: 'listing-feedback', sx: { boxShadow: 'none' } }}
      title={`Which characteristics of ${address} do you like/dislike?`}
    >
      <CardContent>
        <Grid container columnSpacing={8} rowSpacing={2}>
          {FEEDBACK_CATEGORIES.map(feedbackCat => (
            <Grid item xs={12} sm={12} md={6} key={feedbackCat.value}>
              <Stack direction="row" spacing={2} alignItems="center">
                <LikeDislikeButtons
                  listingId={listingId}
                  category={feedbackCat.value}
                  isLiked={isLiked(feedbackCat.value)}
                  isDisliked={isDisliked(feedbackCat.value)}
                />
                <Typography>{feedbackCat.label}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </RsCard>
  )
}

export default Feedback
