import { Card, Typography, Button, Box, useTheme, useMediaQuery } from '@mui/material'
import { gql, useMutation } from '@apollo/client'
import useMutationSnackbarMessages from '@/components/CustomHooks/MutationSnackbarHooks'

const SEND_CASH_OFFER_MESSAGE = gql`
  mutation sendCashOfferMessage($input: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $input) {
      conversation {
        id
      }
    }
  }
`

const RequestCashOffer = ({ agentId, homeReportId, homeReportAddress, previewMode }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'create_conversation_message',
    customErrorMessage: 'Errors occurred, please try again.',
    customSuccessMessage: 'Successfully sent cash offer request to your agent!',
  })

  const [sendMessage, { loading }] = useMutation(SEND_CASH_OFFER_MESSAGE, mutationSnackbarMessages)

  const handleClick = () => {
    if (previewMode || loading) return null
    sendMessage({
      variables: {
        input: {
          attachment_ids: [homeReportId],
          recipient: agentId,
          body: `Cash offer requested for ${homeReportAddress}`,
          is_hva_cash_offer_request: true,
        },
      },
    })
  }

  return (
    <Card variant="outlined" id="request-cash-offer">
      <Box sx={{ p: isSmallerThanMd ? 2 : 3 }}>
        <Typography variant="h4" textAlign="center">
          Are you interested in a cash offer?
        </Typography>
        <Box display="flex" justifyContent="center" pt={2}>
          <Button
            variant="contained"
            fullWidth={isSmallerThanMd}
            onClick={handleClick}
            disabled={loading}
          >
            Request Cash Offer
          </Button>
        </Box>
      </Box>
    </Card>
  )
}

export default RequestCashOffer
