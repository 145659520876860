import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import QuickMessageCard, { SEND_QUICK_MESSAGE } from '../../../Common/QuickMessageCard'
import { displayMessageResultSnackbar } from '../../../Common/Conversations/MessageResultSnackbar'

const AgentChatWidget = ({ agent, previewMode }) => {
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [sendMessage, { loading: sendingMessage }] = useMutation(SEND_QUICK_MESSAGE, {
    onCompleted: data =>
      displayMessageResultSnackbar(data.create_conversation_message, enqueueSnackbar),
  })

  const submitMessage = message => {
    if (previewMode) return null
    sendMessage({
      variables: {
        input: {
          attachment_ids: [id],
          recipient: agent.id,
          body: message,
        },
      },
    })
  }

  const quickMessages = [
    { message: `Hi ${agent.first_name}` },
    { message: `Tell me more about market conditions?` },
  ]

  return (
    <QuickMessageCard
      agentFirstName={agent.first_name}
      agentPhotoUrl={agent.photo_url}
      agentMessage={'Want to learn more about the market?'}
      quickMessages={quickMessages}
      submitMessage={submitMessage}
      textFieldId={'maa-quick-message'}
      sendButtonId={'maa-quick-message-send'}
      sendingMessage={sendingMessage}
    />
  )
}

export default AgentChatWidget
