import queryString from 'query-string';
import RsStorageUtils from "./RsStorageUtils";
import store from 'store';

const maxPrices = [
  {label: "None", value: null},
  {label: "$1,000", value: 1000},
  {label: "$1,500", value: 1500},
  {label: "$2,000", value: 2000},
  {label: "$2,500", value: 2500},
  {label: "$3,000", value: 3000},
  {label: "$3,500", value: 3500},
  {label: "$4,000", value: 4000},
  {label: "$4,500", value: 4500},
  {label: "$5000", value: 5000},
  {label: "$20K", value: 20000},
  {label: "$30K", value: 30000},
  {label: "$40K", value: 40000},
  {label: "$50K", value: 50000},
  {label: "$100K", value: 100000},
  {label: "$150K", value: 150000},
  {label: "$200K", value: 200000},
  {label: "$250K", value: 250000},
  {label: "$300K", value: 300000},
  {label: "$350K", value: 350000},
  {label: "$400K", value: 400000},
  {label: "$450K", value: 450000},
  {label: "$500K", value: 500000},
  {label: "$550K", value: 550000},
  {label: "$600K", value: 600000},
  {label: "$650K", value: 650000},
  {label: "$700K", value: 700000},
  {label: "$750K", value: 750000},
  {label: "$800K", value: 800000},
  {label: "$850K", value: 850000},
  {label: "$900K", value: 900000},
  {label: "$950K", value: 950000},
  {label: "$1M", value: 1000000},
  {label: "$1.1M", value: 1100000},
  {label: "$1.2M", value: 1200000},
  {label: "$1.3M", value: 1300000},
  {label: "$1.4M", value: 1400000},
  {label: "$1.5M", value: 1500000},
  {label: "$1.6M", value: 1600000},
  {label: "$1.7M", value: 1700000},
  {label: "$1.8M", value: 1800000},
  {label: "$1.9M", value: 1900000},
  {label: "$2M", value: 2000000},
  {label: "$2.25M", value: 2250000},
  {label: "$2.5M", value: 2500000},
  {label: "$2.75M", value: 2750000},
  {label: "$3M", value: 3000000},
  {label: "$3.5M", value: 3500000},
  {label: "$4M", value: 4000000},
  {label: "$5M", value: 5000000},
  {label: "$10M", value: 10000000},
  {label: "$20M", value: 20000000}
];


let minPrices = maxPrices.slice(0);
minPrices[0] = {label: 'None', value: null};

const bedChoices = [
  {label: 'Any', value: null},
  {label: '1+', value: 1},
  {label: '2+', value: 2},
  {label: '3+', value: 3},
  {label: '4+', value: 4},
  {label: '5+', value: 5}
];

const bathChoices = [
  {label: 'Any', value: null},
  {label: '1+', value: 1},
  {label: '1.25+', value: 1.1},
  {label: '2+', value: 2},
  {label: '3+', value: 3},
  {label: '4+', value: 4},
  {label: '5+', value: 5}
];

const builtHomeYears = [
  {label: 'Any', value: null },
  {label: '2017+', value: 2017 },
  {label: '2016+', value: 2016 },
  {label: '2015+', value: 2015 },
  {label: '2014+', value: 2014 },
  {label: '2013+', value: 2013 },
  {label: '2010+', value: 2010 },
  {label: '2000+', value: 2000 },
  {label: '1990+', value: 1990 },
  {label: '1980+', value: 1980 }
];

const maxHOAFee = [
  {label: 'Any', value: null},
  {label: 'No Fees ($0)', value: 0},
  {label: '< $50', value: 50},
  {label: '< $100', value: 100},
  {label: '< $200', value: 200},
  {label: '< $300', value: 300},
  {label: '< $500', value: 500},
  {label: '< $1000', value: 1000},
  {label: '< $1500', value: 1500},
  {label: '< $2000', value: 2000},
  {label: '< $2500', value: 2500},
  {label: '< $3000', value: 3000},
  {label: '< $4000', value: 4000},
  {label: '< $5000', value: 5000},
  {label: '< $6000', value: 6000},
  {label: '< $7000', value: 7000},
  {label: '< $8000', value: 8000},
  {label: '< $9000', value: 9000},
  {label: '< $10000', value: 10000},
  {label: '< $15000', value: 15000},
];

const daysOnMarket = [
  {label: 'Any', value: null },
  {label: '< 2 days', value: 2},
  {label: '< 7 days', value: 7},
  {label: '< 14 days', value: 14},
  {label: '< 30 days', value: 30},
  {label: '< 60 days', value: 60}
];

const lotSizes = [
  {label: 'Any', value: null },
  {label: '2000 sqft', value: 2000 },
  {label: '4500 sqft', value: 4500 },
  {label: '6500 sqft', value: 6500 },
  {label: '8000 sqft', value: 8000 },
  {label: '10,890 sqft / .25 acres', value: 10890 },
  {label: '21,780 sqft / .5 acres', value: 21780 },
  {label: '1 acre', value: 43560 },
  {label: '2 acres', value: 87120 },
  {label: '3 acres', value: 130680 },
  {label: '4 acres', value: 174240 },
  {label: '5 acres', value: 217800 },
  {label: '10 acres', value: 435600 },
  {label: '40 acres', value: 1.742e6 },
  {label: '100 acres', value: 4.356e6 }
];

const homeSizes = [
  {label: 'Any', value: null },
  {label: '500 sqft', value: 500 },
  {label: '750 sqft', value: 750 },
  {label: '1000 sqft', value: 1000 },
  {label: '1250 sqft', value: 1250 },
  {label: '1500 sqft', value: 1500 },
  {label: '1750 sqft', value: 1750 },
  {label: '2000 sqft', value: 2000 },
  {label: '2250 sqft', value: 2250 },
  {label: '2500 sqft', value: 2500 },
  {label: '2750 sqft', value: 2750 },
  {label: '3000 sqft', value: 3000 },
  {label: '3500 sqft', value: 3500 },
  {label: '4000 sqft', value: 4000 },
  {label: '5000 sqft', value: 5000 },
  {label: '7500 sqft', value: 7500 }
];

const getMapSettings = (searchUrlString) => {
  let searchParams = queryString.parse(searchUrlString || '?');
  let mapSettings = {};
  const existingSettings = store.get('mapSettings') || {}
  mapSettings.center_lat = parseFloat(searchParams.clat) || existingSettings.center_lat;
  mapSettings.center_lon = parseFloat(searchParams.clng) || existingSettings.center_lon;
  mapSettings.zoom = parseInt(searchParams.zoom) || existingSettings.zoom;
  return mapSettings
}

const parseSearchHash = (mapFilters, searchHashString) => {
  searchHashString = searchHashString.replace('#','');
  let tempHash = {}
  searchHashString.split('/').map((criteria) => {
    const criteriaParts = criteria.split('_');
    if(criteriaParts.length == 2){
      tempHash[criteriaParts[0]] = criteriaParts[1]
    }
  });
  if(tempHash.pmin){
    mapFilters.price_min = parseInt(tempHash.pmin) || null;
  }
  if(tempHash.pmax){
    mapFilters.price_max = parseInt(tempHash.pmax) || null;
  }
  if(tempHash.bmin){
    mapFilters.beds_min = parseInt(tempHash.bmin) || null;
  }
  if(tempHash.bamin){
    mapFilters.baths_min = parseFloat(tempHash.bamin) || null;
  }

  return mapFilters;
}

const parseSearchUrl = (mapFilters, searchUrlString) => {
  let searchParams = queryString.parse(searchUrlString)
  // Load Filters from Map params
  if(searchParams.pmin){
    mapFilters.price_min = parseInt(searchParams.pmin) || null;
  }
  if(searchParams.pmax){
    mapFilters.price_max = parseInt(searchParams.pmax) || null;
  }
  if(searchParams.bmin){
    mapFilters.beds_min = parseInt(searchParams.bmin) || null;
  }
  if(searchParams.bamin){
    mapFilters.baths_min = parseFloat(searchParams.bamin) || null;
  }
  if(searchParams.for_sale){
    mapFilters.for_sale = searchParams.for_sale === '1';
  }
  if(searchParams.for_rent){
    mapFilters.for_rent = searchParams.for_rent === '1';
  }
  if(searchParams.ptype){
    mapFilters.property_types = searchParams.ptype.split(',');
  }
  if(searchParams.oh === '1'){
    mapFilters.has_future_open_house_filter = true;
  }
  if(searchParams.geo_type && searchParams.geo_id){
    mapFilters.geo_id = `${searchParams.geo_type}-${searchParams.geo_id}`
  }else if(searchParams.poly){
    const polygonParts = JSON.parse(searchParams.poly);
    let coordinates = [];
    polygonParts.map((coordinate) => {
      coordinates.push([coordinate[1], coordinate[0]])
    });

    mapFilters.polygon = {
      coordinates: coordinates
    }
  }

  return mapFilters
}

export {
  maxPrices,
  minPrices,
  bedChoices,
  bathChoices,
  builtHomeYears,
  maxHOAFee,
  daysOnMarket,
  lotSizes,
  homeSizes,
  parseSearchUrl,
  parseSearchHash,
  getMapSettings
}
