import React from 'react'
import VerdictWrapper from './../VerdictWrapper'
import lozad from 'lozad'
import ThumbUp from '@mui/icons-material/ThumbUp'
import ThumbDown from '@mui/icons-material/ThumbDown'
import { Button, Card, Skeleton } from '@mui/material'
import { useEffect } from 'react'
const RestrictedSplitCard = ({ listing }) => {
  useEffect(() => {
    //   // Use lozad to observe dynamic content
    const observer = lozad()
    observer.observe()
  }, [])

  return (
    <Card variant="outlined" sx={{ height: 313 }}>
      <Skeleton animation="wave" variant="rectangular" height={200} />
      <div className="card-body pb-0 pt-2 px-0 text-center d-flex flex-column">
        <h6 className="mb-1 text-truncate">
          - Beds&nbsp;&nbsp;&nbsp;- Baths&nbsp;&nbsp;&nbsp;- Sqft
        </h6>
        <p className="small mb-0 text-truncate">Sign Up To See Details</p>
        <div className="text-center mt-2">
          <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
            {({ onSave, isSaved, isLoading }) => (
              <Button
                variant={isSaved ? 'contained' : 'outlined'}
                color="success"
                disabled={isLoading}
                onClick={onSave}
                size="small"
                aria-label="Mark as Interested"
                startIcon={<ThumbUp fontSize="small" />}
              >
                Interested
              </Button>
            )}
          </VerdictWrapper>
          &nbsp;
          <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
            {({ onHide, isHidden, isLoading }) => (
              <Button
                variant={isHidden ? 'contained' : 'outlined'}
                color="error"
                disabled={isLoading}
                onClick={onHide}
                aria-label="Mark as Not Interested"
                size="small"
              >
                <ThumbDown fontSize="small" />
              </Button>
            )}
          </VerdictWrapper>
        </div>
      </div>
    </Card>
  )
}

export default RestrictedSplitCard
