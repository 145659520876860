import './../src/layout-helpers'
import './../src/mega-dropdown'
import './../src/sidenav'
import HomebuyerApp from '~/components/Homebuyer/HomebuyerApp'
import Settings from '~/components/Homebuyer/Settings'
import AppGettingStarted from '~/components/Homebuyer/AppGettingStarted'
import AgentPreviewApp from '~/components/Homebuyer/AgentPreviewApp'

import SetMuiProLicense from '../components/MuiProLicense'
SetMuiProLicense()

import SentrySetup from '../components/SentrySetupVite'
SentrySetup()

import { renderComponent } from '@/components/RenderComponentHelper'
import OnboardingApp from '@/components/Homebuyer/OnboardingApp'

window.global ||= window

renderComponent(HomebuyerApp, 'rs-entry-ClientApp')
renderComponent(Settings, 'rs-entry-ClientSettings')
renderComponent(AppGettingStarted, 'rs-entry-AppGettingStarted')
renderComponent(AgentPreviewApp, 'rs-entry-AgentClientPreview')
renderComponent(OnboardingApp, 'rs-entry-OnboardingApp')
