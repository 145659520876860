import { Component } from 'react'
import { CurrentUserContext } from './../../Contexts/CurrentUserContext'
import { gql } from '@apollo/client'
import { toast } from 'react-toastify'
import { Mutation } from '@apollo/client/react/components'
import VerdictWrapper from '../../Common/VerdictWrapper'
import StarRating from '../../Common/StarRating'
import { Button, Typography, Box } from '@mui/material'
import ThumbDown from '@mui/icons-material/ThumbDown'
import ThumbUp from '@mui/icons-material/ThumbUp'
import React from 'react'
import { analytics } from '../../../Common/Constants/AnalyticsDataAttributes'

const SET_LISTING_RATING = gql`
  mutation set_listing_rating($input: SetListingRatingInput!) {
    set_listing_rating(input: $input) {
      property {
        id
        verdict {
          id
          rating
        }
      }
      errors {
        key
        message
      }
    }
  }
`

class Chat extends Component {
  static contextType = CurrentUserContext
  static fragments = {
    messageFragment: gql`
      fragment Message on Message {
        id
        message_text
        sent_by_agent
        created_at
        message_type
        visit_time_period
        visit_date_time
      }
    `,
  }

  render() {
    const { agent } = this.context

    const listing = this.props.listing
    const showRating =
      listing.verdict && (listing.verdict.rating || listing.verdict.prose === 'saved')
    return (
      <div id="listing-chat">
        <>
          <div className={`card mb-4`}>
            <div className="card-header">
              <div>
                <Box sx={{ textAlign: 'center', mb: 1 }}>
                  <Typography variant="h5">Tell {agent.name} what you think</Typography>
                </Box>
                <div className="mb-2 d-flex flex-row justify-content-center">
                  <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
                    {({ onSave, isSaved, isLoading }) => (
                      <Button
                        variant={isSaved ? 'contained' : 'outlined'}
                        color="success"
                        disabled={isLoading}
                        onClick={() => {
                          onSave()
                          const eventName = isSaved
                            ? analytics.listingDetail.undoInterestedListing
                            : analytics.listingDetail.interestedListing
                          window.posthog?.capture(eventName)
                        }}
                        aria-label="Mark as Interested"
                        startIcon={<ThumbUp fontSize="small" />}
                      >
                        Interested
                      </Button>
                    )}
                  </VerdictWrapper>
                  &nbsp;
                  <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
                    {({ onHide, isHidden, isLoading }) => (
                      <Button
                        variant={isHidden ? 'contained' : 'outlined'}
                        color="error"
                        disabled={isLoading}
                        onClick={() => {
                          onHide()
                          const eventName = isHidden
                            ? analytics.listingDetail.undoHideListing
                            : analytics.listingDetail.hideListing
                          window.posthog?.capture(eventName)
                        }}
                        aria-label="Mark as Not Interested"
                        startIcon={<ThumbDown fontSize="small" />}
                      >
                        Not Interested
                      </Button>
                    )}
                  </VerdictWrapper>
                </div>
              </div>
              {showRating && (
                <div className="text-center">
                  <div className="mb-2">
                    <Typography variant="subtitle2">
                      How does it compare to other homes you've seen?
                    </Typography>
                  </div>
                  <Mutation
                    mutation={SET_LISTING_RATING}
                    onError={() =>
                      toast.error('Error, please try again', { position: toast.POSITION.TOP_RIGHT })
                    }
                  >
                    {(setListingRating, { loading }) => {
                      return (
                        <StarRating
                          rating={listing.verdict && listing.verdict.rating}
                          loading={loading}
                          onStarSelect={rating => {
                            setListingRating({
                              variables: {
                                input: {
                                  property_id: listing.encoded_id,
                                  rating: rating,
                                },
                              },
                            })
                          }}
                        />
                      )
                    }}
                  </Mutation>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    )
  }
}

export default Chat
