import { ApolloProvider } from '@apollo/client'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom'
import { IconContext } from 'react-icons'
import * as Sentry from '@sentry/react'
import * as viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import { ThemeProvider } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { Component } from 'react'
import apolloClient from '../Apollo/HomebuyerClient'
import HomebuyerLayout from './HomebuyerLayout'
import { CurrentUserProvider } from './Contexts/CurrentUserContext'
import { FiltersProviderWithRouter } from './Contexts/FiltersContext'
import { CollectionProviderWithRouter } from './Contexts/CollectionContext'
import ImagesProvider from './Contexts/ImagesContext'
import TermsOfServiceBanner from './Layout/TermsOfServiceBanner'
import ErrorBoundary from '../Common/ErrorBoundary'
import { areCookiesEnabled } from '../Utils/CookieUtils'
import { RealScoutTheme } from '../Theme'

import HomebuyerThemeWithOverrides from '../HomebuyerThemeWithOverrides'

class HomebuyerApp extends Component {
  componentDidMount() {
    viewportUnitsBuggyfill.init()
    Sentry.setUser({
      id: this.props.analyticsId,
    })
    if (this.props.logged_in && !areCookiesEnabled()) {
      Sentry.captureMessage('Logged In Mismatch')
    }
  }

  render() {
    return (
      <ThemeProvider theme={HomebuyerThemeWithOverrides}>
        <SnackbarProvider maxSnack={3}>
          <ApolloProvider client={apolloClient}>
            <ImagesProvider images={this.props.images}>
              <div>
                <Router basename="/homesearch">
                  <div>
                    <ErrorBoundary isInnerErrorBoundary={false}>
                      <CurrentUserProvider {...this.props}>
                        <FiltersProviderWithRouter {...this.props}>
                          <CollectionProviderWithRouter {...this.props}>
                            <IconContext.Provider
                              value={{ style: { position: 'relative', bottom: 1 } }}
                            >
                              <Route component={HomebuyerLayout} />
                            </IconContext.Provider>
                          </CollectionProviderWithRouter>
                        </FiltersProviderWithRouter>
                      </CurrentUserProvider>
                    </ErrorBoundary>
                  </div>
                </Router>
                {this.props.showTOSBanner && <TermsOfServiceBanner />}
                <ToastContainer position="bottom-left" className="global-styleguide" />
              </div>
            </ImagesProvider>
          </ApolloProvider>
        </SnackbarProvider>
      </ThemeProvider>
    )
  }
}

export default HomebuyerApp
