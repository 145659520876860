import {Box, Typography} from "@mui/material";

const HomeReportHeader = ({address}) => {
  return (
      <>
        <Box>
          <Typography variant="h3" color="text.secondary" fontWeight="normal">
            Here's what's happening with <strong>{address}</strong>
          </Typography>
        </Box>
      </>
  )
}

export default HomeReportHeader
