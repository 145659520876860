import { Component } from 'react';
import {
  IoIosHeart, IoIosRadioButtonOn, IoIosRemoveCircle, IoMdHeartDislike,
} from "react-icons/io";
import { Mutation } from '@apollo/client/react/components';
import {toast} from "react-toastify";
import { gql } from '@apollo/client';
import Popover from 'react-popover';
import {CurrentUserContext} from "../Contexts/CurrentUserContext";
import { HbAnalyticsClass } from '../../Common/AnalyticClasses'

const SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION = gql`
  mutation set_feature_preference_sentiment($input: SetFeaturePreferenceSentimentInput!){
    set_feature_preference_sentiment(input: $input){
      user{
        id
        feature_preferences{
          name
          sentiment
        }
      }
      errors{
        key
        message
      }
    }
  }
`;

class FeaturePreference extends Component {
  static contextType = CurrentUserContext;
  state = {
    isFeaturePreferencePopoverOpen: false,
  };

  renderPopoverButtons(featurePreference) {
    return (
      <Mutation
        mutation={SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION}
        onError={() => toast.error('Error, please try again')}
        onCompleted={(data) => {
          this.setState(
            { isFeaturePreferencePopoverOpen: false},
            this.props.onMutationCompleted(data))
        }}
      >
        {(setFeaturePreferenceSentiment, {data}) => {
          return (
            <div
              className="bg-white border border-dark rounded d-flex flex-row justify-content-between align-items-center p-2">
              <button
                className={`btn icon-btn btn-md btn-outline-success borderless ${HbAnalyticsClass('like', 'feature-pref-popup')}`}
                onClick={() => setFeaturePreferenceSentiment({
                  variables: {
                    input: {
                      feature_preference: featurePreference,
                      sentiment: 'like'
                    }
                  }
                })}
              >
                <IoIosHeart style={{fontSize: '26px'}}/>
              </button>
              <button
                className={`btn icon-btn btn-md btn-outline-danger borderless ${HbAnalyticsClass('dislike', 'feature-pref-popup')}`}
                onClick={() => setFeaturePreferenceSentiment({
                  variables: {
                    input: {
                      feature_preference: featurePreference,
                      sentiment: 'dislike'
                    }
                  }
                })}
              >
                <IoMdHeartDislike style={{fontSize: '26px'}}/>
              </button>
              <button
                className={`btn icon-btn btn-md btn-outline-secondary borderless ${HbAnalyticsClass('neutral', 'feature-pref-popup')}`}
                onClick={() => setFeaturePreferenceSentiment({
                  variables: {
                    input: {
                      feature_preference: featurePreference,
                      sentiment: 'neutral'
                    }
                  }
                })}
              >
                <IoIosRemoveCircle style={{fontSize: '26px'}}/>
              </button>
            </div>
          )
        }}
      </Mutation>
    )
  }

  render() {
    const icons = {
      like: <IoIosHeart className="mr-1" />,
      dislike: <IoMdHeartDislike className="mr-1" />,
      neutral: <IoIosRemoveCircle className="mr-1" />,
      noSentiment: <IoIosRadioButtonOn className="mr-1" />
    };
    const iconClass = {
      like: 'btn-outline-success',
      dislike: 'btn-outline-danger',
      neutral: 'btn-outline-secondary',
      noSentiment: 'btn-outline-dark'
    };
    const featurePreference = this.props.featurePreference;
    const sentiment = this.props.sentiment || 'noSentiment';

    if (featurePreference && this.context.loggedIn) {
      const popoverProps = {
        isOpen: this.state.isFeaturePreferencePopoverOpen,
        onOuterAction: () => this.setState({ isFeaturePreferencePopoverOpen: !this.state.isFeaturePreferencePopoverOpen}),
        preferPlace: 'below',
        tipSize: 5,
        body: this.renderPopoverButtons(featurePreference)
      };
      return (
        <div key={featurePreference} className={`my-1 ${this.props.isTwoColumns ? `col-xs-12 col-sm-12 col-md-6` : `col-12 col-sm-6 col-md-4 px-0`}`}>
          <Popover {...popoverProps}>
            <button className={`btn btn-sm ${iconClass[sentiment]} borderless d-flex flex-row w-100 align-items-center`} onClick={() => this.setState({ isFeaturePreferencePopoverOpen: !this.state.isFeaturePreferencePopoverOpen})}>
              {icons[sentiment]}
              <p className="text-truncate m-0">
                {featurePreference}
              </p>
            </button>
          </Popover>
        </div>
      )
    } else if (featurePreference) {
      return (
        <div key={featurePreference} className={`my-2 ${this.props.isTwoColumns ? `col-xs-12 col-sm-12 col-md-6` : `col-12 col-sm-6 col-md-4 px-0 flex-wrap`}`}>
          <button className={`btn btn-sm ${iconClass[sentiment]} borderless d-flex flex-row w-100 align-items-center`}>
            {icons[sentiment]}
            <p className="text-truncate m-0">
              {featurePreference}
            </p>
          </button>
        </div>
      )
    }
  }
}


export default FeaturePreference;
