import React from 'react'
import { numberToCurrency } from '../Utils/NumberUtils'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

const filter = createFilterOptions({
  matchFrom: 'start',
  stringify: option => `${option.value}`,
})

const PriceSelector = ({
  fieldName,
  placeholder,
  options,
  currentFilterOption,
  changeFilter,
  ariaLabel,
  size,
  id,
}) => {
  const cleanInput = input => {
    let value = input?.toString() || ''
    return parseInt(value.replace(/\D/g, ''))
  }

  const validateValueAgainstLimits = value => {
    return value < 100000000 && value > -1
  }

  let selectedOption = options.find(option => option.value === currentFilterOption)
  if (!selectedOption && currentFilterOption) {
    selectedOption = {
      label: `$${currentFilterOption.toLocaleString('en')}`,
      value: currentFilterOption,
    }
  }

  return (
    <Autocomplete
      value={selectedOption?.value}
      isOptionEqualToValue={(option, value) => option?.value === value}
      onChange={(event, selectedOption) => {
        let value
        if (typeof selectedOption === 'string') {
          value = cleanInput(selectedOption)
        } else {
          value = selectedOption.value
        }
        if (validateValueAgainstLimits(value)) {
          changeFilter(value)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(option => cleanInput(inputValue) === option.value)
        if (inputValue !== '' && !isExisting) {
          let newLabel = `${numberToCurrency(cleanInput(inputValue))}`
          filtered.push({
            value: cleanInput(inputValue),
            label: newLabel,
          })
        }

        return filtered
      }}
      clearOnBlur
      handleHomeEndKeys
      disableClearable={true}
      selectOnFocus={false}
      name={fieldName}
      id={id}
      options={options}
      getOptionLabel={option => {
        return option?.label || numberToCurrency(cleanInput(option))
      }}
      renderOption={(props, option) => {
        return (
          <li key={option.value} {...props}>
            {option.label}
          </li>
        )
      }}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          label={placeholder}
          fullWidth
          size={size || 'normal'}
          sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: '16px' }, // 16px (or greater) prevents iOS from auto-zooming
          }}
        />
      )}
    />
  )
}

export default PriceSelector
