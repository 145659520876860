import {useContext, Fragment} from 'react';
import ListingsBlankSlate from "./ListingsBlankSlate";
import {
  IoMdAdd,
  IoMdCheckmark
} from "react-icons/io";
import {Link} from "react-router-dom";
import SuggestedListingsContext from "../../Contexts/SuggestedListingsContext";
import {Tooltip} from 'react-tippy';
import {priceWithFallback} from "./../../../Utils/NumberUtils";
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'
import { Box, CircularProgress, Typography } from '@mui/material'

const SuggestionsSection = ({listings, loading, pageName}) => {

  const suggListingContext = useContext(SuggestedListingsContext);

  const renderRow = (listing) => {
    const isSelected = suggListingContext.selectedIds.includes(listing.id);

    return (
        <div className="row my-2 mx-0">
          <div className="col-7 d-flex flex-column justify-content-center align-items-start">
            <Tooltip
                theme="light"
                animation="fade"
                positon="top"
                delay={0}
                duration={0}
                html={
                  <img src={listing.photos.edges[0].node.photo_url} className="rounded" height={'150px'}/>
                }
            >
              <Link to={{pathname: `/listings/${listing.slug}`, state: {modal: true}}} className="text-primary">
                {listing.address}
              </Link>
            </Tooltip>
          </div>
          <div className="col-3 d-flex flex-row justify-content-start align-items-center">
            <p className="m-0"><small>{priceWithFallback(listing.display_price)}</small></p>
          </div>
          <div className="col-2 d-flex flex-row justify-content-start align-items-center">
            {isSelected ?
                <button onClick={() => suggListingContext.onUnselect(listing)}
                        className={`btn icon-btn btn-sm btn-primary d-flex flex-row justify-content-center align-items-center ${HbAnalyticsClass('removeListingToCompare', 'compare')}`}>
                  <IoMdCheckmark className="m-1" style={{fontSize: '14px'}}/>
                </button>
                :
                <button onClick={() => suggListingContext.onSelect(listing)}
                        className={`btn icon-btn btn-sm btn-outline-primary d-flex flex-row justify-content-center align-items-center ${HbAnalyticsClass('addListingToCompare', 'compare')}`}>
                  <IoMdAdd className="m-1" style={{fontSize: '14px'}}/>
                </button>
            }
          </div>
        </div>
    )
  };


  if (loading) {
    return (
        <div className="mt-5"
             style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               flexDirection: 'column'
             }}>
          <CircularProgress/>
          <Box mt={2}>
            <Typography variant="h6">Loading...</Typography>
          </Box>
        </div>
    )
  } else if (listings && listings.length === 0) {
    return <ListingsBlankSlate page={pageName}/>

  } else if (listings && listings.length > 0) {
    return (
        <div className="container px-1">
          {listings.map(listing => {
            return (
                <Fragment key={listing.id}>
                  {renderRow(listing)}
                </Fragment>
            )
          })}
        </div>
    )
  }
}

export default SuggestionsSection;
