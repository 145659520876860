import {
  Typography,
  Box,
  Modal,
  Card,
  TextField,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
  IconButton,
  InputAdornment
} from '@mui/material';
import {useInput} from '../../../../CustomHooks/InputHook';
import {gql, useMutation} from '@apollo/client';
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import SignupDisclaimerText from '../../../Common/SignupDisclaimerText'

const REGISTER_USER_AND_CREATE_HOME_REPORT_MUTATION = gql`
  mutation register_client_and_create_home_report($input: RegisterClientAndCreateHomeReportInput!) {
    register_client_and_create_home_report(input: $input) {
      redirect_url
      errors {
        key
        message
      }
    }
  }
`

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  p: 3,
};

const CheckEmail = ({onClose, streetAddress}) => {
  return (
    <>
      <Alert severity="success" sx={{mt: 2, mb: 4}}>
        <AlertTitle>Success</AlertTitle>
        Please check your email to login and view your free home value estimate for { streetAddress }.
      </Alert>
      <Button
        fullWidth
        variant='contained'
        onClick={onClose}
      >
        Done
      </Button>
    </>
  )
}

const RegisterUserAndCreateHomeReportModal = ({open, onClose, onCloseAndResetForm, addressInputs}) => {
  const { agent } = useContext(CurrentUserContext)
  const {value: email, bind: bind_email, reset: resetEmail} = useInput('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [showCheckEmail, setShowCheckEmail] = useState(false)
  const [emailValidationError, setEmailValidationError] = useState('')

  const handleCompleted = (data) => {
    const result = data.register_client_and_create_home_report;
    if (result?.errors?.length) {
      const errorMessages = result.errors.map((e) => `${e.key}: ${e.message}`).join(' ');
      enqueueSnackbar(`Errors: ${errorMessages}`, {
        variant: 'error',
        autoHideDuration: 3000
      })
    } else if (result.redirect_url) {
      enqueueSnackbar('Home Alert successfully created!', {
        variant: 'success',
        autoHideDuration: 3000
      })

      window.location.assign(result.redirect_url);
    } else {
      setShowCheckEmail(true)
    }
  }

  const [registerUserAndCreateHomeReport, {loading}] = useMutation(REGISTER_USER_AND_CREATE_HOME_REPORT_MUTATION, {
    onCompleted: handleCompleted,
    onError: () => {
      enqueueSnackbar('Errors occurred, please try again.', {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  });

  const validateEmail = () => {
    if (!email) {
      setEmailValidationError("Can\'t be blank")
      return false
    } if (!/(.+@.+\..+)/i.test(email)) {
      setEmailValidationError("Invalid format")
      return false
    } else {
      return true
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      registerUserAndCreateHomeReport({
        variables: {
          input: {email, ...addressInputs}
        }
      })
    }
  }

  const handleClose = () => {
    if (loading) { return }
    setShowCheckEmail(false)
    setEmailValidationError('')
    onClose()
  }

  const handleCloseAndResetForm = () => {
    if (loading) { return }
    setShowCheckEmail(false)
    setEmailValidationError('')
    resetEmail()
    onCloseAndResetForm()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      id='hva-client-register-modal'
    >
      <Card sx={style}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant="h3">
            Get your home value estimate
          </Typography>
          <IconButton
            onClick={handleClose}
            disabled={loading}
          >
            <CloseIcon sx={(theme) => ({fontSize: '24px', color: theme.colors.alpha.black[50]})} />
          </IconButton>
        </Box>
        {
          showCheckEmail ? <CheckEmail onClose={handleCloseAndResetForm} streetAddress={addressInputs.address} /> :
          <>
            <Typography variant='h6' fontWeight={500} color='text.disabled'>
              Just one more thing to create your home report
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
            >
              <TextField
                id="client-sign-up-email"
                label="Email"
                name="email"
                error={!!emailValidationError}
                helperText={emailValidationError}
                placeholder='Enter your email address'
                {...bind_email}
                fullWidth
                sx={{my: 4}}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><EmailOutlinedIcon color='primary' /></InputAdornment>,
                }}
              />
              <Button
                fullWidth
                variant='contained'
                type='submit'
                disabled={loading}
                endIcon={
                  loading && <CircularProgress
                    sx={(theme) => ({color: theme.colors.alpha.black[30]})}
                    size={15}
                  />
                }
              >
                Continue
              </Button>
              <Box sx={{textAlign: 'center'}} mt={2}>
                <Typography variant="disclaimer">
                  <SignupDisclaimerText agent={agent}/>
                </Typography>
              </Box>
            </Box>
          </>
        }
      </Card>
    </Modal>
  )
}

export default RegisterUserAndCreateHomeReportModal
