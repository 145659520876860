import { Component } from 'react';
import {
  IoIosAdd,
  IoIosHome
} from "react-icons/io";
import Popover from 'react-popover';
import SuggestedListings from "./../../Common/SuggestedListings";
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

class HomebuyerAttachmentSelector extends Component {
  state = {
    isAttachListingsOpen: false,
    searchValue: null,
    options: [],
    loading: false,
  };

  renderPopoverBody() {
    return (
        <div
            className="global-styleguide"
            style={{ height: '288px', width: '325px' }}
        >
          <div
              className="bg-transparent w-100 h-100 p-2"
          >
            <SuggestedListings
                title="Attach Listings"
                height={160}
                selectedIds={this.props.selectedIds}
                onUnselect={this.props.removeListing}
                onSelect={this.props.attachListing}
            />
          </div>
        </div>
    )
  }




  render() {
    const popoverProps = {
      isOpen: this.state.isAttachListingsOpen,
      onOuterAction: () => this.setState({ isAttachListingsOpen: !this.state.isAttachListingsOpen, options: []}),
      preferPlace: 'above',
      tipSize: 5,
      body: this.renderPopoverBody()
    };

    return (
        <>
          <Popover {...popoverProps}>
            <button
                className={`btn btn-default ml-2 ${HbAnalyticsClass('attachment-selector', 'conversations')}`}
                id="homebuyer-attach-listings"
                onClick={() => this.setState({ isAttachListingsOpen: !this.state.isAttachListingsOpen})}
            >
              <IoIosHome
                  size={22}
              />
              <span className="badge badge-pill badge-primary indicator pt-1">
              <IoIosAdd
                  size={12}
              /></span>
            </button>
          </Popover>
        </>
    );
  }
}

export default HomebuyerAttachmentSelector;
