import { Component } from 'react';
import styles from './HomebuyerAttachedListing.module.scss';
import {Link} from 'react-router-dom'
import {priceWithFallback} from "./../../../Utils/NumberUtils";
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

class HomebuyerAttachedListing extends Component {

  render() {
    const attachment = this.props.attachment;

    return (
          <div
              className={`d-flex flex-column align-items-start m-2 ${styles['attached-listing-display-container']}`}
              key={attachment.slug}
          >
            <Link to={{pathname: `/listings/${attachment.slug}`, state: {modal: true}}} className={`w-100 ${HbAnalyticsClass('listing-click', 'conversation-attachments')}`}>
              <div className="card-img ui-bg-cover ui-bg-overlay-container"
                   style={{backgroundImage: `url('${attachment.photos.edges[0].node.photo_url}')`, height: 150}}/>
            </Link>
            <div className="d-flex flex-column">
              <h4 className={`my-1`}>{priceWithFallback(attachment.display_price)}</h4>
              <div className="d-flex flex-column">
                <p className={`my-0`}>{attachment.address}</p>
                <p className={`my-0`}>{attachment.city}, {attachment.state} {attachment.postal_code}</p>
              </div>
            </div>

          </div>
    );
  }
}

export default HomebuyerAttachedListing;
