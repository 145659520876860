import { useState, useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SplitLayout, { listingSearchFragment } from '../Common/SplitLayout'
import SearchFilters from './Search/SearchFilters'
import { gql, useQuery } from '@apollo/client'
import { FiltersContext } from '../Contexts/FiltersContext'
import { Helmet } from 'react-helmet'
import { SplitLayoutProvider } from '../Contexts/SplitLayoutContext'
import { reportError } from '../../Common/ErrorCapture'

const sortOptions = [
  { label: 'Newest On Site', value: 'NEWEST' },
  { label: 'Lowest Price', value: 'PRICE_LOW' },
  { label: 'Highest Price', value: 'PRICE_HIGH' },
]

const SEARCH_QUERY = gql`
  query ListingSearch($data: MapSearchInput!) {
    current_user {
      id
      basic_search(map_arguments: $data) {
        ...ListingSearch
      }
    }
  }
  ${listingSearchFragment}
`

const Search = () => {
  const filters = useContext(FiltersContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [bounds, setBounds] = useState(null)
  const [selectedSort, setSelectedSort] = useState(sortOptions[0])

  const onMapMove = bounds => {
    setBounds(bounds)
    filters.setSearchBounds(bounds)
  }

  const onSortChange = sortOption => {
    setSelectedSort(sortOption)
  }

  const setGeoshape = ({ current_user }) => {
    if (current_user?.basic_search?.geo_shapes && current_user.basic_search.geo_shapes.length > 0) {
      filters.setSearchGeoshape(current_user.basic_search.geo_shapes[0])
    } else if (filters.searchGeoshape) {
      filters.setSearchGeoshape(null)
    }
  }

  // "bounds" are not required for mobile - because the map is not always rendered
  let shouldSkipQuery = !bounds && !isMobile

  const { loading, error, data, previousData } = useQuery(SEARCH_QUERY, {
    variables: {
      data: {
        sort: selectedSort.value,
        ...(bounds || {}),
        ...filters.searchParams,
      },
    },
    skip: shouldSkipQuery,
    onCompleted: setGeoshape,
    onError: reportError,
  })

  let searchData = { listings: [] }
  if (data && data.current_user) {
    searchData = data.current_user.basic_search
  } else if (previousData) {
    searchData = previousData.current_user.basic_search
  }

  return (
    <div>
      <Helmet title="Search for Real Estate" />
      <SplitLayoutProvider
        searchData={searchData}
        onMapMove={onMapMove}
        loading={loading && !shouldSkipQuery}
        sortOptions={sortOptions}
        onSortChange={onSortChange}
        selectedSort={selectedSort}
        isSearchPage={true}
        title="Search"
      >
        <SplitLayout>
          <SearchFilters />
        </SplitLayout>
      </SplitLayoutProvider>
    </div>
  )
}

export default Search
