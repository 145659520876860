import numeral from 'numeral'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useContext } from 'react'
import { gql } from '@apollo/client'
import { calculateDisplayPrices } from '../../../../Utils/NumberUtils'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'
import { FOR_RENT, FOR_SALE } from '../../../../Common/Constants/PropertyConstants'

export const LISTING_HEADER_FRAGMENT = gql`
  fragment listingHeaderFragment on Property {
    id
    simple_listing_status
    days_on_site
    sold_date
    beds_total
    baths_total
    structure_sqft
    sold_price
    list_price
    display_rules {
      hide_days_on_site
    }
    display_status
    combined_display_status
    address
    city
    state
    postal_code
    lot_size_value
    lot_size_units
    slug
  }
`

const Header = ({
  simpleListingStatus,
  daysOnSite,
  soldDate,
  bedsTotal,
  bathsTotal,
  structureSqft,
  soldPrice,
  listPrice,
  listingDisplayRules,
  displayStatus,
  combinedDisplayStatus,
  address,
  city,
  state,
  postalCode,
  lotSizeValue,
  lotSizeUnits,
  slug,
}) => {
  const { loggedIn } = useContext(CurrentUserContext)

  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  const showDaysOnSite =
    [FOR_SALE, FOR_RENT].includes(simpleListingStatus) &&
    loggedIn &&
    !listingDisplayRules.hide_days_on_site

  const bedsString = bedsTotal > 0 ? bedsTotal : '-'
  const bathsString = bathsTotal > 0 ? bathsTotal : '-'
  const sqftString = structureSqft > 0 ? numeral(structureSqft).format('0,0') : '-'
  const lotSizeValueString = lotSizeValue || '-'

  const listPriceData = {
    simple_listing_status: simpleListingStatus,
    sold_price: soldPrice,
    list_price: listPrice,
  }
  const listingPriceInfo = calculateDisplayPrices(listPriceData)
  const { isSold, formattedDisplayPrice, showListedAtPrice, formattedListedAtPrice } =
    listingPriceInfo

  return (
    <Box>
      <Box sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}>
        {isSmallerThanSm ? (
          <MobileHeader
            bedsString={bedsString}
            bathsString={bathsString}
            sqftString={sqftString}
            isSold={isSold}
            soldDate={soldDate}
            formattedDisplayPrice={formattedDisplayPrice}
            displayStatus={displayStatus}
            combinedDisplayStatus={combinedDisplayStatus}
            showListedAtPrice={showListedAtPrice}
            formattedListedAtPrice={formattedListedAtPrice}
            address={address}
            city={city}
            state={state}
            postalCode={postalCode}
            lotSizeValue={lotSizeValueString}
            lotSizeUnits={lotSizeUnits}
            showDaysOnSite={showDaysOnSite}
            daysOnSite={daysOnSite}
          />
        ) : (
          <DesktopHeader
            bedsString={bedsString}
            bathsString={bathsString}
            sqftString={sqftString}
            isSold={isSold}
            soldDate={soldDate}
            formattedDisplayPrice={formattedDisplayPrice}
            displayStatus={displayStatus}
            combinedDisplayStatus={combinedDisplayStatus}
            showListedAtPrice={showListedAtPrice}
            formattedListedAtPrice={formattedListedAtPrice}
            address={address}
            city={city}
            state={state}
            postalCode={postalCode}
            lotSizeValue={lotSizeValueString}
            lotSizeUnits={lotSizeUnits}
            slug={slug}
            userLoggedIn={loggedIn}
            showDaysOnSite={showDaysOnSite}
            daysOnSite={daysOnSite}
          />
        )}
      </Box>
    </Box>
  )
}

export default Header
