// This contains components from the Homebuyer UI that are rendered to the Agent in an iFrame

import { Component } from 'react';
import { ApolloProvider } from '@apollo/client';
import apolloClient from './../Apollo/AgentClient';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import ErrorBoundary from '../Common/ErrorBoundary';
import HomeReport from "./AgentPreviewPages/HomeReport";
import * as Sentry from '@sentry/react';
import {ThemeProvider} from "@mui/material";
import {RealScoutTheme} from './../Theme';
import { SnackbarProvider } from 'notistack';

class AgentPreviewApp extends Component {
  componentDidMount(){
    Sentry.setUser({
      id: this.props.analyticsId,
    });
  }

  render() {
    return (
        <ThemeProvider theme={RealScoutTheme}>
          <SnackbarProvider maxSnack={3}>
            <ApolloProvider client={apolloClient}>
              <div style={{marginBottom: '2rem'}}>
                <Router basename="/agent-preview">
                  <div>
                    <ErrorBoundary isInnerErrorBoundary={false}>
                        <Route path="/users/:userId/home-reports/:homeReportId" component={HomeReport} />
                    </ErrorBoundary>
                  </div>
                </Router>
                <ToastContainer
                    position='bottom-left'
                    className='global-styleguide'
                />
              </div>
            </ApolloProvider>
          </SnackbarProvider>
        </ThemeProvider>
    )
  }
}

export default AgentPreviewApp;

