import { Box, Checkbox, Divider, Stack, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { homeSizes, lotSizes, daysOnMarket, maxHOAFee, bedChoices, bathChoices, minPrices, maxPrices } from '../../../../Utils/SearchUtils'
import PriceSelector from '../../../../Common/PriceSelector'
import { useContext } from 'react'
import { FiltersContext } from '../../../Contexts/FiltersContext'

const SelectOption = ({ fieldName, label, options }) => {
  const { changeMapFilter, mapFilters } = useContext(FiltersContext)
  const selectedOption = options.find(option => option.value === mapFilters[fieldName])

  return (
    <FormControl fullWidth>
      <InputLabel id={`${fieldName}-select-input-label`}>{label}</InputLabel>
      <Select
        labelId={`${fieldName}-select-label`}
        id={`${fieldName}-select`}
        value={selectedOption?.value}
        label={label}
        onChange={e => {
          changeMapFilter({ [fieldName]: e.target.value })
        }}
      >
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const Filters = () => {
  const filtersContext = useContext(FiltersContext)
  const { changeMapFilter, mapFilters, togglePropertyType, searchableSimpleTypes } = filtersContext

  return (
    <Stack spacing={2} divider={<Divider />}>
      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Price
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PriceSelector fieldName="price_min" id="drawer_price_min" placeholder="Price Min" options={minPrices} currentFilterOption={mapFilters['price_min']} changeFilter={value => changeMapFilter({ ['price_min']: value })} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PriceSelector fieldName="price_max" id="drawer_price_max" placeholder="Price Max" options={maxPrices} currentFilterOption={mapFilters['price_max']} changeFilter={value => changeMapFilter({ ['price_max']: value })} />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Status
        </Typography>
        <Grid container rowSpacing={2}>
          <Grid item>
            <FormControlLabel control={<Checkbox onChange={() => changeMapFilter({ for_sale: !mapFilters.for_sale })} checked={mapFilters.for_sale} name="For Sale" />} label="For Sale" />
          </Grid>
          <Grid item>
            <FormControlLabel control={<Checkbox onChange={() => changeMapFilter({ for_rent: !mapFilters.for_rent })} checked={mapFilters.for_rent} name="For Rent" />} label="For Rent" />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Beds & Baths
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="beds_min" label="Beds" options={bedChoices} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="baths_min" label="Baths" options={bathChoices} />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Property Types
        </Typography>
        <Grid container>
          {searchableSimpleTypes.map(simple_type => {
            return (
              <Grid item xs={12} sm={6} key={simple_type.value}>
                <FormControlLabel sx={{ my: 0 }} control={<Checkbox onChange={() => togglePropertyType(simple_type.value)} checked={mapFilters.property_types.includes(simple_type.value)} />} label={simple_type.label} />
              </Grid>
            )
          })}
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Open Houses
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FormControlLabel
              sx={{ my: 0 }}
              control={<Checkbox onChange={() => changeMapFilter({ has_future_open_house_filter: !mapFilters.has_future_open_house_filter })} checked={mapFilters.has_future_open_house_filter} name="Show Only Open Houses" />}
              label="Show Only Open Houses"
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          More Filters
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="sqft_min" label="Sqft Min" options={homeSizes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="sqft_max" label="Sqft Max" options={homeSizes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="lot_min" label="Lot Min" options={lotSizes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="lot_max" label="Lot Max" options={lotSizes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="days_max" label="Days on site" options={daysOnMarket} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectOption fieldName="hoa_max" label="HOA/Maint./Charges" options={maxHOAFee} />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default Filters
