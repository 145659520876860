import { gql } from '@apollo/client';
import { Carousel } from 'react-responsive-carousel';
import {truncate} from './../../../Utils/StringUtils';
import {priceWithFallback} from './../../../Utils/NumberUtils';
import {listingDaysOrSoldString} from './../../../Utils/ListingUtils';
import BasicMap from './../../Common/BasicMap';
import { Link } from 'react-router-dom';
import {CurrentUserConsumer} from './../../Contexts/CurrentUserContext';
import {
  IoIosThumbsDown,
  IoIosThumbsUp,
  IoMdClose
} from "react-icons/io";
import VerdictWrapper from "../../Common/VerdictWrapper";
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

export const COMPARE_LISTING_FRAGMENT = {
  listing: gql`
      fragment CompareListing on Property {
        id
        address
        baths_total
        beds_total
        city
        days_on_site
        encoded_id
        latitude
        list_price
        listing_status
        longitude
        photos { 
          edges {
            photo:node{
              id
              photo_url 
              room_name 
            }
          }
        }
        short_details{
          label
          value
        }
        postal_code
        public_remarks
        price_per_sqft
        simple_listing_status
        slug
        sold_date
        sold_price
        state
        structure_sqft
        verdict { id prose }
      }
  `,
};

const CompareListing = ({toggleListing, allRooms, index, property}) => {

  const renderCarousel = (photos) => {
    return(
        <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
        >
          {photos.map((photo, index) => {
            return (
                <div key={index} className="ui-rect ui-bg-cover" style={{backgroundImage: `url(${photo.photo_url})`}}/>
            )
          })}
        </Carousel>
    )
  }

  const _photosForRoom = (photos, roomName) => {
    let photosForRoom =  photos.filter((photo) => {
      const photoRoomName = photo.room_name ? photo.room_name : 'Misc Rooms' ;
      return photoRoomName === roomName
    });
    return photosForRoom.length > 0 ? photosForRoom : [{ photo_url: 'https://s3.amazonaws.com/RealScoutStatic/missing-photo.jpg' }];
  };

  const _renderPhotosByRooms = (photos, allRooms, index) => {
    return allRooms.map((room) => {
      return(
          <div className="card-footer p-2" key={room}>
            <p className="small">{room}</p>
            {renderCarousel(_photosForRoom(photos,room))}
          </div>
      )
    })
  };

    if (property) {
      const photos = property.photos.edges.map((edge) => edge.photo);
      const daysOrSoldString = listingDaysOrSoldString(property);

      return(
        <div>
          <div className="card-body">
            <header>
              <div className="row">
                <div className="col-10">
                  <Link to={{pathname: `/listings/${property.slug}`, state: {modal: true}}}>
                    <h3 className="mb-2">{priceWithFallback(property.list_price)}</h3>
                  </Link>
                </div>
                <div className="col-2 d-flex flex-row justify-content-end">
                  <CurrentUserConsumer>
                    {({loggedIn}) => {
                      if(loggedIn) return(
                        <button onClick={() => toggleListing(property)} className={`btn icon-btn btn-md btn-outline-danger borderless ${HbAnalyticsClass('removeListingToCompare', 'compare')}`}>
                          <IoMdClose />
                        </button>
                      )
                    }}
                  </CurrentUserConsumer>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div>
                    <CurrentUserConsumer>
                      {({loggedIn}) => {
                        if(loggedIn && daysOrSoldString) return <p className={`badge badge-default mb-2`}>{daysOrSoldString}</p>
                      }}
                    </CurrentUserConsumer>
                  </div>
                </div>
              </div>
            </header>

            <div className="row">
              <div className="col">
                {renderCarousel(photos)}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Link to={{pathname: `/listings/${property.slug}`, state: {modal: true}}}>
                  <h5 className="mb-1 mt-2">{property.address}</h5>
                  <h6>{property.city}, {property.postal_code}</h6>
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div><span className="text-muted">Beds:</span>&nbsp; {property.beds_total || 0}</div>
                <div><span className="text-muted">Baths:</span>&nbsp; {property.baths_total || 0}</div>
                <div><span className="text-muted">Sqft:</span>&nbsp; {property.structure_sqft}</div>
                <div><span className="text-muted">Price Per Sqft:</span>&nbsp; ${property.price_per_sqft}</div>

                <p className="mt-2">
                  {property.public_remarks ? truncate(property.public_remarks, 140)  : 'No description available'}
                </p>
              </div>
            </div>

            <CurrentUserConsumer>
              {({loggedIn}) => {
                if( loggedIn) {
                  return(
                    <div className="row text-center">
                      <div className="col">
                        <VerdictWrapper verdict={property.verdict} listingEncodedId={property.encoded_id}>
                          {({onSave, saveClasses, loadingStyle}) => (
                            <button type="button" onClick={onSave} style={loadingStyle} className={`btn btn-sm ${saveClasses}`}>
                              <IoIosThumbsUp className="mr-1" />
                              Interested
                            </button>
                          )}
                        </VerdictWrapper>
                        &nbsp;
                        <VerdictWrapper verdict={property.verdict} listingEncodedId={property.encoded_id}>
                          {({onHide, hideClasses, loadingStyle}) => (
                            <button type="button" onClick={onHide} style={loadingStyle} className={`btn btn-sm ${hideClasses}`}>
                              <IoIosThumbsDown/>
                            </button>
                          )}
                        </VerdictWrapper>
                      </div>
                    </div>
                  )
                }
              }}
            </CurrentUserConsumer>
          </div>
          {_renderPhotosByRooms(photos, allRooms, index)}
          <div className="card-footer p-2">
            <p className="small">Location</p>
            <BasicMap latitude={property.latitude} longitude={property.longitude}/>
          </div>
          <div className="card-footer p-2">
            <p className="small">Details</p>
            {property.short_details.map((detail) => {
              return <div key={detail.label}><span className="text-muted">{detail.label}:</span>&nbsp; {detail.value}</div>
            })}
          </div>
          <Link to={{pathname: `/listings/${property.slug}`, state: {modal: true}}} className="card-footer d-block text-center text-dark small font-weight-semibold">
            View Full Listing
          </Link>
        </div>
      )
    } else {
      return null;
    }
}

export default CompareListing;
