import {
  IoIosHeart,
  IoMdHeartDislike,
  IoIosRemoveCircle
} from "react-icons/io";
import { Mutation } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import {toast} from "react-toastify";
import difference from "lodash/difference";
import { Component } from 'react';
import { HbAnalyticsClass } from '../../Common/AnalyticClasses'
import { MobileAndTablet } from '../../Common/ResponsiveComponents'

const SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION = gql`
  mutation set_feature_preference_sentiment($input: SetFeaturePreferenceSentimentInput!){
    set_feature_preference_sentiment(input: $input){
      user{
        id
        feature_preferences{
          name
          sentiment
        }
      }
      errors{
        key
        message
      }
    }
  }
`;

class ReviewFeaturePreferences extends Component {
  scrubFeaturePreferences(allFeaturePreferences, userReviewedFeaturePreferences) {
    const userFeaturePreferences = userReviewedFeaturePreferences.map((featurePreference) => featurePreference.name);
    return difference(allFeaturePreferences, userFeaturePreferences);
  }

  render() {
    const scrubbedFeaturePreferences = this.scrubFeaturePreferences(this.props.allFeaturePreferences, this.props.featurePreferences);

    if (scrubbedFeaturePreferences.length) {
      const featurePreference = scrubbedFeaturePreferences[0];
      return (
        <>
            <div><h5 className="my-2">{featurePreference}</h5></div>
            <Mutation
              mutation={SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION}
              onError={() => toast.error('Error, please try again')}
              onCompleted={(data) => {
                this.props.updateFeaturePreferences(data.set_feature_preference_sentiment.user.feature_preferences);
              }}
            >
              {(setFeaturePreferenceSentiment, {data}) => {
                return (
                  <>
                    <MobileAndTablet>
                      {(matches) => {
                        return (
                          <div className={`d-flex ${matches ? `flex-column` : `flex-row justify-content-between`} align-items-center`}>
                            <div  className="d-flex flex-row align-items-center">
                              <button
                                className={`btn btn-outline-success borderless ${HbAnalyticsClass('like', 'feature-pref-review')}`}
                                onClick={() => setFeaturePreferenceSentiment({variables: { input: { feature_preference: featurePreference, sentiment: 'like'}}})}
                              >
                                <IoIosHeart className="mr-1"/>
                                Like
                              </button>
                              <button
                                className={`ml-2 btn btn-outline-danger borderless ${HbAnalyticsClass('dislike', 'feature-pref-review')}`}
                                onClick={() => setFeaturePreferenceSentiment({variables: { input: { feature_preference: featurePreference, sentiment: 'dislike'}}})}
                              >
                                <IoMdHeartDislike className="mr-1" />
                                Dislike
                              </button>
                              <button
                                className={`ml-2 btn btn-outline-secondary borderless ${HbAnalyticsClass('neutral', 'feature-pref-review')}`}
                                onClick={() => setFeaturePreferenceSentiment({variables: { input: { feature_preference: featurePreference, sentiment: 'neutral'}}})}
                              >
                                <IoIosRemoveCircle className="mr-1" />
                                Neutral
                              </button>
                            </div>
                            <div className="ml-2 text-muted">
                              <small>{scrubbedFeaturePreferences.length} more</small>
                            </div>
                          </div>
                        )
                      }}
                    </MobileAndTablet>
                  </>
                )
              }}
            </Mutation>
        </>
      );
    }
    return null;
  }
}

export default ReviewFeaturePreferences;
