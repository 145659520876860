import { CurrentUserContext } from '../Contexts/CurrentUserContext'
import { Link, Typography, Box, Modal, Card, CardContent, Divider } from '@mui/material'
import { useContext, useState } from 'react'
import SignupDisclaimerText from '../Common/SignupDisclaimerText'
import SignUpForm from '@/components/Homebuyer/Common/SignUpForm'
import RsWindow from '@/components/Utils/WindowUtils'

const SignUpModal = ({ title, subtitle, showingSignUp, closeSignUp }) => {
  const userContext = useContext(CurrentUserContext)
  const modalTitle = title || 'Sign Up'
  const modalSubtitle = subtitle || 'To search & save your progress, you need an account'
  const closeModal = e => {
    e.stopPropagation()
    closeSignUp()
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxWidth: '100%',
    zIndex: '1400 !important',
  }

  return (
    <Modal open={showingSignUp} onClose={closeModal}>
      <Card sx={{ ...modalStyle }}>
        <CardContent>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h3" sx={{ m: 1 }}>
              {modalTitle}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {modalSubtitle}
            </Typography>
          </Box>
          <SignUpForm redirectPath={window.location.pathname} />
          <Box sx={{ textAlign: 'center' }} mt={2}>
            <Typography variant="disclaimer">
              <SignupDisclaimerText agent={userContext.agent} />
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardContent>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle2">
              Have an account? <Link href="/users/sign_in">Sign in</Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  )
}

export default SignUpModal
