import { Typography, Button, CircularProgress } from '@mui/material'
import { gql, useLazyQuery } from '@apollo/client'

const COMMUTE_TRAVEL_TIME_QUERY = gql`
  query commutesQuery($commuteId: ID!, $latitude: Float!, $longitude: Float!) {
    current_user {
      id
      commute: poi_by_id(id: $commuteId) {
        id
        travel_time(origin_latitude: $latitude, origin_longitude: $longitude) {
          travel_time
          is_unroutable
          has_error
        }
      }
    }
  }
`

const CalculateCommuteButton = ({ commuteId, latitude, longitude }) => {
  const [calcCommuteTime, { loading, error, data }] = useLazyQuery(COMMUTE_TRAVEL_TIME_QUERY, {
    variables: { commuteId, latitude, longitude },
  })

  if (loading) {
    return <CircularProgress size={14} sx={theme => ({ color: theme.colors.alpha.black[50] })} />
  }

  const travelTimeData = data?.current_user?.commute?.travel_time || {}

  if (error || travelTimeData.has_error) {
    return <Typography color="text.secondary">Error: Unable to calculate</Typography>
  }

  if (travelTimeData.is_unroutable) {
    return <Typography> 60 Minutes</Typography>
  }

  if (travelTimeData.travel_time) {
    return <Typography>{Math.ceil(travelTimeData.travel_time / 60)} Minutes</Typography>
  }

  return (
    <Button size="small" onClick={calcCommuteTime}>
      Calculate Commute
    </Button>
  )
}

export default CalculateCommuteButton
