import { Component } from 'react';
import moment from 'moment';
import RsStorage from './../../Utils/RsStorageUtils';
import styles from './AgentBanner.module.scss';
import {
  IoIosAlert,
  IoIosClose
} from "react-icons/io";
import { TabletAndDesktop } from '../../Common/ResponsiveComponents'

class AgentBanner extends Component {
  state = {
    showAgentBanner:  RsStorage.getOrInitItem('showAgentBanner', true, moment().day(7))
  };

  handleOnClick(e) {
    e.preventDefault();
    this.setState({ showAgentBanner: false }, RsStorage.setItem('showAgentBanner', false))
  }


  render () {
    if (this.state.showAgentBanner) {
      return (
        <TabletAndDesktop>
          <div className={`${styles['agent-buyer-banner']} bg-primary`} id="agent-buyer-banner">
            <div className="d-flex flex-row row my-2">
              <div className="col text-white d-flex flex-row justify-content-center align-items-center">
                <IoIosAlert className={`${styles['icon-size']} mr-2`} />
                <strong>Hi {this.props.agentName}, you're currently viewing the homebuyer experience.</strong>
                <a href="/agent">
                  <button type="button" className="btn btn-primary border-white ml-4">
                    Return to Agent Dashboard
                  </button>
                </a>
                <button
                    className="btn icon-btn btn-primary borderless ml-1"
                    onClick={(e) => this.handleOnClick(e)}
                >
                  <IoIosClose className={`${styles['icon-size']}`} />
                </button>
              </div>
            </div>
          </div>
        </TabletAndDesktop>
      );
    }
    return null;
  }
}

export default AgentBanner;
