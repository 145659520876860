// This route will enforce the "Forced Sign Up" modal if it is set

import { CurrentUserConsumer } from '../Contexts/CurrentUserContext'
import { Route } from 'react-router-dom'
import SignUpModal from './SignUpModal'

function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <CurrentUserConsumer>
      {({ showForcedRegistration }) => {
        return (
          <Route
            {...rest}
            render={props => (
              <>
                <Component {...props} />
                <SignUpModal showingSignUp={showForcedRegistration} closeSignUp={() => {}} />
              </>
            )}
          />
        )
      }}
    </CurrentUserConsumer>
  )
}

export default ProtectedRoute
