import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import QuickMessageCard, { SEND_QUICK_MESSAGE } from '../../../Common/QuickMessageCard'
import { displayMessageResultSnackbar } from '../../../Common/Conversations/MessageResultSnackbar'

export const AGENT_MESSAGE_WIDGET_FIELDS = {
  homeReportFields: gql`
    fragment HomeReportFields on HomeReport {
      agent_message
    }
  `,
}

const AgentChatWidget = ({ agent, agentMessage, homeReportId, previewMode }) => {
  const { enqueueSnackbar } = useSnackbar()

  const [sendMessage, { loading: sendingMessage }] = useMutation(SEND_QUICK_MESSAGE, {
    onCompleted: data =>
      displayMessageResultSnackbar(data.create_conversation_message, enqueueSnackbar),
  })

  const submitMessage = message => {
    if (previewMode) return null
    sendMessage({
      variables: {
        input: {
          attachment_ids: [homeReportId],
          recipient: agent.id,
          body: message,
        },
      },
    })
  }

  const quickMessages = [
    { message: `Hi ${agent.first_name}` },
    { message: 'Is now a good time to sell?' },
  ]

  return (
    <QuickMessageCard
      agentFirstName={agent.first_name}
      agentPhotoUrl={agent.photo_url}
      agentMessage={agentMessage}
      title={`Quick Message to ${agent.first_name}`}
      quickMessages={quickMessages}
      submitMessage={submitMessage}
      textFieldId={'hva-quick-message'}
      sendButtonId={'hva-quick-message-send'}
      sendingMessage={sendingMessage}
    />
  )
}

export default AgentChatWidget
