import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import QuickMessageCard, { SEND_QUICK_MESSAGE } from '../../../Common/QuickMessageCard'
import { displayMessageResultSnackbar } from '../../../Common/Conversations/MessageResultSnackbar'
import { CardContent, Link, Typography } from '@mui/material'
import { FOR_RENT, FOR_SALE } from '../../../Common/Constants/PropertyConstants'

const CREATE_SHOWING_REQUEST = gql`
  mutation createShowingRequest($input: CreateShowingRequestMutationInput!) {
    create_showing_request(input: $input) {
      showing_request {
        id
      }
      conversation {
        id
      }
      errors
    }
  }
`

const MessageHistoryFooter = () => {
  return (
    <CardContent>
      <Typography variant={'body2'}>
        You have sent a message about this listing.{' '}
        <Link href={'/homesearch/conversations'}>View your conversation</Link>
      </Typography>
    </CardContent>
  )
}

const AgentChatWidget = ({
  agent,
  listingId,
  listingEncodedId,
  listingStatus,
  listingConversation,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [sendMessage, { loading: sendingMessage, data: quickMessageMutationData }] = useMutation(
    SEND_QUICK_MESSAGE,
    {
      onCompleted: data =>
        displayMessageResultSnackbar(data.create_conversation_message, enqueueSnackbar),
    }
  )

  const [createShowingRequest, { data: showingRequestMutationData }] = useMutation(
    CREATE_SHOWING_REQUEST,
    {
      onCompleted: data =>
        displayMessageResultSnackbar(data.create_showing_request, enqueueSnackbar),
    }
  )

  const submitMessage = message => {
    sendMessage({
      variables: {
        input: {
          attachment_ids: [listingEncodedId],
          recipient: agent.id,
          body: message,
        },
      },
    })
  }

  const createShowingRequestMessage = () => {
    createShowingRequest({
      variables: {
        input: {
          property_id: listingId,
          recipient: agent.id,
        },
      },
    })
  }

  const isOnMarket = [FOR_SALE, FOR_RENT].includes(listingStatus)

  const chatPrompt = isOnMarket
    ? 'Have any questions about this listing? Want to visit?'
    : 'Have any questions about this listing?'

  const quickMessages = [
    ...(isOnMarket
      ? [{ message: `Request Showing`, onPressMessage: createShowingRequestMessage }]
      : []),
    // Add other quick messages here as needed:
    // { message: 'Some quick message', onPressMessage: someCallbackFunction }
  ]

  const hasMessageHistory =
    !!listingConversation ||
    quickMessageMutationData?.create_conversation_message?.conversation ||
    showingRequestMutationData?.create_showing_request?.conversation

  return (
    <QuickMessageCard
      agentFirstName={agent?.first_name}
      agentPhotoUrl={agent?.photo_url}
      agentCompanyName={agent?.company_name}
      agentMessage={chatPrompt}
      quickMessages={quickMessages}
      submitMessage={submitMessage}
      textFieldId={'ldp-quick-message'}
      sendButtonId={'ldp-quick-message-send'}
      sendingMessage={sendingMessage}
      renderFooterComponent={hasMessageHistory ? () => <MessageHistoryFooter /> : null}
    />
  )
}

export default AgentChatWidget
