import { Component } from 'react';

export default class NotFound extends Component {
  render(){
    return(
        <div className="container-fluid d-flex ui-mh-100vh">
          <div className="row align-items-start w-100 py-5">

            <div className="col order-2 order-md-1 text-md-left text-center">
              <h1 className="display-2 font-weight-bolder mb-4">Page Not Found</h1>
              <div className="text-xlarge font-weight-light mb-2">We couldn't find the page you are looking for.</div>
              <div className="text-xlarge font-weight-light mb-2">Try going back, or use the sidebar to navigate to a new page.</div>
            </div>

          </div>
        </div>
    )
  }
}
