import { Component } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { matchPath, Route, withRouter, Link } from 'react-router-dom'
import ConversationHeader from '../../../Common/Conversations/ConversationHeader'
import ConversationMessages from '../../../Common/Conversations/ConversationMessages'
import ConversationMessageInput from '../../../Common/Conversations/ConversationMessageInput'
import HomebuyerAttachmentsSidebar from './HomebuyerAttachmentsSidebar'
import HomebuyerAttachmentSelector from './HomebuyerAttachmentSelector'
import LoadingIcon from '../../../Legacy/RsLoadingCircle'
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

const CONVERSATION_THREAD_QUERY = gql`
  query ConversationThreadQuery($endCursor: String, $numberOfMessages: Int) {
    current_user {
      id
      agent {
        id
        full_name
        photo_url
      }
      conversation @connection(key: "conversations") {
        id
        participants {
          edges {
            node {
              ... on Agent {
                full_name
                photo_url
                id
              }
              ... on User {
                full_name
                id
              }
            }
          }
        }
        messages(first: $numberOfMessages, after: $endCursor) @connection(key: "messages") {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
          }
          edges {
            cursor
            node {
              body
              attachments(first: 2) @connection(key: "attachments") {
                total_count
                edges {
                  node {
                    ... on Property {
                      id
                      baths_total
                      beds_total
                      structure_sqft
                      address
                      city
                      state
                      postal_code
                      display_price
                      slug
                      photos(first: 1) {
                        edges {
                          node {
                            id
                            photo_url
                          }
                        }
                      }
                    }
                    ... on PremarketListing {
                      id
                    }
                    ... on HomeReport {
                      id
                      address
                      user {
                        id
                      }
                    }
                    ... on MarketAlert {
                      id
                      market_name
                      user {
                        id
                      }
                    }
                    ... on ShowingRequest {
                      id
                      property {
                        id
                        slug
                        address
                        photos(first: 1) {
                          edges {
                            node {
                              id
                              photo_url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              id
              created_at
              sender: sender_participant {
                id: participant_id
                full_name
              }
            }
          }
        }
      }
    }
  }
`

class HomebuyerConversationThread extends Component {
  renderListingLink({ listingSlug, renderAttachment }) {
    return (
      <Link
        to={{ pathname: `/listings/${listingSlug}`, state: { modal: true } }}
        className={HbAnalyticsClass('listing-click', 'conversation-thread')}
      >
        {renderAttachment()}
      </Link>
    )
  }

  render() {
    const attachmentPath = `/conversations/attachments/:messageId`
    const sidebarActive = matchPath(this.props.location.pathname, { path: attachmentPath })

    return (
      <Query
        query={CONVERSATION_THREAD_QUERY}
        variables={{ numberOfMessages: 10 }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, fetchMore, refetch }) => {
          if (data && data.current_user) {
            let participant
            let messages
            let endCursor
            let areThereMoreMessages
            let startCursor

            const { conversation } = data.current_user
            if (conversation) {
              const participantArrayWithoutUser = conversation.participants.edges.filter(
                participant => participant.node.id !== data.current_user.id
              )
              participant = participantArrayWithoutUser[0].node // Will need to update when conversations include more than two participants
              messages = conversation.messages.edges
              startCursor = conversation.messages.pageInfo.startCursor
              endCursor = conversation.messages.pageInfo.endCursor

              areThereMoreMessages = conversation.messages.pageInfo.hasNextPage
            } else {
              participant = data.current_user.agent
              messages = []
              areThereMoreMessages = false
            }

            const fetchMoreMessages = () =>
              fetchMore({
                variables: { endCursor, numberOfMessages: 5 },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev
                  fetchMoreResult.current_user.conversation.messages.edges = [
                    ...prev.current_user.conversation.messages.edges,
                    ...fetchMoreResult.current_user.conversation.messages.edges,
                  ]
                  return fetchMoreResult
                },
              })
            return (
              <div className="d-flex col-12 flex-column">
                <ConversationHeader participant={participant} />

                <hr className="border-light m-0" />

                <div className="row">
                  <div className={`${sidebarActive ? 'col-8' : 'col-12'}`}>
                    <ConversationMessages
                      messages={messages}
                      startCursor={startCursor}
                      senderId={data.current_user.id}
                      participant={participant}
                      fetchMoreMessages={() => fetchMoreMessages()}
                      areThereMoreMessages={areThereMoreMessages}
                      isQueryLoading={loading}
                      renderListingLink={this.renderListingLink}
                    />

                    <hr className="border-light m-0" />

                    <ConversationMessageInput
                      participantId={participant.id}
                      sendMessageCallback={() => {
                        if (!conversation) {
                          refetch()
                        }
                      }}
                    >
                      {({ attachListing, removeListing, attachedListings }) => {
                        const selectedIds = attachedListings.map(listing => listing.id)
                        return (
                          <HomebuyerAttachmentSelector
                            selectedIds={selectedIds}
                            removeListing={removeListing}
                            attachListing={attachListing}
                          />
                        )
                      }}
                    </ConversationMessageInput>
                  </div>
                  {sidebarActive && (
                    <div className="col-4">
                      <Route path={attachmentPath} component={HomebuyerAttachmentsSidebar} />
                    </div>
                  )}
                </div>
              </div>
            )
          }

          if (loading) {
            return (
              <div className="d-flex col flex-column">
                <LoadingIcon />
              </div>
            )
          }

          return null
        }}
      </Query>
    )
  }
}

export default HomebuyerConversationThread
