import { Component } from 'react';
import {Map, TileLayer, Marker, FeatureGroup, LayersControl} from 'react-leaflet'
import {divIcon} from "leaflet";
import {TILE_URL, MAP_ATTRIBUTION, SATELLITE_TILE_URL} from './../../Utils/MapUtils';
import {
  IoIosHome
} from "react-icons/io";
import ReactDOMServer from 'react-dom/server';
import LazyLoad from 'react-lazyload';

class BasicMap extends Component{
  state = {
    zoom: 15,
  };

  render(){
    const latitude = this.props.latitude;
    const longitude = this.props.longitude;
    const position = [latitude, longitude];
    const icon = divIcon({
      type: 'div',
      className: `search-marker`,
      popupAnchor: [2, -25],
      iconSize: [20, 20],
      iconAnchor: [21, 21],
      html: ReactDOMServer.renderToString(<IoIosHome style={{ height: '20px', width: '20px'}}/>)
    });

    return(
        <div>
          <LazyLoad height={300}>
            <Map center={position} zoom={this.state.zoom} style={{height: 300}} scrollWheelZoom={false}>
              <LayersControl position="topright">
                <LayersControl.BaseLayer name="Streets" checked={true}>
                  <TileLayer
                      attribution={MAP_ATTRIBUTION}
                      url={TILE_URL}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer
                      attribution={MAP_ATTRIBUTION}
                      url={SATELLITE_TILE_URL}
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <FeatureGroup ref="purple">
                <Marker position={[latitude, longitude]} icon={icon}/>
              </FeatureGroup>
            </Map>
          </LazyLoad>
        </div>
    )
  }
}

export default BasicMap;
