import { gql, useMutation } from '@apollo/client'
import { Button, Stack, Typography } from '@mui/material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { useContext } from 'react'
import { LIKE, NEUTRAL } from '@/components/Common/Constants/FeaturePreferenceConstants'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'

const SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION = gql`
  mutation set_feature_preference_sentiment($input: SetFeaturePreferenceSentimentInput!) {
    set_feature_preference_sentiment(input: $input) {
      user {
        id
        feature_preferences {
          name
          sentiment
        }
      }
      errors {
        key
        message
      }
    }
  }
`

const LikeFeatureButton = ({ featureName, isLiked }) => {
  const { loggedIn } = useContext(CurrentUserContext)
  const [setFeaturePreference, { loading }] = useMutation(SET_FEATURE_PREFERENCE_SENTIMENT_MUTATION)

  const handleClick = () => {
    if (!loggedIn) return
    setFeaturePreference({
      variables: {
        input: {
          feature_preference: featureName,
          sentiment: isLiked ? NEUTRAL : LIKE,
        },
      },
    })
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Button
        size="small"
        onClick={handleClick}
        disabled={loading || !loggedIn}
        color={isLiked ? 'success' : 'gray'}
        variant={isLiked ? 'contained' : 'outlined'}
        sx={{ minWidth: '0', px: 1.25 }}
        data-testid={`${featureName}-${isLiked ? LIKE : NEUTRAL}`}
      >
        {isLiked ? <FavoriteIcon fontSize="small" /> : <FavoriteBorderIcon fontSize="small" />}
      </Button>
      <Typography>{featureName}</Typography>
    </Stack>
  )
}

export default LikeFeatureButton
