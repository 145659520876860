import { Button, CardContent, Grid, TextField, Typography } from '@mui/material'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import { useContext } from 'react'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'
import { useInput } from '@/components/CustomHooks/InputHook'
import { gql, useMutation, useQuery } from '@apollo/client'
import RsPageLoading from '@/components/Common/UIComponents/PageLayout/RsPageLoading'
import { useHistory } from 'react-router-dom'

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    update_user(input: $input) {
      user {
        id
        first_name
        last_name
        primary_mobile_phone
      }
      errors {
        key
        message
      }
    }
  }
`

const UpdateAccountForm = ({ user }) => {
  const { agent } = useContext(CurrentUserContext)
  const history = useHistory()
  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER_MUTATION, {
    onCompleted: () => {
      history.push('/requirements')
    },
  })
  const { value: firstName, bind: bindFirstName } = useInput(user.first_name)
  const { value: lastName, bind: bindLastName } = useInput(user.last_name)
  const { value: phone, bind: bindPhone } = useInput(user.primary_mobile_phone)

  return (
    <RsCard title="Confirm your details to setup your account">
      <CardContent sx={{ mt: 2, mb: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="First Name" {...bindFirstName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Last Name" {...bindLastName} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Phone Number" {...bindPhone} />
          </Grid>
        </Grid>
      </CardContent>
      <RsCardFooterAction>
        <Button
          fullWidth
          variant="contained"
          disabled={updateUserLoading}
          onClick={e =>
            updateUser({
              variables: {
                input: {
                  id: user.id,
                  first_name: firstName,
                  last_name: lastName,
                  primary_mobile_phone: phone,
                },
              },
            })
          }
        >
          Next
        </Button>
      </RsCardFooterAction>
    </RsCard>
  )
}

const USER_BASICS_QUERY = gql`
  query agentContactInfoQuery {
    current_user {
      id
      first_name
      last_name
      primary_mobile_phone
    }
  }
`

const UpdateAccount = () => {
  const { data, loading } = useQuery(USER_BASICS_QUERY)
  if (loading) return <RsPageLoading />
  return <UpdateAccountForm user={data.current_user} />
}

export default UpdateAccount
