import React, { useContext } from 'react'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'
import { Box, Typography, Link, Stack } from '@mui/material'

const Disclaimers = () => {
  const splitContext = useContext(SplitLayoutContext)
  const userContext = useContext(CurrentUserContext)
  const { isExclusivesSearch } = splitContext
  const { disclaimers, exclusivesDisclaimer, agent, displayRules } = userContext

  return (
    <Stack spacing={1} mt={2} mb={15}>
      {displayRules.show_office_address && agent.office_address && (
        <Typography variant="body2">
          {agent.office_name} - {agent.office_address}
        </Typography>
      )}
      {!isExclusivesSearch &&
        disclaimers.map((disclaimerData, i) =>
          displayRules.show_logos_with_disclaimer ? (
            <Box key={i} display="flex" alignItems="center">
              { disclaimerData.logo &&
                <Box
                  component="img"
                  src={disclaimerData.logo}
                  sx={{ height: 30, marginRight: 2 }}
                />
              }
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: disclaimerData.disclaimer }}
              />
            </Box>
          ) : (
            <Typography
              key={i}
              variant="body2"
              dangerouslySetInnerHTML={{ __html: disclaimerData.disclaimer }}
            />
          )
        )}
      {isExclusivesSearch && (
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: exclusivesDisclaimer }} />
      )}
      {agent.enterprise_disclaimer && (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: agent.enterprise_disclaimer }}
        />
      )}
      <Typography variant="body2">Listing data is updated every 5 minutes.</Typography>
      <Typography variant="body2">
        Attendance zones provided by Pitney Bowes. Please consult school district to confirm.
      </Typography>
      <Typography variant="body2">
        {agent.enterprise_disclaimer_url && (
          <>
            <Link href={agent.enterprise_disclaimer_url} target="_blank">
              {agent.name} & {agent.enterprise_name} Terms/Policies
            </Link>
            {' - '}
          </>
        )}
        <Link href="/client-terms" target="_blank">
          Terms
        </Link>
        {' - '}
        <Link href="/service-provider-privacy" target="_blank">
          Privacy Policy
        </Link>
        {agent.has_custom_disclaimer && (
          <>
            {' - '}
            <Link href="/agent-disclaimer" target="_blank">
              Agent Disclaimer/Policies
            </Link>
          </>
        )}
      </Typography>
    </Stack>
  )
}

export default Disclaimers
