import { Button, CardContent, Typography, Box, Link } from '@mui/material'
import RsModal from './RsModal'
import RsCardFooterAction from '../Card/RsCardFooterAction'

const DisclaimerTextModal = ({ smallText, disclaimerTitle, children }) => (
  <RsModal
    title={disclaimerTitle}
    modalStylesProp={{ width: '400px' }}
    renderTriggerComponent={({ onOpen }) => (
      <Typography variant="disclaimerModal" onClick={onOpen}>
        {smallText}
      </Typography>
    )}
    renderContentComponent={({ onClose }) => (
      <>
        <CardContent>
          <Typography>{children}</Typography>
        </CardContent>
        <RsCardFooterAction>
          <Button variant="contained" fullWidth onClick={onClose}>
            Done
          </Button>
        </RsCardFooterAction>
      </>
    )}
  />
)

export default DisclaimerTextModal
