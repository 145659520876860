import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Card,
  Stack,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';

const Faq = () => {
  const bodyTextProps = { variant: 'h6', fontWeight: 'normal', color: 'text.primary'}

  const customAnswerText = [
    'Pricing your home for sale',
    'Estimating your budget on a new home',
    'Understanding your home equity',
    'Evaluating a remodel versus a move',
    'General awareness of market trends'
  ]

  const items = [
    {
      question: 'Why stay up-to-date with your property value?',
      customAnswer: true,
      answer: <Stack direction='column' spacing={2}>
        <Typography {...bodyTextProps}>
          Understanding your home's value empowers you to make strategic financial decisions related to your home investment, such as:
        </Typography>
        <Stack direction='column' spacing={1} sx={{px: { xs: 2, md: 6}}}>
          {
            customAnswerText.map((text, i) => {
              return (
                <Stack direction='row' spacing={1} alignItems='center' key={i}>
                  <CircleIcon sx={(theme) => ({ fontSize: '8px', color: theme.colors.alpha.black[100]})} />
                  <Typography {...bodyTextProps}>
                    { text }
                  </Typography>
                </Stack>
              )
            })
          }
        </Stack>
        <Typography {...bodyTextProps}>
          Your home report can be viewed anytime and is updated monthly with revised valuations, buyer demand stats and recent sales activity.
        </Typography>
      </Stack>
    },
    {
      question: 'How is a home value estimate calculated?',
      answer: 'Automated valuations are computer generated typically based on historical data. They do not take into account unique upgrades, recent renovations, and specifics of the demand in your local market, therefore they may not represent the true value of the home. We offer multiple valuations from different providers to show estimates from different perspectives. A local real estate professional can provide an analysis to give you a better understanding of your home’s value, the demand and market trends in your area.'
    },
    {
      question: 'What factors affect my home’s value?',
      answer: 'Home values will increase along with demand for homes in your area and price range. Additionally, updates to your home including renovations, cosmetic improvements, or adding outdoor spaces will also contribute to your home’s value by making it more attractive to potential buyers. Your agent can help you determine the types of improvements that can directly affect your home’s value and drive demand for your home.'
    },
    {
      question: 'How to price your home for sale?',
      answer: 'Start with your automated valuation as a benchmark for your home value in today’s market. Compare your home to similar homes sold recently in your area to better understand the current value. View the Buyer Audience graph on your home report. See how changes in the listing price may affect the number of potential buyers searching for a home at your price range. Reach out to your real estate agent for their local expertise and a more detailed analysis of your home’s value taking into account your unique amenities, specific location and local market conditions.'
    },
    {
      question: 'Will my personal details be shared?',
      answer: 'Never. All details shared stay exclusive between you and your agent - no other agents or third parties will have access to your information.'
    }
  ]

  return (
    <Box display='flex' justifyContent='center'>
      <Box>
        <Typography
          variant='h3'
          textAlign='center'
          sx={{pb: 4}}
        >
          Frequently Asked Questions
        </Typography>
        <Card>
          <Stack
            direction='column'
            divider={<Divider />}
          >
            {
              items.map((item, idx) => {
                return (
                  <Accordion key={idx} sx={{py: 0.5}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography variant='h5' color='primary'>{ item.question }</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        item.customAnswer ? item.answer
                          :
                        <Typography {...bodyTextProps}>
                          { item.answer }
                        </Typography>
                      }
                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </Stack>
        </Card>
      </Box>
    </Box>
  )
}

export default Faq
