import { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import queryString from 'query-string'

import Sidebar from './Layout/Sidebar'
import Header from './Layout/Header'

import Matches from './Pages/Matches'
import Search from './Pages/Search'
import Interested from './Pages/Interested'
import Uninterested from './Pages/Uninterested'
import Viewed from './Pages/Viewed'
import Exclusives from './Pages/Exclusives'
import SavedSearch from './Pages/SavedSearch'
import ListingDetail from './Pages/ListingDetail'
import MyProfile from './Pages/MyProfile'
import Compare from './Pages/Compare'
import NotFound from './Pages/NotFound'
import HomeReport from './Pages/HomeReport'
import Conversations from './Pages/Conversations'
import MarketActivityAlert from './Pages/MarketActivityAlert'
import ErrorBoundary from '../Common/ErrorBoundary'
import ListingModal from './Layout/ListingModal'
import SignUpModal from './Layout/SignUpModal'
import AgentBanner from './Layout/AgentBanner'
import { CurrentUserContext } from './Contexts/CurrentUserContext'
import PrivateRoute from './Layout/PrivateRoute'
import ProtectedRoute from './Layout/ProtectedRoute'
import RedirectRoute from '../Common/Layout/RedirectRoute'
import HomeReportLandingPage from './Pages/HomeReportLandingPage'

class HomebuyerLayout extends Component {
  static contextType = CurrentUserContext

  previousLocation = this.props.location

  componentDidMount() {
    // Remove Auth Tokens from URL, to protect against link sharing of tokens for LDPs
    // Note, this will break redirection if it's run on a re-direct route
    const { history, location } = this.props
    const onListingPage = location && location.pathname && location.pathname.includes('listings')
    const hasAuthToken = location && location.search && location.search.includes('auth_token')
    if (onListingPage && hasAuthToken) {
      const { auth_token, ...rest } = queryString.parse(location.search)
      const search = queryString.stringify(rest)
      history.replace({
        ...location,
        search,
      })
    }
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props

    // set previousLocation if props.location is not modal
    if (nextProps.history.action !== 'POP' && (!location.state || !location.state.modal)) {
      this.previousLocation = this.props.location
    }
  }

  render() {
    const { location } = this.props

    const isModal = !!(location.state && location.state.modal && this.previousLocation !== location) // not initial render

    return (
      <div className="layout layout-nav-side">
        <div className="layout-wrapper layout-2">
          <div className="layout-inner">
            <Sidebar />
            <div className="layout-container">
              <Header />
              <main className="layout-content" id="layout-content">
                <ErrorBoundary isInnerErrorBoundary>
                  <Switch location={isModal ? this.previousLocation : location}>
                    <ProtectedRoute path="/map" component={Search} />
                    {this.context.displayRules.client_exclusives_enabled && (
                      <Route path="/exclusives" component={Exclusives} />
                    )}
                    <Route path="/compare/:ids" component={Compare} />
                    <Route path="/compare" component={Compare} />

                    <PrivateRoute path="/matches" component={Matches} />
                    <PrivateRoute path="/interested" component={Interested} />
                    <PrivateRoute path="/uninterested" component={Uninterested} />
                    <PrivateRoute path="/viewed" component={Viewed} />
                    <PrivateRoute path="/searches/:id" component={SavedSearch} />
                    <PrivateRoute path="/profile" component={MyProfile} />
                    <PrivateRoute path="/conversations" component={Conversations} />

                    <PrivateRoute
                      path="/market-activity-alerts/:id"
                      component={MarketActivityAlert}
                    />
                    <PrivateRoute path="/home-reports/:id" component={HomeReport} />
                    <Route path="/home-reports" component={HomeReportLandingPage} />

                    <RedirectRoute path="/my-homes/hidden" redirectTo="/uninterested" />
                    <RedirectRoute path="/my-homes/saved" redirectTo="/interested" />
                    <RedirectRoute path="/my-homes" redirectTo="/matches" />

                    <ProtectedRoute path="/listings/matched/:id" component={ListingDetail} />
                    <ProtectedRoute path="/listings/:id" component={ListingDetail} />

                    <Route component={NotFound} />
                  </Switch>
                  {isModal ? (
                    <Route
                      path="/listings/:id"
                      render={props => (
                        <ListingModal {...props} previousLocation={this.previousLocation} />
                      )}
                    />
                  ) : null}
                  {this.context.showingSignUp && (
                    <SignUpModal
                      showingSignUp={this.context.showingSignUp}
                      closeSignUp={this.context.closeSignUp}
                    />
                  )}
                  {this.context.agent.agent_logged_in && (
                    <AgentBanner agentName={this.context.agent.name} />
                  )}
                </ErrorBoundary>
              </main>
            </div>
          </div>
          <div
            className="layout-overlay layout-sidenav-toggle"
            onClick={() => window.layoutHelpers.toggleCollapsed()}
          />
        </div>
      </div>
    )
  }
}

export default HomebuyerLayout
