import { gql, useQuery } from '@apollo/client'
import Heatmap, { HEATMAP_FRAGMENT } from '../../../Common/Heatmap'
import MuiErrorBoundarySimple from '../../../Common/MuiErrorBoundarySimple'
import RsCard from '../../../Common/UIComponents/Card/RsCard'

const MARKET_ACTIVITY_ALERT_HEATMAP_QUERY = gql`
  query marketActivityHeatmapQuery($marketAlertId: ID!, $start_time: Time, $interval: MetricIntervalEnum) {
    current_user {
      id
      timezone
      market_alert: market_alert_by_id(id: $marketAlertId) {
        id
        report(start_time: $start_time, interval: $interval) {
          ...Heatmap
        }
      }
    }
  }
  ${HEATMAP_FRAGMENT}
`

const MarketActivityHeatmap = ({ marketAlertId, marketGeoJson, startTime }) => {
  const { data, loading } = useQuery(MARKET_ACTIVITY_ALERT_HEATMAP_QUERY, {
    variables: {
      marketAlertId,
      startTime: startTime.value,
      interval: startTime.interval,
    },
  })

  const heatmapData = data?.current_user?.market_alert?.report?.heatmap_data

  return (
    <RsCard title="Areas with Popular Listings">
      <MuiErrorBoundarySimple>
        <Heatmap
          key={heatmapData ? 'heat-map' : 'fake-heat-map'} // Causes a remount, required for Heatmap to render
          data={heatmapData}
          loading={loading}
          geoJson={marketGeoJson}
          height={450}
        />
      </MuiErrorBoundarySimple>
    </RsCard>
  )
}

export default MarketActivityHeatmap
