import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, styled, Typography, Chip, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'
import { priceWithFallback } from '../../../../Utils/NumberUtils'
import { analytics } from '../../../../Common/Constants/AnalyticsDataAttributes'
import { HbAnalyticsClass } from '../../../../Common/AnalyticClasses'

export const CtaListingCard = ({ previewMode }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const width = isSmallerThanMd || previewMode ? 200 : 240

  const cardStyle = {
    width,
    minWidth: width,
    backgroundColor: theme.colors.primary.main,
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: 2,
  }

  return (
    <Card variant="outlined" sx={cardStyle} boxShadow={3}>
      <CardContent sx={{ height: '100%', width: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-evenly" flexDirection="column" sx={{ height: '100%', width: '100%' }}>
          <Typography variant="h3" color="white" textAlign="center" sx={{ py: 2 }}>
            Want to view more listings?
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white' },
              color: theme.colors.primary.main,
            }}
            component={RouterLink}
            to={previewMode ? null : '/map'}
            data-rs-ph-event-name={analytics.homeReport.viewMoreListings}
          >
            Browse Listings
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

const BoldDivider = styled(Divider)(
  ({ theme }) => `
      background: ${theme.colors.primary.light};
      height: 5px;
  `
)

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .MuiTouchRipple-root {
        opacity: .3;
    }

    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .05;
        }
    }
  `
)

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(1)};
    bottom: ${theme.spacing(1)};
    z-index: 7;
    display: flex;
  `
)

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.gradients.black1};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
)

const ListingCard = ({ listing, isSelected, previewMode }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  let cardStyle = { width: isSmallerThanMd || previewMode ? 200 : 240 }

  if (isSelected) {
    const selectedStyle = {
      '&.MuiCard-root': {
        border: `2px solid ${theme.colors.info.main}`,
      },
    }
    cardStyle = { ...cardStyle, ...selectedStyle }
  }

  return (
    <Card variant="outlined" sx={cardStyle} boxShadow={3}>
      <CardActionAreaWrapper>
        <CardMedia component="img" className={`${HbAnalyticsClass('listing-click', 'home-report-listings')}`} height="140" image={listing.photos.edges[0].node.photo_url} alt="..." />
        {listing.sold_date && (
          <CardActions>
            <LabelWrapper>Sold on {listing.sold_date}</LabelWrapper>
          </CardActions>
        )}
      </CardActionAreaWrapper>
      <BoldDivider />
      <CardContent>
        <Typography gutterBottom noWrap variant="h3">
          {priceWithFallback(listing.display_price)}
        </Typography>
        <Typography gutterBottom noWrap variant="body1" fontWeight="700">
          {listing.beds_total} beds - {listing.baths_total} baths - {listing.structure_sqft} sqft
        </Typography>
        <Typography variant="body1" noWrap color="text.secondary">
          {listing.address}, {listing.city}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ListingCard
