import { useContext } from 'react'
import { Typography, Link, Stack, Box, useTheme, useMediaQuery } from '@mui/material'
import ListingFooter from '../../../../Common/ListingDetail/ListingFooter'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'

const Footer = ({ listing }) => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))

  const { agent } = useContext(CurrentUserContext)
  return (
    <ListingFooter listing={listing}>
      <Stack direction="column" spacing={1} py={2}>
        {agent.enterprise_disclaimer && <Typography>{agent.enterprise_disclaimer}</Typography>}
        <Typography>Powered By RealScout</Typography>
        <Typography>
          Need help? View our{' '}
          <Link target="_blank" href="https://support.realscout.com/hc/en-us/categories/200545255">
            support site
          </Link>{' '}
          for tutorials and other information.
        </Typography>
        <Stack
          direction={isSmallerThanSm ? 'column' : 'row'}
          spacing={1}
          divider={isSmallerThanSm ? null : <Box>-</Box>}
        >
          {agent.enterprise_disclaimer_url && (
            <Link href={agent.enterprise_disclaimer_url} target="_blank" rel="noreferrer">
              {agent.name} & {agent.enterprise_name} Terms/Policies
            </Link>
          )}
          <Link href="/client-terms">Terms</Link>
          <Link href="/service-provider-privacy">Privacy Policy</Link>
          {agent.has_custom_disclaimer && (
            <Link href="/agent-disclaimer" target="_blank">
              Agent Disclaimer/Policies
            </Link>
          )}
        </Stack>
      </Stack>
    </ListingFooter>
  )
}

export default Footer
