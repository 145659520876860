import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import {CurrentUserContext} from './../Contexts/CurrentUserContext';
import { Component } from 'react';

const VERDICT_CONSTANTS = {
  SAVED: 'saved',
  HIDDEN: 'hidden',
  UNDECIDED: 'undecided'
}

class VerdictWrapper extends Component {
  static contextType = CurrentUserContext;
  state = {
    loading: false
  };

  onSave = () => {
    const verdict = this.props.verdict;
    if(verdict && verdict.prose === VERDICT_CONSTANTS.SAVED){
      this.recordVerdict(VERDICT_CONSTANTS.UNDECIDED)
    }else{
      this.recordVerdict(VERDICT_CONSTANTS.SAVED)
    }
  };

  onHide = () => {
    const verdict = this.props.verdict;
    if(verdict && verdict.prose === VERDICT_CONSTANTS.HIDDEN){
      this.recordVerdict(VERDICT_CONSTANTS.UNDECIDED)
    }else{
      this.recordVerdict(VERDICT_CONSTANTS.HIDDEN)
    }
  }

  recordVerdict = (prose) => {
    const { loggedIn, showSignUp } = this.context;

    if (loggedIn) {
      this.setState({loading: true})
      this.props.setVerdict({
        prose: prose,
        property_id: this.props.listingEncodedId
      }).then((data) => {
        this.setState({loading: false})
      })
    } else {
      showSignUp();
    }
  };

  render(){
    const verdict = this.props.verdict;
    const isSaved = verdict && verdict.prose === VERDICT_CONSTANTS.SAVED;
    const isHidden = verdict && verdict.prose === VERDICT_CONSTANTS.HIDDEN;
    const loadingStyle = this.state.loading ? {opacity: 0.5} : {};
    const saveClasses = isSaved ? 'btn-success rs-analytics_hb_unsave' : 'btn-outline-success rs-analytics_hb_save';
    const hideClasses = isHidden ? 'btn-danger rs-analytics_hb_unhide' : 'btn-outline-danger rs-analytics_hb_hide';
    return(
        this.props.children({
          isLoading: this.state.loading,
          onSave: this.onSave,
          onHide: this.onHide,
          isSaved,
          isHidden,
          loadingStyle,
          saveClasses,
          hideClasses
        })
    )
  }

}


const setListingVerdict = gql`
      mutation set_listing_verdict($data: SetListingVerdictInput!) {
        set_listing_verdict(input: $data) {
          property {
            id
            verdict {
              id
              prose
              rating
            }
          }
          errors {
            key
            message
          }
        }
      }
`;

const VerdictWrapperWithMutation = graphql(setListingVerdict, {
  props: ({mutate}) => ({
    setVerdict: (data) => mutate({
      variables: {data: data}
    }),
  }),
})(VerdictWrapper);

export default  VerdictWrapperWithMutation;
