import { Box, Alert, Stack, Button } from '@mui/material'
import { useContext } from 'react'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'

const NotLoggedInView = () => {
  const { showSignUp } = useContext(CurrentUserContext)

  return (
    <Stack
      direction="column"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      minWidth="100%"
    >
      <Alert severity="warning">You must be logged in to view open house information.</Alert>
      <Stack direction="row" spacing={1}>
        <Box>
          <Button variant="contained" href="/users/sign_in">
            Sign In
          </Button>
        </Box>

        <Box>
          <Button variant="outlined" onClick={showSignUp}>
            Sign Up
          </Button>
        </Box>
      </Stack>
    </Stack>
  )
}

export default NotLoggedInView
