import {useState, useContext} from 'react'
import {Query} from '@apollo/client/react/components';
import {gql, useQuery} from '@apollo/client';
import {Helmet} from "react-helmet";
import CompareListing, {COMPARE_LISTING_FRAGMENT} from "./Compare/CompareListing";
import SuggestedListings from "./../Common/SuggestedListings";
import {CurrentUserContext} from "../Contexts/CurrentUserContext";
import {reportError} from "../../Common/ErrorCapture";
import LoadingIcon from '../../Legacy/RsLoadingCircle'
import { TabletAndDesktop } from '../../Common/ResponsiveComponents'

const CompareListingQuery = gql`
        query compareListings($property_1_id: ID!, $property_2_id: ID!, $property_3_id: ID!) {
          current_user {
            id
            property_1:property_by_id(id: $property_1_id) {
              ...CompareListing
            }
            property_2:property_by_id(id: $property_2_id) {
              ...CompareListing
            }
            property_3:property_by_id(id: $property_3_id) {
              ...CompareListing
            }
          }
        }
        ${COMPARE_LISTING_FRAGMENT.listing}
      `;

const Compare = ({match, history}) => {

  const userContext = useContext(CurrentUserContext);

  const [selectedListingId, setSelectedListingId] = useState()
  const [listingIds, setListingIds] = useState([])

  const getAllRooms = (properties) => {
    const rawRoomList = properties.map((property) => {
      return property.photos.edges.map((edge) => {
        return edge.photo.room_name || 'Misc Rooms';
      })
    });
    //Flatten, Unique List
    const flattedUniqueRooms = new Set([].concat.apply([], rawRoomList));
    const foundRooms = [...flattedUniqueRooms];
    const orderedFoundRooms = ['Kitchen', 'Living Room', 'Bathroom', 'Bedroom', 'Outside', 'Floor Plan', 'Misc Rooms',].filter((roomName) => {
      return foundRooms.includes(roomName);
    });
    return orderedFoundRooms;
  };

  const toggleListing = (listing) => {
    let splitPropertyIds = match.params.ids ? match.params.ids.split(',') : [];
    const listingIndex = splitPropertyIds.findIndex(listingSlugInCompare => listingSlugInCompare === listing.slug);
    const isListingInCompare = (listingIndex !== -1);

    if (isListingInCompare) {
      splitPropertyIds = splitPropertyIds.filter(listingSlugInCompare => listingSlugInCompare !== listing.slug)
    } else {
      splitPropertyIds.push(listing.slug)
    }
    const newCompareUrl = `/compare/${splitPropertyIds.join(',')}`;
    history.push({pathname: newCompareUrl})
  };

  const renderMobileHeader = (properties) => {
    if (properties.length) {
      return (
          <div className="text-center">
            <p className="mt-3">
              Select a Listing
            </p>
            <div className="row no-gutters row-bordered row-border-light">
              {properties.map((property, index) => (
                  <a href="javascript:void(0)"
                     key={property.id}
                     onClick={(e) => {
                       e.preventDefault();
                       setSelectedListingId(property.id)
                     }}
                     className="d-flex col flex-column text-dark py-4"
                     style={{
                       backgroundImage: `url(${property.photos.edges[0].photo.photo_url})`,
                       backgroundSize: 'cover',
                       height: 100
                     }}
                  >
                    <h1 style={{color: 'white'}}>{index + 1}</h1>
                  </a>
              ))}
            </div>
          </div>
      )
    } else {
      return null;
    }
  };

  const setSelectedListings = (data) => {
    if (data && data.current_user) {
      const {property_1, property_2, property_3} = data.current_user;
      const properties = [property_1, property_2, property_3].filter(x => !!x);

      setListingIds(properties.map((prop) => prop.id))
    }
  };

  let propertyIds = [];
  if (match.params.ids) {
    propertyIds = match.params.ids.split(',');
  }
  let suggestedTitle = "Select a listing to compare";

  const {loading, data, refetch} = useQuery(CompareListingQuery, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: reportError,
    onCompleted: setSelectedListings,
    variables: {
      property_1_id: propertyIds[0] || '0',
      property_2_id: propertyIds[1] || '0',
      property_3_id: propertyIds[2] || '0'
    }
  });

  const renderCompare = (propertyData) => {
    const {property_1, property_2, property_3} = propertyData.current_user;
    const properties = [property_1, property_2, property_3].filter(x => !!x);

    let allRooms = getAllRooms(properties);
    return (
        <TabletAndDesktop>
          {(matches) => {
            if (matches) {
              const columnWidthClass = {
                0: 'd-none',
                1: 'col-4',
                2: 'col-8',
                3: 'col'
              };
              return (
                  <>
                    <div className="row">
                      <div className={`card mb-5 ${columnWidthClass[properties.length]}`}>
                        <div className="row row-bordered">
                          {properties.map((property, index) => {
                                return (
                                    <div className="col" key={index} id={`compare-${index}`}>
                                      <CompareListing
                                          property={property}
                                          index={index}
                                          allRooms={allRooms}
                                          key={property.id}
                                          toggleListing={toggleListing}
                                      />
                                    </div>
                                )
                              }
                          )}
                        </div>
                      </div>
                      {propertyIds.length < 3 && userContext.loggedIn &&
                      <div className="col-4">
                        <SuggestedListings
                            title={suggestedTitle}
                            selectedIds={listingIds}
                            onUnselect={toggleListing}
                            onSelect={toggleListing}
                        />
                      </div>
                      }
                    </div>
                  </>
              )
            } else {
              const selectedListing = properties.find((prop) => prop.id === selectedListingId) || properties[0];
              return (
                  <div className="row">
                    <div className="col-12">
                      <div className={`card mb-2 ${!selectedListing && 'd-none'}`}>
                        <div className="row">
                          <div className="col">
                            {renderMobileHeader(properties)}
                            <CompareListing property={selectedListing} allRooms={allRooms}
                                            toggleListing={toggleListing}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {propertyIds.length < 3 && userContext.loggedIn &&
                    <div className="col-12">
                      <SuggestedListings
                          title={suggestedTitle}
                          selectedIds={listingIds}
                          onUnselect={toggleListing}
                          onSelect={toggleListing}
                      />
                    </div>
                    }
                  </div>
              )
            }
          }}
        </TabletAndDesktop>
    )
  }


  return (
      <div className="container-fluid">
        <h4 className="d-flex justify-content-between align-items-center w-100 font-weight-bold my-3">
          <div>Compare</div>
        </h4>
        <Helmet title="Compare"/>
        {
          loading ? <LoadingIcon/> : renderCompare(data)
        }
      </div>
  )
}

export default Compare;
