import {CurrentUserContext} from './../../Contexts/CurrentUserContext';
import {gql, useMutation} from '@apollo/client';
import validationUtils from "../../../Utils/ValidationUtils";
import RsWindow from "../../../Utils/WindowUtils";
import {useContext, useState} from "react";
import {useInput} from "../../../CustomHooks/InputHook";
import {Button, TextField, FormGroup, FormControlLabel, Checkbox, Box, Typography, Link} from "@mui/material";
import SignupDisclaimerText from '../../Common/SignupDisclaimerText'
const HOMEBUYER_SIGN_UP_MUTATION = gql`
      mutation homebuyer_sign_up($input: HomebuyerSignUpInput!){
        homebuyer_sign_up(input: $input){
          user{
            id
          }
          errors{
            key
            message
          }
          auth_token
        }
      }
    `;

const SignUp = ({listing}) => {
  const {value: email, bind: bindEmail} = useInput('')
  const {value: fullName, bind: bindFullName} = useInput('')
  const {value: phone, bind: bindPhone} = useInput('')
  const {value: message, bind: bindMessage} = useInput(`I am interested in ${listing.address}`)
  const [emailError, setEmailError] = useState(null);
  const [createListingAlert, setCreateListingAlert] = useState(true)
  const userContext = useContext(CurrentUserContext);
  const { agent } = userContext;

  const [homebuyerSignUp, {loading}] = useMutation(HOMEBUYER_SIGN_UP_MUTATION, {
    onCompleted: ({homebuyer_sign_up}) => {
      if (homebuyer_sign_up.auth_token) {
        RsWindow.location.assign(`${RsWindow.location.pathname}?auth_token=${homebuyer_sign_up.auth_token}`);
      } else if (homebuyer_sign_up.errors) {
        const errorMessage = homebuyer_sign_up.errors.map((error) => `${error.key} ${error.message}`).join(', ');
        setEmailError(errorMessage);
      }
    }
  })

  const validateEmail = () => {
    const emailValidation = validationUtils.email(email);
    if (emailValidation.hasError) {
      setEmailError(emailValidation.reason)
    }else{
      setEmailError(null)
    }
  }

  const submitSignUp = (e) => {
    e.preventDefault();
    const emailValidation = validationUtils.email(email);
    if (emailValidation.hasError){
      validateEmail()
    }else{
      let first_name, last_name;
      const nameParts = fullName.split(' ');
      if (nameParts.length === 1) {
        first_name = nameParts[0]
      } else {
        first_name = nameParts[0];
        last_name = nameParts.slice(1).join(' ');
      }
      homebuyerSignUp({
        variables: {
          input: {
            first_name: first_name,
            last_name: last_name,
            email: email,
            primary_mobile_phone: phone,
            message_text: message,
            create_alert: createListingAlert,
            property_id: listing.id,
          }
        }
      })
    }
  }

  return(
      <div className={`card mb-4`} id="listing-chat">
        <>
          <div className="card-header">
            <div className="media">
              <img src={agent.photo} className="ui-w-80 rounded-circle"/>
              <div className="media-body pt-1 ml-3">
                <Typography variant="h5">Have any questions?</Typography>
                <Typography variant="subtitle1">{agent.phone_number}</Typography>
                <Typography variant="subtitle1">{agent.office_name}</Typography>
              </div>
            </div>
          </div>
          <div className={`card-body`}>
            <form
              noValidate
              onSubmit={submitSignUp}
            >
                  <TextField
                    sx={{mb: 2}}
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={(theme) => ({mb: 2, backgroundColor: theme.colors.alpha.white[100]})}
                    id="sign-up-first-name"
                    label="Full Name"
                    {...bindFullName}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    onBlur={validateEmail}
                    helperText={emailError}
                    error={!!emailError}
                    variant="outlined"
                    sx={(theme) => ({mb: 2, backgroundColor: theme.colors.alpha.white[100]})}
                    id="sign-up-email"
                    label="Email"
                    {...bindEmail}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={(theme) => ({mb: 2, backgroundColor: theme.colors.alpha.white[100]})}
                    id="sign-up-phone"
                    label="Phone Number"
                    {...bindPhone}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    maxRows={2}
                    size="small"
                    variant="outlined"
                    sx={(theme) => ({backgroundColor: theme.colors.alpha.white[100]})}
                    id="sign-message"
                    label="Message"
                    {...bindMessage}
                  />
              <FormGroup sx={{mb: 0}}>
                <FormControlLabel control={
                  <Checkbox checked={createListingAlert}
                            name="Create Listing Alert"
                            onChange={() => setCreateListingAlert(!createListingAlert)}  />
                } label="Create a listing alert for similar homes" />
              </FormGroup>
              <Button type="submit" fullWidth variant="contained" disabled={loading}>Contact {agent.name}</Button>
              <Box sx={{textAlign: 'center', mt: 1}}>
                <Typography variant="disclaimer">
                  <SignupDisclaimerText agent={agent}/>
                </Typography>
              </Box>
            </form>
          </div>
        </>
      </div>
  )
}

export default SignUp;
