import { useContext, useEffect } from 'react'
import { Route, Link } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { Button, Typography, Link as MuiLink } from '@mui/material'
import { IoIosMail, IoIosCall, IoIosSearch, IoIosAlbums, IoIosThumbsUp, IoIosUnlock } from 'react-icons/io'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styles from './Sidebar.module.scss'
import { CurrentUserContext } from '../Contexts/CurrentUserContext'
import { reportError } from '../../Common/ErrorCapture'

function LargeMenuLink({ label, to, activeOnlyWhenExact, children, signedOut, showSignUp }) {
  const linkClass = 'sidenav-link py-1 px-3'
  const iconClass = 'mr-3'
  if (signedOut && showSignUp) {
    return (
      <li className="sidenav-item">
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            showSignUp()
          }}
          className={linkClass}
        >
          <span className={iconClass}>{children}</span>
          <strong>{label}</strong>
        </a>
      </li>
    )
  }

  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <li className={`sidenav-item ${match ? 'active' : ''}`}>
          <Link to={to} className={linkClass}>
            <span className={iconClass}>{children}</span>
            <strong>{label}</strong>
          </Link>
        </li>
      )}
    />
  )
}

function SmallMenuLink({ label, to, activeOnlyWhenExact }) {
  return (
    <Route
      path={to}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <li className={`sidenav-item ${match ? 'active' : ''}`}>
          <Link to={to} className="sidenav-link py-0 px-3">
            <span className="text-truncate">{label}</span>
          </Link>
        </li>
      )}
    />
  )
}

function SidenavHeader({ title }) {
  return <li className={`sidenav-header small font-weight-semibold pb-0 pt-1 pb-2 px-3 ${styles.header}`}>{title.toUpperCase()}</li>
}

const CLIENT_DATA_QUERY = gql`
  query savedSearches {
    current_user {
      id
      saved_searches(only_active: true) {
        total_count
        edges {
          node {
            id
            search_name
          }
        }
      }
      home_reports {
        total_count
        edges {
          node {
            id
            titleized_full_address
          }
        }
      }
      market_alerts {
        total_count
        edges {
          node {
            id
            market_name
            client_url
          }
        }
      }
    }
  }
`

const Sidebar = () => {
  const { agent, loggedIn, showSignUp, displayRules } = useContext(CurrentUserContext)

  useEffect(() => {
    const node = document.getElementById('main-sidenav')
    new SideNav(node)
  }, [])

  const { data, loading } = useQuery(CLIENT_DATA_QUERY, {
    onError: reportError,
    skip: !loggedIn,
  })

  const renderSearches = () => {
    if (data?.current_user?.saved_searches) {
      const saved_searches_edges = data.current_user.saved_searches
      if (saved_searches_edges.total_count === 0) {
        return (
          <li className={`sidenav-item`}>
            <div className="px-3">
              <small>
                <em>No Searches</em>
              </small>
            </div>
          </li>
        )
      }

      return (
        <>
          {saved_searches_edges.edges.map(edge => (
            <SmallMenuLink key={edge.node.id} to={`/searches/${edge.node.id}`} label={edge.node.search_name} />
          ))}
        </>
      )
    }

    return null
  }

  const renderHomes = () => {
    if (data?.current_user?.home_reports) {
      const home_reports_edges = data.current_user.home_reports
      if (home_reports_edges.total_count === 0) {
        return (
          <li className={`sidenav-item`}>
            <div className="px-3">
              <small>
                <em>No Homes</em>
              </small>
            </div>
          </li>
        )
      }

      return (
        <>
          {home_reports_edges.edges.map(edge => (
            <SmallMenuLink key={edge.node.id} to={`/home-reports/${edge.node.id}`} label={edge.node.titleized_full_address} />
          ))}
        </>
      )
    }

    return null
  }

  const renderMarketActivityAlerts = () => {
    if (data?.current_user?.market_alerts) {
      const marketAlertsEdges = data.current_user.market_alerts
      if (marketAlertsEdges.total_count === 0) {
        return null
      }

      return (
        <>
          <SidenavHeader title="My Market Activity" />
          {marketAlertsEdges.edges.map(edge => (
            <SmallMenuLink key={edge.node.id} to={`/market-activity-alerts/${edge.node.id}`} label={edge.node.market_name} />
          ))}
          <li className="sidenav-divider" />
        </>
      )
    }

    return null
  }

  const renderSignedInItems = () => (
    <>
      <SidenavHeader title="My Searches" />
      {renderSearches()}
      <li className="sidenav-divider" />
      {displayRules.has_home_report_access && (
        <>
          <SidenavHeader title="My Homes" />
          <SmallMenuLink to="/home-reports" label="+ Add Home" />
          {renderHomes()}
          <li className="sidenav-divider" />
        </>
      )}
      {renderMarketActivityAlerts()}
      <SidenavHeader title="More" />
      {!agent.use_legacy_messenger && <SmallMenuLink to="/conversations" label="Messages" />}
      <SmallMenuLink to="/uninterested" label="Not Interested" />
      <SmallMenuLink to="/viewed" label="Recently Viewed" />
      <SmallMenuLink to="/profile" label="My Profile" />
      <li className="sidenav-divider" />
      {/*<SmallMenuLink to="/settings" label="Settings"/>*/}
      <li className={`sidenav-item`}>
        <a href="/profile/my_account" target="_blank" className="sidenav-link py-0 px-3">
          <span>Settings</span>
        </a>
      </li>
      <li className={`sidenav-item`}>
        <a className="sidenav-link py-0 px-3" rel="nofollow" method="delete" href="/users/sign_out">
          Logout
        </a>
      </li>
    </>
  )

  const renderSignedOutCTA = () => (
    <li>
      <div className="text-center">
        <Typography variant="subtitle2" color="white" mb={1} px={0.5}>
          Sign up to start searching with your agent.
        </Typography>
        <Button color="primary" variant="contained" onClick={showSignUp}>
          Sign Up
        </Button>
        <br />
        <Typography variant="subtitle2" color="white" mt={1} px={0.5}>
          Have an account?{' '}
          <MuiLink underline="always" href="/users/sign_in" color="inherit">
            Sign In
          </MuiLink>
        </Typography>
      </div>
    </li>
  )

  return (
    <nav id="layout-sidenav" className={`layout-sidenav ${styles.sidenav}`} aria-label="Navigation Sidebar">
      <div id="main-sidenav" className={`sidenav sidenav-vertical bg-dark ${styles.vertical}`}>
        <PerfectScrollbar className="w-100">
          <ul className={`sidenav-inner ${styles.inner}`}>
            <li className="sidenav-block my-1 px-3">
              {agent.photo ? (
                <div className="media align-items-center">
                  <img src={agent.photo} className="d-block ui-w-50 rounded-circle" alt={`Headshot of ${agent.name}`} />
                  <div className="media-body pl-3">
                    <h5 className={`sidenav-text mb-0 ${styles['agent-name']}`}>{agent.name}</h5>
                  </div>
                </div>
              ) : (
                <div className="media align-items-center">
                  <div className="media-body my-1 text-center">
                    <h5 className={`sidenav-text mb-0`}>{agent.name}</h5>
                  </div>
                </div>
              )}
            </li>

            <LargeMenuLink to="/map" label="Search">
              <IoIosSearch className={styles['large-icon']} />
            </LargeMenuLink>
            {displayRules.client_exclusives_enabled && (
              <LargeMenuLink to="/exclusives" label="Exclusives">
                <IoIosUnlock className={styles['large-icon']} />
              </LargeMenuLink>
            )}
            <LargeMenuLink to="/matches" activeOnlyWhenExact label="My Matches" showSignUp={showSignUp} signedOut={!loggedIn}>
              <IoIosAlbums className={styles['large-icon']} />
            </LargeMenuLink>
            <LargeMenuLink to="/interested" label="Interested" showSignUp={showSignUp} signedOut={!loggedIn}>
              <IoIosThumbsUp className={styles['large-icon']} />
            </LargeMenuLink>

            <li className="sidenav-divider" />
            {loggedIn ? renderSignedInItems() : renderSignedOutCTA()}
          </ul>
        </PerfectScrollbar>
      </div>
      <div className={styles.footer}>
        <div className="sidenav bg-dark py-0">
          <ul className="sidenav-inner">
            <li className="sidenav-item pl-3" style={{ width: 200 }}>
              <div className="text-truncate">
                <IoIosMail className="text-white" />
                &nbsp;
                <a className="text-white" href={`mailto:${agent.email}`}>
                  {agent.email}
                </a>
              </div>
              <div className="text-truncate">
                <IoIosCall className="text-white" />
                &nbsp;
                <a className="text-white" href={`tel:${agent.phone_number}`}>
                  {agent.phone_number}
                </a>
              </div>
              {agent.secondary_phone_number && (
                <div className="text-truncate">
                  <IoIosCall className="text-white" />
                  &nbsp;
                  <a className="text-white" href={`tel:${agent.secondary_phone_number}`}>
                    {agent.secondary_phone_number}
                  </a>
                </div>
              )}
              <div>
                <div className="text-white">License #{agent.license}</div>
              </div>
              {agent.license_2 && (
                <div>
                  <br />
                  <div className="text-white">{agent.license_2}</div>
                </div>
              )}
              {agent.license_3 && (
                <div>
                  <br />
                  <div className="text-white">{agent.license_3}</div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Sidebar
