import { useContext } from 'react'
import Select from 'react-select-2'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { Typography, Stack, CircularProgress } from '@mui/material'
import { TabletAndDesktop } from '../../../Common/ResponsiveComponents'

const CountAndSortHeader = () => {
  const splitContext = useContext(SplitLayoutContext)

  const renderCountMessage = () => {
    if (splitContext.loading) {
      return 'Loading'
    } else if (splitContext.total_count) {
      return `Showing ${splitContext.current_count} of ${splitContext.total_count} listings`
    } else if (splitContext.current_count) {
      return `Showing ${splitContext.current_count} listings`
    } else {
      return 'No listings found'
    }
  }

  return (
    <div>
      <TabletAndDesktop>
        {matches => {
          if (matches) {
            return (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography variant="h6" pl={1}>
                    {splitContext.loading && <CircularProgress size={15} />} {renderCountMessage()}
                  </Typography>
                  <>
                    {splitContext.sortOptions && (
                      <div className="btn-group" style={{ width: '170px', margin: 3 }}>
                        <Select
                          options={splitContext.sortOptions}
                          value={splitContext.selectedSort}
                          placeholder={'Sort by'}
                          className="w-100 border-0 text-center"
                          onChange={splitContext.onSortChange}
                          classNamePrefix={'sortby'}
                          aria-label="Sort Listings By"
                        />
                      </div>
                    )}
                  </>
                </Stack>
              </>
            )
          }
        }}
      </TabletAndDesktop>
    </div>
  )
}

export default CountAndSortHeader
