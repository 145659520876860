import {useInput} from "../../../../CustomHooks/InputHook";
import SelectState from './../../../../Common/SelectState'
import {useState, useContext} from "react";
import {CurrentUserContext} from '../../../Contexts/CurrentUserContext'
import {gql, useMutation} from "@apollo/client";
import {
  Box,
  Button,
  Grid,
  FormControl,
  TextField,
  CircularProgress
} from "@mui/material";
import { useSnackbar } from 'notistack'
import ErrorBoundarySimple from "../../../../Common/ErrorBoundarySimple";
import EastIcon from '@mui/icons-material/East';
import RegisterUserAndCreateHomeReportModal from './RegisterUserAndCreateHomeReportModal';

const CREATE_HOME_REPORT_MUTATION = gql`
    mutation clientCreateHomeReport($input: CreateClientHomeReportMutationInput!) {
        create_client_home_report(input: $input){
            home_report{
                id
            }
            errors{
                key
                message
            }
        }
    }
`;

const CreateHomeReportForm = ({}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const {loggedIn} = useContext(CurrentUserContext)

  const {value: address, bind: bind_address, reset: resetAddress} = useInput('');
  const {value: city, bind: bind_city, reset: resetCity} = useInput('');
  const {value: postal_code, bind: bind_postal_code, reset: resetPostalCode} = useInput('');
  const [stateAbbrev, setStateAbbrev] = useState('')

  const [modalOpen, setModalOpen] = useState(false)
  const [errors, setErrors] = useState({})

  const handleCompleted = (data) => {
    const result = data.create_client_home_report;
    if (result?.errors?.length) {
      const errorMessages = result.errors.map((e) => `${e.key}: ${e.message}`).join(' ');
      enqueueSnackbar(`Errors: ${errorMessages}`, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }

    if (result.home_report) {
      const home_report = result.home_report;

      enqueueSnackbar(`Home Alert successfully created!!`, {
        variant: 'success',
        autoHideDuration: 3000
      })

      window.location.assign(`/homesearch/home-reports/${home_report.id}`);
    }
  }

  const [createHomeReport, {loading: createHomeReportLoading}] = useMutation(CREATE_HOME_REPORT_MUTATION, {
    onCompleted: handleCompleted
  })

  const validateInputs = (inputs) => {
    const errors = {}
    Object.keys(inputs).forEach((key) => {
      const value = inputs[key]
      if (key === 'postal_code' && value.length !== 5) {
        Object.assign(errors, { [key]: "Must be 5 digits" })
      }
      if (!value) {
        Object.assign(errors, { [key]: "Can\'t be blank" })
      }
    })

    setErrors(errors)

    return Object.keys(errors).length ? false : true
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let input = {
      address,
      city,
      state: stateAbbrev,
      postal_code,
    }

    const isValid = validateInputs(input)
    if (!isValid) return

    if (loggedIn) {
      createHomeReport({ variables: {input}})
    } else {
      setModalOpen(true)
    }
  }

  const handleCloseAndResetForm = () => {
    resetAddress()
    resetCity()
    resetPostalCode()
    setStateAbbrev('')
    setModalOpen(false)
  }

  return (
    <ErrorBoundarySimple>
      <Box
        component="form"
        onSubmit={handleSubmit}
      >
        <Grid container columnSpacing={2} rowSpacing={2} sx={{pt: 1}}>
          <Grid item xs={12}>
            <TextField
              id="street-address"
              label="Street"
              name="street-address"
              {...bind_address}
              fullWidth
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="city"
              label="City"
              name="city"
              {...bind_city}
              fullWidth
              error={!!errors.city}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <SelectState
                valueProp={stateAbbrev}
                setValueProp={setStateAbbrev}
                onChange={setStateAbbrev}
                error={errors.state}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="postal-code"
              label="Postal Code"
              name="postal-code"
              {...bind_postal_code}
              fullWidth
              error={!!errors.postal_code}
              helperText={errors.postal_code}
            />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Button
            variant="contained"
            type="submit"
            size='large'
            disabled={createHomeReportLoading}
            fullWidth
            endIcon={
              createHomeReportLoading ? <CircularProgress
                sx={(theme) => ({color: theme.colors.alpha.black[30]})}
                size={15}
              /> :
                <EastIcon />
            }
          >
            View Home Value
          </Button>
        </Box>
      </Box>
      <RegisterUserAndCreateHomeReportModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCloseAndResetForm={handleCloseAndResetForm}
        addressInputs={{
          address,
          state: stateAbbrev,
          city,
          postal_code,
        }}
      />
    </ErrorBoundarySimple>
  )
}

export default CreateHomeReportForm
