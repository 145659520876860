import { IconButton, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const ListingModalButtons = ({ closeModal }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    position="fixed"
    top="20px"
    right="5%"
  >
    <IconButton
      onClick={closeModal}
      variant="contained"
      sx={theme => ({
        height: '50px',
        width: '50px',
        borderRadius: '50%',
        backgroundColor: theme.colors.alpha.black[100],
        color: theme.colors.alpha.white[100],
        border: `1px solid ${theme.colors.alpha.white[100]}`,
        '&:hover': {
          backgroundColor: theme.colors.alpha.black[70],
        },
        '&:focus': {
          outline: 'none !important',
        },
      })}
    >
      <CloseIcon color="white" sx={{ fontSize: '20px' }} />
    </IconButton>
  </Box>
)

export default ListingModalButtons
