import { Component, createContext } from 'react';
let CollectionContext;
const {
  Provider,
  Consumer
} = (CollectionContext = createContext());
import { withRouter } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import {areCookiesEnabled} from './../../Utils/CookieUtils';

const MATCHED_COLLECTION_QUERY = gql`
                      query CollectionSearch($id: ID!) {
                        current_user {
                          id
                          searcher:matches_search {
                            listings {
                              id
                              slug
                              address
                              city
                              postal_code
                            }
                          }
                          listing:property_by_id(id: $id) {
                            id
                            address
                            slug
                            city
                            postal_code
                          }
                        }
                      }
                    `;

class CollectionProvider extends Component {
  state = {
    collection: [],
    currentListingIdentifier: null,
    currentListingIndex: null,
    previousListingIndex: null,
    nextListingIndex: null,
  };

  componentDidMount(){
    let location = this.props.location.pathname;
    if(location.includes('listings/matched/') && this.props.logged_in && areCookiesEnabled()){
      let propertyId = location.split('/').slice(-1)[0];

      this.props.client.query({
        query: MATCHED_COLLECTION_QUERY,
        variables: {id: propertyId},
      }).then(({data}) => {
        const currentListing = data.current_user.listing;
        const listings = data.current_user.searcher.listings.filter((listing) => {
          return listing.id !== currentListing.id
        });
        const fullListings = [currentListing, ...listings];

        if(fullListings.length > 1){
          this.setState({collection: fullListings})
          this.setCurrentListing(currentListing.id)
        }

      })
    }
  }

  updateCollection = (collection) =>{
    this.setState({collection})
  };

  setCurrentListing = (currentListingIdentifier) => {
    const currentListingIndex = this.state.collection.findIndex((listing) => {
      // currentListingIdentifier could be ID or slug
      return listing.id === currentListingIdentifier || listing.slug === currentListingIdentifier;
    });

    if (currentListingIndex !== -1) {
      const nextListingIndex = (currentListingIndex === (this.state.collection.length - 1)) ? null : currentListingIndex + 1;
      const previousListingIndex = (currentListingIndex === 0) ? null : currentListingIndex - 1;

      this.setState({ currentListingIdentifier, currentListingIndex, nextListingIndex, previousListingIndex })
    } else {
      this.setState({ currentListingIdentifier,
        currentListingIndex: null,
        nextListingIndex: null,
        previousListingIndex: null
      })
    }
  };

  render() {
    return (
        <Provider
            value={{
              collection: this.state.collection,
              updateCollection: this.updateCollection,
              setCurrentListing: this.setCurrentListing,
              currentListingIdentifier: this.state.currentListingIdentifier,
              currentListingIndex: this.state.currentListingIndex,
              currentListing: this.state.collection[this.state.currentListingIndex],
              previousListing: this.state.collection[this.state.previousListingIndex],
              nextListing: this.state.collection[this.state.nextListingIndex],
            }}
        >
          {this.props.children}
        </Provider>
    );
  }
}

class CollectionProviderWrapper extends Component {
  render(){
    return(
        <CollectionProvider {...this.props}>
          {this.props.children}
        </CollectionProvider>
    )
  }
}

const CollectionProviderWithRouter = withApollo(withRouter(CollectionProviderWrapper))

export {
  CollectionProvider,
  CollectionProviderWithRouter,
  Consumer as CollectionConsumer,
  CollectionContext
};
