import { Box, Link, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'

const OnboardingFooter = ({ rsLogoUrl }) => {
  const { agent } = useContext(CurrentUserContext)
  return (
    <Box
      sx={{
        backgroundColor: '#F2F5FA',
        width: '100%',
      }}
      textAlign="center"
    >
      <Stack direction="column" spacing={1} py={2} alignContent="center" justifyContent="center">
        {agent.enterprise_disclaimer && <Typography>{agent.enterprise_disclaimer}</Typography>}
        <Stack>
          {agent.enterprise_disclaimer_url && (
            <Link href={agent.enterprise_disclaimer_url} target="_blank" rel="noreferrer">
              {agent.name} & {agent.enterprise_name} Terms/Policies
            </Link>
          )}
          <Link href="/client-terms">Terms</Link>
          <Link href="/service-provider-privacy">Privacy Policy</Link>
          {agent.has_custom_disclaimer && (
            <Link href="/agent-disclaimer" target="_blank">
              Agent Disclaimer/Policies
            </Link>
          )}
        </Stack>
        <Typography>Powered By RealScout</Typography>
        <Box>
          <Box component="img" src={rsLogoUrl} width={200} />
        </Box>
      </Stack>
    </Box>
  )
}
export default OnboardingFooter
