import { Component } from 'react';
import styles from './SplitListingPhoto.module.scss'
import { priceWithFallback } from './../../../Utils/NumberUtils'

class SplitListingPhoto extends Component {
  renderContent(listing) {
    const openHouseCount = listing.open_houses ? listing.open_houses.total_count : 0
    return (
      <div>
        <div className={styles['open-house-container']}>
          <div className="row">
            <div className="col">
              {openHouseCount > 0 && (
                <small className="mb-1">
                  Upcoming Open {openHouseCount > 1 ? 'Houses' : 'House'}
                </small>
              )}
            </div>
            <div className="col-md-auto">
              {listing.display_rules.show_logo_on_results && (
                <img src={listing.mls_logo} alt="" style={{ width: 65 }} />
              )}
            </div>
          </div>
        </div>
        <div className={`pb-1 px-1 ${styles['price-container']}`}>
          <small>{listing.display_status}</small>
          <h5 className="mb-0">{priceWithFallback(listing.display_price)}</h5>
        </div>
      </div>
    )
  }

  render() {
    const listing = this.props.listing

    const photoUrl = listing.photos.edges[0].node.photo_url

    const baseClasses = `ui-bg-cover rounded-top ${styles['image-background']}`

    if (this.props.useLozad) {
      return (
        <div className={`${baseClasses} lozad`} data-background-image={photoUrl}>
          {this.renderContent(listing)}
        </div>
      )
    } else {
      return (
        <div
          className={baseClasses}
          style={{
            backgroundImage: `url(${photoUrl})`,
          }}
        >
          {this.renderContent(listing)}
        </div>
      )
    }
  }
}

export default SplitListingPhoto
