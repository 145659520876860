import LazyLoad from 'react-lazyload'
import { FeatureGroup, LayersControl, Map, Marker, TileLayer } from 'react-leaflet'
import { divIcon } from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import { IoIosHome } from 'react-icons/io'
import { useRef, useState, useCallback } from 'react'
import { Card } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MAP_ATTRIBUTION, SATELLITE_TILE_URL, TILE_URL } from '../../../../Utils/MapUtils'
import { shortPrice } from '../../../../Utils/NumberUtils'

const AreaReportMap = ({ latitude, longitude, listingsToDisplay, selectedListingId, setSelectedListingId, previewMode }) => {
  const map = useRef(null)

  const listingPinsRef = useCallback(node => {
    // Fits listing pins in viewport
    if (node !== null) {
      const leafletMap = map.current.leafletElement
      const group = node.leafletElement
      if (listingsToDisplay.length === 1) {
        leafletMap.setView([listingsToDisplay[0].latitude, listingsToDisplay[0].longitude], 15)
      } else if (listingsToDisplay.length > 1) {
        leafletMap.fitBounds(group.getBounds())
      }
    }
  }, [])

  const position = [latitude, longitude]

  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const [clickedPinListingId, setClickedPinListingId] = useState(null)

  const homeIcon = divIcon({
    type: 'div',
    className: `home-report-my-home-marker`,
    popupAnchor: [2, -25],
    iconSize: [20, 20],
    iconAnchor: [21, 21],
    html: ReactDOMServer.renderToString(<IoIosHome style={{ height: '20px', width: '20px' }} />),
  })

  const makeMarkers = listings =>
    listings.map(listing => (
      <Marker
        key={listing.id}
        position={[listing.latitude, listing.longitude]}
        zIndexOffset={selectedListingId === listing.id ? 1000 : 0}
        icon={divIcon({
          type: 'div',
          className: `home-report-listing-marker ${selectedListingId === listing.id ? 'selected-home-report-listing-marker' : ''}`,
          popupAnchor: [2, -25],
          iconSize: [64, 20],
          iconAnchor: [32, 20],
          html: `<i style="font-weight: bold">${shortPrice(listing.display_price, 0)}</i>`,
        })}
        onClick={() => {
          setClickedPinListingId(listing.id)
          setSelectedListingId(listing.id)
        }}
        onMouseOver={() => {
          setSelectedListingId(listing.id)
        }}
        onMouseOut={() => {
          if (clickedPinListingId !== listing.id) {
            setSelectedListingId(null)
          }
        }}
      />
    ))

  return (
    <Card variant="outlined">
      <LazyLoad height={isSmallerThanMd || previewMode ? 350 : 450}>
        <Map ref={map} center={position} zoom={15} style={isSmallerThanMd || previewMode ? { height: 350 } : { height: 450 }} scrollWheelZoom={false}>
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Streets" checked>
              <TileLayer attribution={MAP_ATTRIBUTION} url={TILE_URL} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer attribution={MAP_ATTRIBUTION} url={SATELLITE_TILE_URL} />
            </LayersControl.BaseLayer>
          </LayersControl>
          <FeatureGroup ref={listingPinsRef}>
            <Marker position={[latitude, longitude]} icon={homeIcon} />
            {makeMarkers(listingsToDisplay)}
          </FeatureGroup>
        </Map>
      </LazyLoad>
    </Card>
  )
}

export default AreaReportMap
