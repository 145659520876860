import { Skeleton, Stack, Card, CardContent } from '@mui/material'
import React from 'react'

const SkeletonCard = () => {
  return (
    <Card variant="outlined" sx={{ height: 313 }}>
      <Skeleton animation="wave" variant="rectangular" height={200} />
      <CardContent sx={{ paddingTop: '0.5rem' }}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} mb={1} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '0.5rem' }} mb={1} />
        <Stack direction="row" spacing={2} justifyContent="center" mt={1}>
          <Skeleton animation="wave" variant="rectangular" width={90} height={30} m={1} />
          <Skeleton animation="wave" variant="rectangular" width={50} height={30} m={1} />
        </Stack>
      </CardContent>
    </Card>
  )
}

export default SkeletonCard
