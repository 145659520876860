// Note, this is a shared form by multiple components on the Client UX
import { useInput } from '@/components/CustomHooks/InputHook'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { gql, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import validationUtils from '@/components/Utils/ValidationUtils'
import { Box, Button, Grid, TextField } from '@mui/material'

const HOMEBUYER_SIGN_UP_MUTATION = gql`
  mutation homebuyer_sign_up($input: HomebuyerSignUpInput!) {
    homebuyer_sign_up(input: $input) {
      user {
        id
      }
      errors {
        key
        message
      }
      auth_token
    }
  }
`

const SignUpForm = ({ redirectPath }) => {
  const { value: email, bind: bindEmail } = useInput('')
  const { value: firstName, bind: bindFirstName } = useInput('')
  const { value: lastName, bind: bindLastName } = useInput('')
  const { value: phone, bind: bindPhone } = useInput('')
  const { value: password, bind: bindPassword } = useInput('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [homebuyerSignUp, { loading }] = useMutation(HOMEBUYER_SIGN_UP_MUTATION, {
    onError: () => toast.error('Error, please try again'),
    onCompleted: ({ homebuyer_sign_up }) => {
      if (homebuyer_sign_up.auth_token) {
        window.location.assign(`${redirectPath}?auth_token=${homebuyer_sign_up.auth_token}`)
      } else if (homebuyer_sign_up.errors) {
        const errorMessage = homebuyer_sign_up.errors
          .map(error => `${error.key} ${error.message}`)
          .join(', ')
        enqueueSnackbar(`Error: ${errorMessage}`, {
          variant: 'error',
          autoHideDuration: 3000,
        })
      }
    },
  })

  const validateEmail = () => {
    const emailValidation = validationUtils.email(email)
    if (emailValidation.hasError) {
      setEmailError(emailValidation.reason)
    } else {
      setEmailError(null)
    }
  }
  const validatePassword = () => {
    const passwordValidation = validationUtils.password(password)
    if (passwordValidation.hasError) {
      setPasswordError(passwordValidation.reason)
    } else {
      setPasswordError(null)
    }
  }

  const submitSignUp = e => {
    e.preventDefault()
    const passwordValidation = validationUtils.password(password)
    const emailValidation = validationUtils.email(email)
    if (emailValidation.hasError || passwordValidation.hasError) {
      validateEmail()
      validatePassword()
    } else {
      homebuyerSignUp({
        variables: {
          input: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            primary_mobile_phone: phone,
            password: password,
          },
        },
      })
    }
  }

  return (
    <Box>
      <form noValidate onSubmit={submitSignUp}>
        <Grid container columnSpacing={1} rowSpacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
              id="sign-up-first-name"
              label="First Name"
              {...bindFirstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
              id="sign-up-last-name"
              label="Last Name"
              {...bindLastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              onBlur={validateEmail}
              helperText={emailError}
              error={!!emailError}
              variant="outlined"
              sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
              id="sign-up-email"
              label="Email"
              {...bindEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
              id="sign-up-phone"
              label="Phone Number"
              {...bindPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              helperText={passwordError}
              error={!!passwordError}
              onBlur={validatePassword}
              sx={theme => ({ backgroundColor: theme.colors.alpha.white[100] })}
              id="sign-up-password"
              label="Password"
              type="password"
              {...bindPassword}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button type="submit" fullWidth variant="contained" disabled={loading} sx={{ mt: 1 }}>
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default SignUpForm
