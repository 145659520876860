import { RealScoutTheme } from '../Theme'
import { Avatar, Box, CardContent, Container, Stack, Typography, Button, ThemeProvider } from '@mui/material'
import RsCard from '../Common/UIComponents/Card/RsCard'

const AppGettingStarted = ({ agent, images }) => {
  return (
    <ThemeProvider theme={RealScoutTheme}>
      <div>
        <Container maxWidth="sm" sx={{my: 2}}>
          <Stack spacing={2}>
            <RsCard>
              <CardContent>
                <Box textAlign="center" pt={2}>
                  <Typography variant="h2" mb={1}>
                    Search Listings, View Home Value, and More
                  </Typography>
                  <Typography variant="h6">
                    Stay up to date on your real estate journey with {agent.name} wherever you are.
                  </Typography>
                </Box>
              </CardContent>
              <Box textAlign="center">
                <img src={images.phone_image} style={{ maxWidth: 400, width: '100%' }} />
              </Box>
              <Box px={3} py={2}>
                <Box py={1}>
                  <Typography variant="h4">Step 1: Download App</Typography>
                </Box>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <a href="https://apps.apple.com/app/apple-store/id1281304485?pt=10633084&ct=get-app-page&mt=8">
                    <img src={images.app_store_logo} alt="App Store Logo" style={{ width: 150 }} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=org.realscout.RSHomebuyerApp&utm_source=web&utm_campaign=get-app-page">
                    <img
                      src={images.google_play_logo}
                      alt="Google Play Logo"
                      style={{ width: 165 }}
                    />
                  </a>
                </Stack>

                <Box mb={1} mt={3}>
                  <Typography variant="h4">Step 2: Register Account</Typography>
                  <Typography variant="body1" mt={0.5}>
                    <strong>Note:</strong> This link only works once the app is installed
                  </Typography>
                  <Box textAlign="center" mt={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      href={`https://www.realscout.com/invite?agent=${agent.subdomain}&source=Get_App_Page_Link`}
                    >
                      Launch App & Register
                    </Button>
                  </Box>
                </Box>
              </Box>
            </RsCard>

            <RsCard title={`Questions? Contact ${agent.name}`}>
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Box>
                    <Avatar src={agent.photo} sx={{ width: 80, height: 80 }} />
                  </Box>
                  <Box>
                    <Typography variant="h3">{agent.name}</Typography>
                    <Typography variant="body1">{agent.email}</Typography>
                    <Typography variant="body1">{agent.phone_number}</Typography>
                  </Box>
                </Stack>
              </CardContent>
            </RsCard>
          </Stack>
        </Container>
      </div>
    </ThemeProvider>
  )
}

export default AppGettingStarted
