import { CardContent, Box, Typography, Stack, Grid, Link } from '@mui/material'
import { gql } from '@apollo/client'
import RsCard from '~/components/Common/UIComponents/Card/RsCard'

export const LISTING_FULL_DETAILS_FRAGMENT = gql`
  fragment listingFullDetailsFragment on Property {
    id
    details
  }
`

const FullDetailsItem = ({ fullDetailsItem }) => {
  const fullDetailsItemArray = fullDetailsItem.split(/: (.+)/) // Splits on first ": "
  const label = fullDetailsItemArray[0]
  const value = fullDetailsItemArray[1]
  const isLink = value ? value.startsWith('http') : false
  return (
    <Stack direction="row" spacing={1}>
      <Typography color="text.secondary">{label}:</Typography>

      {isLink ? (
        <Link href={value} target="_blank" rel="noreferrer">
          Visit Link (External)
        </Link>
      ) : (
        <Typography>{value}</Typography>
      )}
    </Stack>
  )
}

const FullDetails = ({ fullDetailsProp }) => {
  const fullDetails = JSON.parse(fullDetailsProp)
  return (
    <Stack direction="column" spacing={3}>
      {Object.keys(fullDetails).map(fullDetailsCategory => (
        <Box key={fullDetailsCategory}>
          <RsCard title={fullDetailsCategory} cardProps={{ sx: { boxShadow: 'none ' } }}>
            <CardContent>
              <Stack direction="column" spacing={2}>
                {Object.keys(fullDetails[fullDetailsCategory]).map(fullDetailsSubcategory => (
                  <Box key={fullDetailsSubcategory}>
                    <Typography fontWeight="bold" pb={0.75}>
                      {fullDetailsSubcategory}
                    </Typography>

                    <Grid container spacing={0.5}>
                      {fullDetails[fullDetailsCategory][fullDetailsSubcategory].map(
                        fullDetailsItem => (
                          <Grid item xs={12} sm={12} md={6} key={fullDetailsItem}>
                            <FullDetailsItem fullDetailsItem={fullDetailsItem} />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                ))}
              </Stack>
            </CardContent>
          </RsCard>
        </Box>
      ))}
    </Stack>
  )
}

export default FullDetails
