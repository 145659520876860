import { gql, useMutation } from '@apollo/client'
import { ButtonGroup, Button, Box } from '@mui/material'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { DISLIKE, LIKE, NEUTRAL } from '@/components/Common/Constants/FeaturePreferenceConstants'

const SET_LISTING_FEEDBACK_MUTATION = gql`
  mutation set_listing_feedback($input: SetListingFeedbackInput!) {
    set_listing_feedback(input: $input) {
      user_interactions {
        id
        feedback {
          id
          category
          sentiment
        }
      }
      errors {
        key
        message
      }
    }
  }
`

const LikeDislikeButtons = ({ listingId, category, isLiked, isDisliked }) => {
  const [setFeaturePreferenceSentiment, { data, loading }] = useMutation(
    SET_LISTING_FEEDBACK_MUTATION
  )

  const handleLikeClick = () => {
    if (loading) return
    setFeaturePreferenceSentiment({
      variables: {
        input: {
          property_id: listingId,
          category,
          sentiment: isLiked ? NEUTRAL : LIKE,
        },
      },
    })
  }

  const handleDislikeClick = () => {
    if (loading) return
    setFeaturePreferenceSentiment({
      variables: {
        input: {
          property_id: listingId,
          category,
          sentiment: isDisliked ? NEUTRAL : DISLIKE,
        },
      },
    })
  }

  return (
    <Box>
      <ButtonGroup variant="outlined" aria-label="Basic button group">
        <Button
          variant={isLiked ? 'contained' : 'outlined'}
          size="small"
          disabled={loading}
          color={isLiked ? 'success' : 'gray'}
          onClick={handleLikeClick}
          sx={{ px: 1, py: 0.75 }}
          data-testid={`like-button-${category.split(' ').join('-')}-${isLiked ? LIKE : NEUTRAL}`}
        >
          <ThumbUpIcon sx={{ fontSize: '16px' }} />
        </Button>
        <Button
          variant={isDisliked ? 'contained' : 'outlined'}
          size="small"
          disabled={loading}
          color={isDisliked ? 'error' : 'gray'}
          onClick={handleDislikeClick}
          sx={{ px: 1, py: 0.75 }}
          data-testid={`dislike-button-${category.split(' ').join('-')}-${
            isDisliked ? DISLIKE : NEUTRAL
          }`}
        >
          <ThumbDownIcon sx={{ fontSize: '16px' }} />
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default LikeDislikeButtons
