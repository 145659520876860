import { gql, useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { Grid, Typography, Box, Card, CardContent, Button } from '@mui/material'
import Sticky from 'react-stickynode'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ErrorBoundary from '../../../Common/ErrorBoundary'
import PageHeader from '../../../Common/PageHeader'
import HomeReportHeader from './HomeReportHeader'
import HomeValuation, { CORE_VALUATION_FIELDS } from './HomeValuation'
import AreaReport, { AREA_REPORT_FIELDS } from './AreaReport/AreaReport'
import RequestCmaCta from './RequestCmaCta'
import ClientPriceChart from '../../../Common/ClientPriceChart'
import AgentChatWidget, { AGENT_MESSAGE_WIDGET_FIELDS } from './AgentChatWidget'
import HomeReportFooter from './HomeReportFooter'
import { shortPrice } from '../../../Utils/NumberUtils'
import RequestCashOffer from './RequestCashOffer'
import RsCardFooterAction from '../../../Common/UIComponents/Card/RsCardFooterAction';

export const AGENT_FRAGMENT_FIELDS = gql`
  fragment AgentFields on Agent {
    id
    first_name
    last_name
    photo_url
    email
    phone_number
    broker_logo_url
    company_name
    has_hva_cash_offer_access
  }
`

export const HOME_REPORT_FULL_FRAGMENT = gql`
  ${CORE_VALUATION_FIELDS}
  ${AREA_REPORT_FIELDS}
  ${AGENT_MESSAGE_WIDGET_FIELDS.homeReportFields}
  fragment FullReportFragment on HomeReport {
    id
    titleized_full_address
    address
    refresh_available
    user {
      first_name
    }
    listing_report {
      test_price_metrics {
        count
        test_price
      }
    }
    ...CoreValuationFields
    ...AreaReportFields
    ...HomeReportFields
  }
`

export const REFRESH_HOME_REPORT = gql`
  ${HOME_REPORT_FULL_FRAGMENT}
  mutation refreshHomeReport($data: RefreshHomeReportInput!) {
    refresh_home_report(input: $data) {
      home_report {
        ...FullReportFragment
      }
    }
  }
`

const HomeReport = ({ homeReport, agent, previewMode }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const [refreshHomeReport, { loading: refreshingHomeReportValuations }] = useMutation(
    REFRESH_HOME_REPORT,
    {
      variables: {
        data: {
          home_report_id: homeReport.id,
        },
      },
    }
  )

  useEffect(() => {
    if (homeReport.refresh_available) {
      refreshHomeReport()
    }
  }, [homeReport.id])

  const agentChatProps = {
    agent,
    agentMessage: homeReport.agent_message,
    homeReportId: homeReport.id,
    previewMode,
  }

  const priceChartHasMetrics =
    homeReport.listing_report.test_price_metrics.filter(test => test.count !== 0).length > 0
  const showZestimateFooter = () =>
    homeReport.zestimate_property_url &&
    (homeReport.show_zestimate || homeReport.show_zestimate_range)

  const metricsForRecentlyListed = [
    {
      key: 'recently-listed',
      title: 'Listings',
      value: homeReport.recently_updated_listings.length,
    },
    {
      key: 'avg-list-price',
      title: 'Avg List Price',
      value: shortPrice(homeReport.avg_list_price),
    },
    {
      key: 'avg-list-price-per-sqft',
      title: 'Avg List Price / Sqft',
      value: `$${homeReport.avg_listing_price_per_sqft}`,
    },
  ]

  const metricsForRecentlySold = [
    {
      key: 'recently-sold',
      title: 'Listings',
      value: homeReport.recently_sold_listings.length,
    },
    {
      key: 'avg-sold-price',
      title: 'Avg Sold Price',
      value: shortPrice(homeReport.avg_sold_price),
    },
    {
      key: 'avg-sold-price-per-sqft',
      title: 'Avg Sold Price / Sqft',
      value: `$${homeReport.avg_sold_price_per_sqft}`,
    },
  ]

  return (
    <ErrorBoundary>
      <PageHeader>
        <HomeReportHeader
          address={homeReport.titleized_full_address}
          firstName={homeReport.user.first_name}
        />
      </PageHeader>

      <Grid container spacing={2} sx={isSmallerThanMd ? { pb: 3 } : { px: 3, pb: 3 }}>
        <Grid item container xs={12} md={8} rowSpacing={2}>
          <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
            <HomeValuation
              agent={agent}
              agentValuation={homeReport.agent_valuation}
              agentValuationUpdatedAt={homeReport.agent_valuation_updated_at}
              agentValueLow={homeReport.agent_value_low}
              agentValueHigh={homeReport.agent_value_high}
              valuation={homeReport.valuation}
              valueLow={homeReport.value_low}
              valueHigh={homeReport.value_high}
              percentValuationChange={homeReport.percent_valuation_change}
              valuationChangeDate={homeReport.previous_valuation_updated_at}
              valuationSource={homeReport.valuation_source}
              valuationSourceLogo={homeReport.valuation_source_logo}
              showPrimaryValuation={homeReport.show_primary_valuation}
              showPrimaryValuationRange={homeReport.show_primary_valuation_range}
              homeReportId={homeReport.id}
              address={homeReport.address}
              refreshingValuations={refreshingHomeReportValuations}
              previewMode={previewMode}
              zestimateValuation={homeReport.zestimate_valuation}
              zestimateValueHigh={homeReport.zestimate_value_high}
              zestimateValueLow={homeReport.zestimate_value_low}
              showZestimate={homeReport.show_zestimate}
              showZestimateRange={homeReport.show_zestimate_range}
              valuationDifferenceDisclaimer={homeReport.valuation_difference_disclaimer}
              valuationDisclaimer={homeReport.valuation_disclaimer}
            />
          </Grid>
          {isSmallerThanMd && (
            <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>

              <AgentChatWidget {...agentChatProps} />
                {agent.has_hva_cash_offer_access && (
                <Box mt={2}>
                  <RequestCashOffer
                    agentId={agent.id}
                    homeReportId={homeReport.id}
                    homeReportAddress={homeReport.address}
                    previewMode={previewMode}
                  />
                </Box>
              )}

            </Grid>
          )}

          {priceChartHasMetrics && (
            <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
              <ClientPriceChart
                testPriceMetrics={homeReport.listing_report.test_price_metrics}
                selectedPrice={homeReport.valuation}
                title="Buyer Audience by Price Range"
                subtitle="See how changing the listing price affects the audience of matching buyers searching on RealScout"
              />
            </Grid>
          )}
          <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
            <AreaReport
              title="Recently Listed"
              testId="recently-listed"
              metrics={metricsForRecentlyListed}
              latitude={homeReport.latitude}
              longitude={homeReport.longitude}
              listings={homeReport.recently_updated_listings}
              previewMode={previewMode}
              comparableListingsDisclaimer={homeReport.comparable_listings_disclaimer}
              loading={refreshingHomeReportValuations}
            />
          </Grid>
          <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
            <AreaReport
              title="Recently Sold"
              testId="recently-sold"
              metrics={metricsForRecentlySold}
              latitude={homeReport.latitude}
              longitude={homeReport.longitude}
              listings={homeReport.recently_sold_listings}
              previewMode={previewMode}
              comparableListingsDisclaimer={homeReport.comparable_listings_disclaimer}
              loading={refreshingHomeReportValuations}
            />
          </Grid>
          <Grid item xs={12} sx={isSmallerThanMd ? { px: 3 } : {}}>
            <RequestCmaCta
              previewMode={previewMode}
              homeReportId={homeReport.id}
              address={homeReport.address}
              agentId={agent.id}
              showPrimaryValuation={homeReport.show_primary_valuation}
              showPrimaryValuationRange={homeReport.show_primary_valuation_range}
              showZestimate={homeReport.show_zestimate}
              showZestimateRange={homeReport.show_zestimate_range}
              valuation={homeReport.valuation}
              zestimateValuation={homeReport.zestimate_valuation}
            />
          </Grid>
        </Grid>
        {!isSmallerThanMd && (
          <Grid item xs={12} md={4}>
            <Sticky top={20}>
              <AgentChatWidget {...agentChatProps} />
              {agent.has_hva_cash_offer_access && (
                <Box mt={2}>
                  <RequestCashOffer
                    agentId={agent.id}
                    homeReportId={homeReport.id}
                    homeReportAddress={homeReport.address}
                    previewMode={previewMode}
                  />
                </Box>
              )}
            </Sticky>
          </Grid>
        )}
      </Grid>
      {showZestimateFooter() && (
        <HomeReportFooter>
          <Typography variant="subtitle2" fontSize={12} textAlign="center">
            *See more details for{' '}
            <a href={homeReport.zestimate_property_url} target="_blank" rel="noreferrer">
              {homeReport.address}
            </a>{' '}
            on Zillow. Data is provided "as is" via the Zestimate API.
            <br />© Zillow, Inc., 2006-{new Date().getFullYear()}. Use is subject to the Zillow
            Terms of Use.
          </Typography>
        </HomeReportFooter>
      )}
    </ErrorBoundary>
  )
}

export default HomeReport
