import { Typography, Stack, Button, Box, Card } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { gql, useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'

const REQUEST_VALUATION_ESTIMATE = gql`
  mutation requestValuationEstimate($input: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $input) {
      errors {
        key
        message
      }
      conversation {
        id
      }
    }
  }
`

const RequestCmaCta = ({ previewMode, homeReportId, address, agentId, valuation, showPrimaryValuation, showPrimaryValuationRange, showZestimate, showZestimateRange, zestimateValuation }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const onCompleted = data => {
    const result = data.create_conversation_message
    if (result?.errors?.length) {
      const errorMessages = result.errors.map(e => `${e.key}: ${e.message}`).join(' ')
      enqueueSnackbar(`Errors: ${errorMessages}`, {
        variant: 'error',
        autoHideDuration: 3000,
      })
    }

    if (result.conversation) {
      enqueueSnackbar('Your message has been sent', {
        variant: 'success',
        autoHideDuration: 3000,
        action: (
          <Button href={`/homesearch/conversations/${result.conversation.id}`} style={{ color: 'white' }}>
            View Message
          </Button>
        ),
      })
    }
  }

  const [requestCma, { loading }] = useMutation(REQUEST_VALUATION_ESTIMATE, {
    variables: {
      input: {
        attachment_ids: [homeReportId],
        body: `Comparative Market Analysis request for ${address}`,
        recipient: agentId,
      },
    },
    onCompleted: data => onCompleted(data),
  })

  const hasPrimaryValuation = valuation && (showPrimaryValuation || showPrimaryValuationRange)
  const hasZestimateValuation = zestimateValuation && (showZestimate || showZestimateRange)
  if (!hasPrimaryValuation && !hasZestimateValuation) {
    return null
  }

  return (
    <Card variant="outlined" sx={previewMode ? { py: 3, px: 3 } : { py: 3, px: 6 }}>
      <Stack direction={isSmallerThanMd ? 'column' : 'row'} justifyContent="space-between" alignItems="center" spacing={3}>
        <Box textAlign="center">
          <Typography variant={isSmallerThanMd || previewMode ? 'h3' : 'h2'}>Want more details about sales in your area?</Typography>
          <Typography variant="h6" pt={1} sx={isSmallerThanMd ? { py: 2 } : { pt: 1 }} color="text.secondary">
            Get a detailed report on home sales in your area
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          fullWidth={isSmallerThanMd}
          sx={{
            whiteSpace: 'nowrap',
          }}
          onClick={requestCma}
          disabled={loading}
        >
          Request Details
        </Button>
      </Stack>
    </Card>
  )
}

export default RequestCmaCta
