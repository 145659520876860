import { Box, Card, Typography, Skeleton } from '@mui/material'

const MetricWidget = ({ title, value, loading }) => (
  <Card sx={{ boxShadow: 'none' }}>
    <Box display="flex" justifyContent="center">
      <Box py={1.5} px={2} display="flex" flexDirection="column" alignItems="center">
        <Box>{loading ? <Skeleton height={36} /> : <Typography variant="h3">{value.toLocaleString()}</Typography>}</Box>
        <Typography fontWeight="bold" color="text.secondary" textAlign="center">
          {title}
        </Typography>
      </Box>
    </Box>
  </Card>
)

export default MetricWidget
