import {Box, useTheme } from '@mui/material';

const HomeReportFooter = ({children}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems={{xs: 'stretch', md: 'center'}}
      flexDirection={{xs: 'column', md: 'row'}}
      justifyContent="center"
      sx={{
        paddingX: theme.spacing(4),
        paddingY: theme.spacing(2),
        background: theme.colors.alpha.white[50],
        marginTop: `${theme.spacing(2)}`,
        boxShadow: `${theme.colors.alpha.black[100]} 0px -2px 6px -6px`
      }}
    >
      {children}
    </Box>
  );
}

export default HomeReportFooter;
