import { Link } from '@mui/material'

const SignupDisclaimerText = ({ agent }) => {
  return (
    <>
      By signing up/sending a message, you're creating a RealScout account and agreeing to the <Link href="/client-terms">Terms</Link> and <Link href="/service-provider-privacy">Privacy Policy</Link>
      {agent.enterprise_disclaimer_url && (
        <>
          {' '}
          and the{' '}
          <Link href={agent.enterprise_disclaimer_url} target="_blank">
            {agent.name} & {agent.enterprise_name} Terms/Policies
          </Link>
        </>
      )}
      .
      {agent.display_tcr_disclaimer && (
        <> You agree to be contacted by {agent.office_name} via call, email, and text. To opt-out, you can reply 'stop' at any time in text messages or click the unsubscribe link in the emails. Message and data rates may apply.</>
      )}
    </>
  )
}

export default SignupDisclaimerText
