import { Button, CardContent, Typography, Box, Divider, Stack } from '@mui/material'
import RsCard from '../../Common/UIComponents/Card/RsCard'
import {useSnackbar} from "notistack";
import {useState} from "react";

const AccountSettings = ({ userId }) => {
    const [resetButtonClicked, setResetButtonClicked] = useState(false);
    const { enqueueSnackbar } = useSnackbar()
    let csrfToken = document.getElementsByName('csrf-token')[0]?.content

    const resetPassword = () => {
        let csrfToken = document.getElementsByName('csrf-token')[0]?.content
        fetch("/users/send_password_reset", {
            method: "POST",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ })
        }).then(response => {
            if (!response.ok) { throw response; }
            enqueueSnackbar('Password Reset email sent. Please check your email.', {
                variant: 'success',
                autoHideDuration: 3000,
            })
            setResetButtonClicked(false)
        }).catch(error => {
            enqueueSnackbar('Error: Unable send password reset email.', {
                variant: 'error',
                autoHideDuration: 3000,
            })
            setResetButtonClicked(false)
        })
    }

    return (
        <RsCard title="Account Settings">
            <Stack spacing={2} divider={<Divider />}>
                <Stack direction={{xs: 'column', md: 'row'}} padding={2} justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h6">Reset Password</Typography>
                        <Typography variant="body1">
                            This will send an email so you can reset your password.
                        </Typography>
                    </Box>
                    <Box>
                        <Button variant="contained" disabled={resetButtonClicked} onClick={() => resetPassword()}>Reset Password</Button>
                    </Box>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} padding={2} justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography variant="h6">Opt-Out</Typography>
                        <Typography variant="body1">This will disable your account and stop all email alerts.</Typography>
                    </Box>
                    <Box>
                        <form action={`/users/${userId}`} method="post">
                            <input type="hidden" name="_method" value="delete" />
                            <input type="hidden" name="authenticity_token" value={csrfToken} />

                            <Box pt={2} textAlign="right">
                                <Button variant="contained" color="error" type="submit">
                                    Opt-Out & Disable Account
                                </Button>
                            </Box>
                        </form>
                    </Box>
                </Stack>
            </Stack>
        </RsCard>
    )
}

export default AccountSettings;
