import { withRouter } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import React from 'react'
import AttachmentsSidebar from '../../../Common/Conversations/AttachmentsSidebar'
import HomebuyerAttachedListing from './HomebuyerAttachedListing'
import { reportError } from '../../../Common/ErrorCapture'
import LoadingIcon from '../../../Legacy/RsLoadingCircle'

const CONVERSATION_DETAILS_SIDEBAR_QUERY = gql`
  query ConversationAttachmentsSidebarQuery($messageId: ID!) {
    current_user {
      id
      conversation {
        id
        message_by_id(id: $messageId) {
          id
          body
          created_at
          sender: sender_participant {
            id: participant_id
            full_name
          }
          attachments {
            total_count
            edges {
              node {
                ... on Property {
                  id
                  baths_total
                  beds_total
                  structure_sqft
                  address
                  city
                  state
                  postal_code
                  display_price
                  slug
                  photos(first: 1) {
                    edges {
                      node {
                        id
                        photo_url
                      }
                    }
                  }
                }
                ... on PremarketListing {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`

const ConversationAttachmentsSidebar = ({ match }) => {
  const { messageId } = match.params

  const { loading, data, refetch } = useQuery(CONVERSATION_DETAILS_SIDEBAR_QUERY, {
    onError: reportError,
    variables: { messageId },
  })

  if (loading) {
    return (
      <div className="d-flex flex-column">
        <LoadingIcon />
      </div>
    )
  }
  if (data && data.current_user) {
    const message = data.current_user.conversation.message_by_id
    console.log(message)
    const attachments = message.attachments.edges
    const isMessageSentByCurrent = data.current_user.id === message.sender.id

    return (
      <AttachmentsSidebar
        attachments={attachments}
        message={message}
        isMessageSentByCurrent={isMessageSentByCurrent}
        renderAttachment={attachment => (
          <HomebuyerAttachedListing
            attachment={attachment}
            isMessageSentByCurrent={isMessageSentByCurrent}
          />
        )}
      />
    )
  }
  return null
}

const ConversationAttachmentsSidebarWithRouter = withRouter(ConversationAttachmentsSidebar)
export default ConversationAttachmentsSidebarWithRouter
