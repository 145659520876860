import { Typography, Stack, Card, Grid, Box } from '@mui/material'
import TimeFormatter from '../../../../Utils/TimeUtils'

const OpenHouseCards = ({ openHouses }) => (
  <Box>
    <Grid container spacing={1}>
      {openHouses.map(openHouse => (
        <Grid item xs={12} sm={6} md={6} lg={3} key={openHouse.id}>
          <Card variant="outlined" sx={{ boxShadow: 'none' }}>
            <Stack
              direction="column"
              spacing={0.5}
              key={openHouse.id}
              p={2}
              mr={1}
              mb={1}
              justifyContent="center"
              textAlign="center"
            >
              <Typography fontWeight="bold">
                {TimeFormatter.formatUnixWithOffset(
                  openHouse.start_date_time,
                  'day',
                  openHouse.timezone_offset
                )}
              </Typography>
              <Typography>
                {TimeFormatter.formatUnixWithOffset(
                  openHouse.start_date_time,
                  'monthDay',
                  openHouse.timezone_offset
                )}
              </Typography>
              <Typography color="text.secondary">
                {TimeFormatter.formatUnixWithOffset(
                  openHouse.start_date_time,
                  'time',
                  openHouse.timezone_offset
                )}{' '}
                -{' '}
                {TimeFormatter.formatUnixWithOffset(
                  openHouse.end_date_time,
                  'time',
                  openHouse.timezone_offset
                )}
              </Typography>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
)

export default OpenHouseCards
