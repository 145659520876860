import {
  Typography,
  Stack,
  Box,
  Avatar,
  Skeleton, useTheme,
} from "@mui/material"

import {gql, useQuery} from "@apollo/client"
import useMediaQuery from "@mui/material/useMediaQuery";

const AGENT_CONTACT_INFO_QUERY = gql`
  query agentContactInfoQuery {
    current_user{
      id
      agent {
        id
        photo_url
        full_name
        broker_logo_url
        enterprise_name
        license_prefix
        licenses
      }
    }
  }
`

const AgentContactInfo = () => {
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

  const {data, loading} = useQuery(AGENT_CONTACT_INFO_QUERY)

  if (loading) return <Skeleton variant="rectangular" width='100%' height={100} />

  const {
    full_name,
    photo_url,
    broker_logo_url,
    license_prefix,
    licenses,
    enterprise_name
  } = data?.current_user?.agent || {}

  return (
    <Stack
      direction='column'
      justifyContent='center'
      spacing={4}
    >
      <Typography variant='h3' textAlign='center' color='text.primary'>
        Get an Expert Opinion on Your Home's Value
      </Typography>

      <Stack direction={isSmallerThanSm ? 'column' : 'row'} spacing={4} justifyContent='center' alignItems='center'>
        <Avatar
          alt="Agent profile picture"
          src={photo_url}
          variant='rounded'
          sx={{height: 120, width: 120, flexShrink: 1 }}
        />

        <Stack direction='column' spacing={1}>
          <Typography
            variant='h1'
            color='text.primary'
            textAlign={isSmallerThanSm ? 'center' : 'left'}
          >
            { full_name }
          </Typography>
          {
            licenses.length && <Typography
              variant='h5'
              color='text.secondary'
              textAlign={isSmallerThanSm ? 'center' : 'left'}
            >
              { license_prefix } #{ licenses.join(', #')}
            </Typography>
          }

          <Typography
            variant='h5'
            color='text.secondary'
            textAlign={isSmallerThanSm ? 'center' : 'left'}
          >
            { enterprise_name }
          </Typography>

        </Stack>
      </Stack>
      <Box
        sx={{mx: 'auto !important'}}
      >
        <Box
          component='img'
          src={broker_logo_url}
          alt='Brokerage logo'
          width={250}
        />
      </Box>
    </Stack>
  )
}

export default AgentContactInfo
