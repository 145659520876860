import {Link} from "react-router-dom";
import {SUGGESTED_LISTINGS_SECTIONS} from './../SuggestedListings';
import { Component } from 'react';

class ListingsBlankSlate extends Component {
  render() {
    const page = this.props.page;
    const titleOptions = {
      [SUGGESTED_LISTINGS_SECTIONS.interested]: 'You have no interested listings.',
      [SUGGESTED_LISTINGS_SECTIONS.matched]: 'You have no matches.',
      [SUGGESTED_LISTINGS_SECTIONS.recentlyViewed]: 'You have no recently viewed listings.'
    };
    const subMessageOptions = {
      [SUGGESTED_LISTINGS_SECTIONS.interested]: <p>Start searching on the&nbsp;<Link to={'/map'}>Map Search</Link>&nbsp;to find listings you're interested in.</p>,
      [SUGGESTED_LISTINGS_SECTIONS.matched]: <p>Go to the&nbsp;<Link to={'/map'}>Map Search</Link>&nbsp;and save a search.</p>,
      [SUGGESTED_LISTINGS_SECTIONS.recentlyViewed]: <p>Start browsing around on the&nbsp;<Link to={'/map'}>Map Search</Link>.</p>
    };

    return (
      <div className="container mt-2">
        <div className="col">
          <div className="p-4">
            <h4>{titleOptions[page]}</h4>
            {subMessageOptions[page]}
          </div>
        </div>
      </div>
    )
  }
}

export default ListingsBlankSlate;