import { Component } from 'react';
import Select, { components } from 'react-select-2'
import PropTypes from 'prop-types'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { withRouter } from 'react-router-dom'
import { FiltersContext } from '../Contexts/FiltersContext'
import { debounce } from '../../Utils/DebounceUtils'

const OMNI_TYPES = {
  CITY: 'CITY',
  NEIGHBORHOOD: 'NEIGHBORHOOD',
  LISTING: 'LISTING',
  ZIPCODE: 'ZIPCODE',
  SCHOOL: 'SCHOOL',
  TOWNSHIP: 'TOWNSHIP',
}

const FRIENDLY_NAMES = {
  [OMNI_TYPES.LISTING]: 'Address',
  [OMNI_TYPES.CITY]: 'City',
  [OMNI_TYPES.NEIGHBORHOOD]: 'Neighborhood',
  [OMNI_TYPES.TOWNSHIP]: 'Township',
  [OMNI_TYPES.ZIPCODE]: 'Postal Code',
  [OMNI_TYPES.SCHOOL]: 'School',
}

const Option = props => (
  <components.Option {...props}>
    <p className="mb-0">{props.data.label}</p>
    <small>{FRIENDLY_NAMES[props.data.display_type] || FRIENDLY_NAMES[props.data.type]}</small>
  </components.Option>
)

const MenuList = (props, onCategorySelect, selectedCategory, counts) => {
  const onClick = (e, catType) => {
    e.preventDefault()
    onCategorySelect(catType)
  }

  const categories = [
    ['Everything', null],
    ['Addresses', OMNI_TYPES.LISTING],
    ['Cities/Towns', OMNI_TYPES.CITY],
    ['Neighborhoods', OMNI_TYPES.NEIGHBORHOOD],
    ['Postal Codes', OMNI_TYPES.ZIPCODE],
    ['Schools', OMNI_TYPES.SCHOOL],
  ]

  return (
    <components.MenuList {...props}>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-4">
          <ul className="list-group">
            {categories.map(categoryData => {
              const countForCategory = counts[categoryData[1]]
              return (
                <a
                  href="javascript:void(0)"
                  onClick={() => onCategorySelect(categoryData[1])}
                  key={categoryData[1]}
                  className={`d-flex justify-content-between align-items-center ${
                    selectedCategory === categoryData[1] ? 'text-dark' : 'text-muted'
                  } font-weight-bold py-2 px-1`}
                >
                  <div style={{ minWidth: 110 }}>{categoryData[0]}</div>
                  {countForCategory && countForCategory > 0 ? (
                    <div className="badge badge-primary">{countForCategory}</div>
                  ) : (
                    <div className="badge badge-secondary">0</div>
                  )}
                </a>
              )
            })}
          </ul>
        </div>
        <div className="col" style={{ padding: 0 }}>
          <div style={{ height: 250, overflowY: 'scroll' }} className="omnisearch-options">
            {props.children}
          </div>
        </div>
      </div>
    </components.MenuList>
  )
}

class Omnisearch extends Component {
  static contextType = FiltersContext

  state = {
    // menuIsOpen: false,
    category: null,
    options: [],
    counts: {},
    inputValue: '',
  }

  onSelect = selectedItem => {
    if (selectedItem.type === OMNI_TYPES.LISTING) {
      this.props.history.push({
        pathname: `/listings/${selectedItem.slug}`,
        state: { modal: true },
      })
    } else {
      this.context.changeMapFilter({ geo_id: selectedItem.value, polygon: null })
      this.setState({ inputValue: '' })
      const searchPath = '/map'
      const exclusivesPath = '/exclusives'
      if (![searchPath, exclusivesPath].includes(this.props.location.pathname)) {
        this.props.history.push({ pathname: searchPath })
      }
    }
  }

  onChange = inputValue => {
    this.setState({ inputValue })
    if (!inputValue.length || inputValue.length < 3) return
    this.setState({ loading: true })
    this.props.client
      .query({
        query: gql`
          query locationSearch($query: String!) {
            search_location(query: $query, first: 50) {
              edges {
                node {
                  __typename
                  ... on City {
                    name
                    state
                    es_id
                  }
                  ... on Township {
                    name
                    state
                    es_id
                  }
                  ... on Neighborhood {
                    name
                    sub_name
                    es_id
                  }
                  ... on PublicSchool {
                    name
                    sub_name
                    state
                    es_id
                  }
                  ... on Zipcode {
                    name
                    es_id
                  }
                }
              }
            }
            properties_by_query(query: $query) {
              edges {
                node {
                  id
                  address
                  city
                  mls_number
                  slug
                }
              }
            }
          }
        `,
        variables: { query: inputValue },
      })
      .then(({ data }) => {
        const counts = {
          [OMNI_TYPES.CITY]: 0,
          [OMNI_TYPES.NEIGHBORHOOD]: 0,
          [OMNI_TYPES.ZIPCODE]: 0,
          [OMNI_TYPES.SCHOOL]: 0,
        }
        const properties = data.properties_by_query.edges.map(edge => ({
          value: edge.node.id,
          slug: edge.node.slug,
          label: `${edge.node.address}, ${edge.node.city} (${edge.node.mls_number})`,
          type: OMNI_TYPES.LISTING,
        }))
        counts[OMNI_TYPES.LISTING] = properties.length
        const places = data.search_location.edges.map(edge => {
          const { node } = edge
          switch (node.__typename) {
            case 'City':
              counts[OMNI_TYPES.CITY]++
              return {
                value: node.es_id,
                label: `${node.name}, ${node.state}`,
                type: OMNI_TYPES.CITY,
              }
            case 'Township':
              counts[OMNI_TYPES.CITY]++
              return {
                value: node.es_id,
                label: `${node.name}, ${node.state}`,
                type: OMNI_TYPES.CITY,
                display_type: OMNI_TYPES.TOWNSHIP,
              }
            case 'Neighborhood':
              counts[OMNI_TYPES.NEIGHBORHOOD]++
              return {
                value: node.es_id,
                label: `${node.name}, ${node.sub_name}`,
                type: OMNI_TYPES.NEIGHBORHOOD,
              }
            case 'Zipcode':
              counts[OMNI_TYPES.ZIPCODE]++
              return { value: node.es_id, label: node.name, type: OMNI_TYPES.ZIPCODE }
            case 'PublicSchool':
              counts[OMNI_TYPES.SCHOOL]++
              return {
                value: node.es_id,
                label: `${node.name}, ${node.sub_name}`,
                type: OMNI_TYPES.SCHOOL,
              }
          }
        })

        counts.null = Object.values(counts).reduce((sum, x) => sum + x)

        const options = [...places, ...properties]
        this.setState({ options, counts, loading: false })
      })
      .catch(e => {
        console.log(e)
      })
  }

  onCategorySelect = category => {
    this.setState({ category })
  }

  render() {
    let options
    if (this.state.category) {
      options = this.state.options.filter(opt => opt.type === this.state.category)
    } else {
      options = this.state.options
    }
    return (
      <div>
        <Select
          placeholder="City, Zip, Neighborhood, Address, School, MLS#"
          onInputChange={debounce(this.onChange, 500)}
          components={{
            Option,
            MenuList: props =>
              MenuList(props, this.onCategorySelect, this.state.category, this.state.counts),
          }}
          isLoading={this.state.loading}
          options={options}
          onChange={this.onSelect}
          value={this.state.inputValue}
          classNamePrefix="omnisearch"
          className="omnisearch"
          aria-label="Search by City, Zip, Neighborhood, Address, School, MLS#"
        />
      </div>
    )
  }
}

export default withRouter(withApollo(Omnisearch))
