import { Button, CardContent, Grid, TextField, Typography } from '@mui/material'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'
import SignupDisclaimerText from '@/components/Homebuyer/Common/SignupDisclaimerText'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import { useContext } from 'react'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'
import SignUpForm from '@/components/Homebuyer/Common/SignUpForm'

const CreateAccount = () => {
  const { agent } = useContext(CurrentUserContext)

  return (
    <RsCard
      title="Create an account"
      subheader="Setup your account to create listing alerts and search for listings"
    >
      <CardContent>
        <SignUpForm redirectPath={'/onboarding/requirements'} />
      </CardContent>
      <CardContent>
        <SignupDisclaimerText agent={agent} />
      </CardContent>
    </RsCard>
  )
}

export default CreateAccount
