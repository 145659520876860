import { Component } from 'react';
import { Creatable } from 'react-select-2';
import { numberToCurrency } from './../Utils/NumberUtils';


class CustomPriceSelector extends Component {
  
  handleCommaInput(input) {
    let value = input;
    if (value.includes(',')) {
      value = value.split(',').reduce((acc, current) => {
        return acc.concat(current)
      })
    }
    return parseInt(value);
  }

  validateValueAgainstLimits(value){
    return value < 100000000 && value > -1
  }

  render() {
    const styles = {
      singleValue: (base, state) => ({
        ...base,
        color: state.selectProps.menuIsOpen ? 'transparent' : base.color,
      }),
    }
    const {fieldName, placeholder, options, currentFilterOption, changeFilter, ariaLabel} = this.props;

    let selectedOption = options.find(option => option.value === currentFilterOption);
    if (!selectedOption && currentFilterOption) {
      selectedOption = { label: `$${currentFilterOption.toLocaleString('en')}`, value: currentFilterOption };
    }

    return <Creatable
      value={selectedOption}
      onChange={(selectedOption) => {
        let value = selectedOption.value;
        if (typeof value === 'string') {
          value = this.handleCommaInput(value);
        }
        if(this.validateValueAgainstLimits(value)){
          changeFilter(value);
        }
      }}
      styles={styles}
      options={options}
      aria-label={ariaLabel}
      placeholder={placeholder}
      className="w-100"
      classNamePrefix={fieldName}
      isValidNewOption={(input) => {
        const value = this.handleCommaInput(input);
        return isFinite(value) && this.validateValueAgainstLimits(value)
      }}
      formatCreateLabel={(input) => {
        if (input.includes(',')) {
          return `$${input}`
        }
        return numberToCurrency(input);
      }}
    />
  }
}

export default CustomPriceSelector
