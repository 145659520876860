import React, { useState, useEffect, useContext } from 'react'
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Divider,
  InputAdornment,
  Button,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { gql, useMutation } from '@apollo/client'
import RsCard from '../UIComponents/Card/RsCard'
import {
  calculateMonthlyPrincipalAndInterest,
  calculateMonthlyInsurance,
  calculateMonthlyPropertyTax,
  calculatePMI,
} from '../../Utils/MortgageMathUtils'
import { numberToCurrency } from '../../Utils/NumberUtils'
import DisclaimerTextModal from '~/components/Common/UIComponents/Modal/DisclaimerTextModal'
import useMutationSnackbarMessages from '@/components/CustomHooks/MutationSnackbarHooks'
import { CurrentUserContext } from '@/components/Homebuyer/Contexts/CurrentUserContext'

export const MORTGAGE_CALCULATOR_FRAGMENT = gql`
  fragment mortgageCalculatorPropertyFragment on Property {
    id
    list_price
    simple_type
  }
  fragment mortgageCalculatorUserFragment on User {
    id
    mortgage_rate_basis_points
    mortgage_type
    down_payment_type
    down_payment_amount
  }
`

const UPDATE_USER_MORTGAGE_DETAILS = gql`
  mutation updateUserMortgageDetails($input: UpdateUserInput!) {
    update_user(input: $input) {
      user {
        id
        mortgage_rate_basis_points
        mortgage_type
        down_payment_type
        down_payment_amount
      }
    }
  }
`

const MortgageCalculator = ({
  userId,
  propertyType,
  initialListingPrice = 0,
  initialInterestRate = 6.0,
  initialMortgageType = '30-year fixed',
  initialDownPaymentPercentage = 20,
}) => {
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)
  const insurancePercentage = 0.007 // National average insurance rates come out to ~.7% of home value
  const propertyTaxPercentage = 0.01 // Rough estimate for property tax
  const [listingPrice, setListingPrice] = useState(initialListingPrice)
  const [interestRate, setInterestRate] = useState(initialInterestRate)
  const [mortgageType, setMortgageType] = useState(initialMortgageType)
  const [downPaymentPercentage, setDownPaymentPercentage] = useState(initialDownPaymentPercentage)
  const [monthlyPrincipalAndInterest, setMonthlyPrincipalAndInterest] = useState(0)
  const [monthlyInsurance, setMonthlyInsurance] = useState(0)
  const [monthlyPropertyTax, setMonthlyPropertyTax] = useState(0)
  const [monthlyPMI, setMonthlyPMI] = useState(0)
  const [userSetInsurance, setUserSetInsurance] = useState(false)
  const mutationSnackbarMessages = useMutationSnackbarMessages({
    dataAttribute: 'update_user',
    customSuccessMessage: 'Successfully saved mortgage options',
  })
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MORTGAGE_DETAILS, {
    ...mutationSnackbarMessages,
  })

  if (propertyType !== 'SFR') {
    return null
  }
  const handleClick = () => {
    if (loggedIn) {
      updateUser({
        variables: {
          input: {
            id: userId,
            mortgage_rate_basis_points: parseInt(interestRate * 100, 10),
            mortgage_type: mortgageType,
            down_payment_amount: parseInt(downPaymentPercentage, 10),
          },
        },
      })
    } else {
      showSignUp()
    }
  }

  const mortgageTypes = [
    { value: '30-year fixed', label: '30-year fixed' },
    { value: '15-year fixed', label: '15-year fixed' },
  ]

  useEffect(() => {
    calculateMonthlyPayments()
  }, [listingPrice, interestRate, mortgageType, downPaymentPercentage])

  const calculateMonthlyPayments = () => {
    if (!listingPrice || !interestRate) return

    const principal = parseFloat(listingPrice)
    const downPayment = (principal * downPaymentPercentage) / 100
    const loanAmount = principal - downPayment
    const annualInterestRate = parseFloat(interestRate) / 100

    const monthlyPrincipalAndInterestPayment = calculateMonthlyPrincipalAndInterest(
      loanAmount,
      annualInterestRate,
      mortgageType
    )

    setMonthlyPrincipalAndInterest(monthlyPrincipalAndInterestPayment)

    if (!userSetInsurance) {
      const monthlyInsurancePayment = calculateMonthlyInsurance(listingPrice, insurancePercentage)
      setMonthlyInsurance(monthlyInsurancePayment)
    }

    const monthlyPropertyTaxPayment = calculateMonthlyPropertyTax(
      listingPrice,
      propertyTaxPercentage
    )
    setMonthlyPropertyTax(monthlyPropertyTaxPayment)

    const monthlyPMIPayment = calculatePMI(loanAmount, downPaymentPercentage)
    setMonthlyPMI(monthlyPMIPayment)
  }

  const handleInsuranceChange = e => {
    setMonthlyInsurance(e.target.value)
    setUserSetInsurance(true)
  }

  const totalMonthlyPayment =
    parseFloat(monthlyPrincipalAndInterest) +
    parseFloat(monthlyInsurance) +
    parseFloat(monthlyPropertyTax) +
    parseFloat(monthlyPMI)

  const data = [
    {
      name: 'Principal & Interest',
      value: parseFloat(monthlyPrincipalAndInterest),
      color: '#7CB888',
    },
    { name: 'Insurance', value: parseFloat(monthlyInsurance), color: '#235D89' },
    { name: 'Property Tax', value: parseFloat(monthlyPropertyTax), color: '#309FC6' },
    { name: 'PMI', value: parseFloat(monthlyPMI), color: '#F39C12' },
  ]

  const minListingPrice = initialListingPrice * 0.5
  const maxListingPrice = initialListingPrice * 1.5

  return (
    <RsCard cardProps={{ sx: { boxShadow: 'none', mb: 2 } }} title="Cost Calculator">
      <CardContent sx={{ padding: 0 }}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                innerRadius="70%"
                outerRadius="85%"
                paddingAngle={5}
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="body2">Estimated Payment</Typography>
            <Typography variant="h3">
              {numberToCurrency(totalMonthlyPayment.toFixed(0))}
              <Typography variant="subtitle1" component="span">
                /mo
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: 3 }}>
          {data.map((entry, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box sx={{ width: 16, height: 16, bgcolor: entry.color, borderRadius: 1, mr: 2 }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {entry.name}
              </Typography>
              <Typography variant="body1">{numberToCurrency(entry.value.toFixed(0))}</Typography>
            </Box>
          ))}
        </Box>
        <Divider />
        <Accordion sx={{ mt: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              label="Listing Price"
              value={listingPrice}
              onChange={e => setListingPrice(e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
              margin="normal"
            />
            <Slider
              value={listingPrice}
              onChange={(e, newValue) => setListingPrice(newValue)}
              aria-labelledby="listing-price-slider"
              min={minListingPrice}
              max={maxListingPrice}
              step={10000}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Interest Rate (%)"
              type="number"
              value={interestRate}
              onChange={e => setInterestRate(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Slider
              value={interestRate}
              onChange={(e, newValue) => setInterestRate(newValue)}
              aria-labelledby="interest-rate-slider"
              min={0}
              max={20}
              step={0.1}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Down Payment (%)"
              value={downPaymentPercentage}
              onChange={e => setDownPaymentPercentage(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Slider
              value={downPaymentPercentage}
              onChange={(e, newValue) => setDownPaymentPercentage(newValue)}
              aria-labelledby="down-payment-slider"
              min={0}
              max={100}
              step={1}
              valueLabelDisplay="auto"
              sx={{ mb: 2 }}
            />
            <TextField
              label="Monthly Insurance"
              value={monthlyInsurance}
              onChange={handleInsuranceChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <TextField
              select
              id="mortgage-type"
              label="Mortgage Type"
              value={mortgageType}
              onChange={e => setMortgageType(e.target.value)}
              fullWidth
              margin="normal"
            >
              {mortgageTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Button onClick={handleClick}>Save Options</Button>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <Box sx={{ mx: 2, mt: 2 }}>
          <DisclaimerTextModal smallText="Disclaimer" disclaimerTitle="Cost Calculator Disclaimer">
            The results provided by this mortgage calculator are intended for illustrative purposes
            only and are not guaranteed to be accurate. The estimates generated by this tool are
            based on the information provided by the user and do not constitute financial advice.
            Actual mortgage payments and interest rates may vary and are subject to approval by
            lenders. Additionally, other factors such as property taxes, homeowner's insurance,
            Private Mortgage Insurance (PMI), and other fees or expenses may impact your final
            monthly payment. Please consult with your agent for precise information and personalized
            advice regarding your mortgage options.
          </DisclaimerTextModal>
        </Box>
      </CardContent>
    </RsCard>
  )
}

export default MortgageCalculator
