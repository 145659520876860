import React from 'react'
import {gql, useMutation, useQuery} from '@apollo/client';
import AddCommute from './AddCommute';
import {friendlyCommuteType} from "../../../Utils/CommuteUtils";
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'

const COMMUTES_QUERY = gql`
      query commutesQuery{
        current_user{
          id
          commutes:points_of_interest { 
            edges { 
              node { id address commute_type lat lng } 
            } 
          }
        }
      }
    `;

const REMOVE_COMMUTE_MUTATION= gql`
   mutation delete_commute($input: DeleteCommuteInput!){
    delete_commute(input: $input){
      user{
        id
        commutes:points_of_interest { 
          edges { 
            node { id address commute_type lat lng } 
          } 
        }
      }
    }
  }
  `;

const Commutes = () => {

  const {data, loading} = useQuery(COMMUTES_QUERY);
  const [removeCommute, { loading:removeCommuteLoading }] = useMutation(REMOVE_COMMUTE_MUTATION);

  if(loading) return null;
  let commutes = []
  if(data?.current_user?.commutes?.edges){
    commutes = data?.current_user?.commutes?.edges;
  }

  return(
      <div className="card mb-4" id="commutes">
        <div className="card-header">Commutes</div>
        <div className="card-body">
                <div>
                  {commutes.map((commuteNode) => {
                    let commute = commuteNode.node;
                    return (
                      <div className="media align-items-center pb-1 mb-3 commute-row" key={commute.id}>
                        <img src={`https://api.maptiler.com/maps/a7dd27eb-217c-40e7-901d-58705e95cd3d/static/${commute.lng},${commute.lat},14/200x200.png?key=ljZ3lZVgrAw68a6yu4hM`} className="d-block ui-w-80 rounded-circle"/>
                        <div className="media-body pl-3">
                          <div className="text-dark">{commute.address}</div>
                          <div className="badge badge-secondary">{friendlyCommuteType(commute.commute_type)}</div>
                        </div>
                        <button type="button"
                                className={`btn btn-sm btn-default md-btn-flat d-block ${HbAnalyticsClass('remove-commute', 'profile')}`}
                                onClick={() => removeCommute({variables: {input: {id: commute.id}}})}>Remove
                        </button>
                      </div>
                    )
                  })}
                  {
                    commutes.length === 0 &&
                    <div className="col pb-1 mb-0">
                      <div className="alert alert-secondary mb-0">
                      Add a location to calculate your commute from any listing.
                      </div>
                    </div>
                  }
                </div>
        </div>
        <div className="card-footer">
          <AddCommute/>
        </div>
      </div>
    )
}

export default Commutes;
