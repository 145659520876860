import { Typography, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { gql } from '@apollo/client'
import TimeFormatter from '@/components/Utils/TimeUtils'
import { priceWithFallback } from '@/components/Utils/NumberUtils'
import RsCard from '@/components/Common/UIComponents/Card/RsCard'
import RsCardFooterAction from '@/components/Common/UIComponents/Card/RsCardFooterAction'

export const LISTING_HISTORY_FRAGMENT = gql`
  fragment listingHistoryFragment on Property {
    id
    property_histories {
      id
      change_type
      created_at
      old_value
      new_value
    }
  }
`

const History = ({ listingHistory }) => {
  const prettyValue = value => (isNaN(parseInt(value)) ? value : priceWithFallback(value))

  const prettyCreatedAt = createdAt => {
    if (createdAt) {
      return TimeFormatter.formatUnix(createdAt, 'monthDayYear')
    }
    return '(Unknown)'
  }

  const historyItems = listingHistory.filter(lh => [0, 2, 3].includes(lh.change_type))

  return (
    <RsCard title="Listing History" cardProps={{ sx: { boxShadow: 'none' } }}>
      <Table className="no-style">
        <TableBody>
          {historyItems.map(listingHistoryItem => (
            <TableRow
              key={listingHistoryItem.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{prettyCreatedAt(listingHistoryItem.created_at)}</TableCell>
              <TableCell>
                {listingHistoryItem.change_type === 3 ? (
                  prettyValue(listingHistoryItem.old_value)
                ) : (
                  <s>{prettyValue(listingHistoryItem.old_value)}</s>
                )}
              </TableCell>
              <TableCell>{prettyValue(listingHistoryItem.new_value)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RsCardFooterAction>
        <Typography variant="body2" color="text.secondary" px={1} py={0.5}>
          Listing History is calculated by RealScout, and is not guaranteed to be accurate,
          up-to-date or complete.
        </Typography>
      </RsCardFooterAction>
    </RsCard>
  )
}

export default History
