import { Button } from '@mui/material'

export const displayMessageResultSnackbar = (result, enqueueSnackbar) => {
  if (result.errors?.length) {
    const errorMessages = result.errors.map(e => `${e.key}: ${e.message}`).join(' ')
    enqueueSnackbar(`Errors: ${errorMessages}`, {
      variant: 'error',
      autoHideDuration: 3000,
    })
  } else if (result.conversation) {
    enqueueSnackbar('Your message has been sent', {
      variant: 'success',
      autoHideDuration: 3000,
      action: (
        <Button href="/homesearch/conversations" style={{ color: 'white' }}>
          View Conversation
        </Button>
      ),
    })
  }
}
