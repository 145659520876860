import React, { useState, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Button,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FiltersContext } from '../../Contexts/FiltersContext'
import { CurrentUserContext } from '../../Contexts/CurrentUserContext'
import * as Sentry from '@sentry/react'
import { useSnackbar } from 'notistack'
import RsModal from '../../../Common/UIComponents/Modal/RsModal'
import RsCardFooterAction from '../../../Common/UIComponents/Card/RsCardFooterAction'

const SaveSearch = ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { loggedIn, showSignUp } = useContext(CurrentUserContext)
  const { mapFilters, searchGeoshape, searchBounds } = useContext(FiltersContext)

  const scheduleOptions = [
    { label: 'Daily', value: 'DAILY' },
    { label: 'ASAP', value: 'ASAP' },
  ]
  const [searchName, setSearchName] = useState('My Home Search')
  const [selectedScheduleOption, setSelectedScheduleOption] = useState(scheduleOptions[0])

  const handleScheduleChange = event => {
    setSelectedScheduleOption(scheduleOptions.find(option => option.value === event.target.value))
  }

  const onOpenModalButtonPress = onOpen => {
    if (searchGeoshape) {
      setSearchName(`My ${searchGeoshape.name} Search`)
    }
    loggedIn ? onOpen() : showSignUp()
  }

  const [saveSearch, { loading }] = useMutation(saveSearchMutation, {
    onCompleted: () => {
      enqueueSnackbar('Search successfully saved!', {
        variant: 'success',
        autoHideDuration: 3000,
      })
    },
    onError: error => {
      enqueueSnackbar(error.message, {
        variant: 'error',
        autoHideDuration: 3000,
      })
      if (process.env.NODE_ENV === 'production') {
        Sentry.withScope(scope => {
          scope.setExtra('saveSearch', true)
          Sentry.captureException(error)
        })
      }
    },
  })

  const onSave = (searchName, selectedScheduleOption, onClose) => {
    // Formulate search params
    let saveSearchParams = {
      search_name: searchName,
      schedule: selectedScheduleOption,
      price_min: mapFilters.price_min,
      price_max: mapFilters.price_max,
      for_sale: mapFilters.for_sale,
      for_rent: mapFilters.for_rent,
      only_open_houses: mapFilters.has_future_open_house_filter,
      lot_min: mapFilters.lot_min,
      lot_max: mapFilters.lot_max,
      days_max: mapFilters.days_max,
      hoa_max: mapFilters.hoa_max,
      beds_min: mapFilters.beds_min,
      baths_min: mapFilters.baths_min,
      property_types: mapFilters.property_types,
      sqft_min: mapFilters.sqft_min,
      sqft_max: mapFilters.sqft_max,
    }

    if (searchGeoshape) {
      saveSearchParams.geo_ids = [searchGeoshape.id]
    } else if (mapFilters.polygon) {
      saveSearchParams.geojson_polygons = [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [mapFilters.polygon['coordinates']],
          },
        },
      ]
    } else if (searchBounds) {
      let mapBounds = [
        [searchBounds.ne_lon, searchBounds.ne_lat], // top right
        [searchBounds.ne_lon, searchBounds.sw_lat], // bottom right
        [searchBounds.sw_lon, searchBounds.sw_lat], // bottom left
        [searchBounds.sw_lon, searchBounds.ne_lat], // top left
        [searchBounds.ne_lon, searchBounds.ne_lat], // top right again
      ]
      saveSearchParams['polygons'] = [
        {
          coordinates: mapBounds,
        },
      ]
    }
    // Fire mutation
    saveSearch({ variables: { data: saveSearchParams } }).then(() => {
      onClose()
    })
  }

  return (
    <RsModal
      title="Save Search"
      disableClose={loading}
      modalStylesProp={{ width: '500px' }}
      renderTriggerComponent={({ onOpen }) => (
        <Button
          fullWidth={isMobile}
          sx={{
            '&:focus': { outline: 'none' },
          }}
          variant={isMobile ? 'contained' : 'outlined'}
          onClick={() => onOpenModalButtonPress(onOpen)}
        >
          Save Search
        </Button>
      )}
      renderContentComponent={({ onClose }) => (
        <>
          <CardContent>
            <Stack spacing={4} mt={1}>
              <TextField
                fullWidth
                label="Search Name"
                variant="outlined"
                value={searchName}
                onChange={e => setSearchName(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel>Receive new alerts</InputLabel>
                <Select
                  value={selectedScheduleOption.value}
                  label="Receive new alerts"
                  onChange={handleScheduleChange}
                >
                  {scheduleOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </CardContent>
          <RsCardFooterAction>
            <Button
              aria-label="confirm-save-search"
              variant="contained"
              fullWidth
              onClick={() => onSave(searchName, selectedScheduleOption.value, onClose)}
              disabled={loading}
              endIcon={
                loading && (
                  <CircularProgress
                    sx={theme => ({ color: theme.colors.alpha.black[30] })}
                    size={15}
                  />
                )
              }
            >
              Save Search
            </Button>
          </RsCardFooterAction>
        </>
      )}
    />
  )
}

const saveSearchMutation = gql`
  mutation createSavedSearch($data: CreateSavedSearchInput!) {
    create_saved_search(input: $data) {
      saved_search {
        id
        user {
          id
          saved_searches(only_active: true) {
            total_count
            edges {
              node {
                id
                search_name
              }
            }
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

export default SaveSearch
