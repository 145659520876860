import { gql, useQuery } from '@apollo/client'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  Avatar,
  Stack,
  Divider,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import RsBadge from '~/components/Common/UIComponents/Badge/RsBadge'
import { friendlyCommuteType } from '~/components/Utils/CommuteUtils'
import CalculateCommuteButton from './CalculateCommuteButton'
import RsSkeletonLoading from '@/components/Common/UIComponents/PageLayout/RsSkeletonLoading'

const COMMUTES_QUERY = gql`
  query commutesQuery {
    current_user {
      id
      commutes: points_of_interest {
        edges {
          node {
            id
            address
            commute_type
            lat
            lng
          }
        }
      }
    }
  }
`

const Commutes = ({ latitude, longitude }) => {
  const { data, loading, error } = useQuery(COMMUTES_QUERY)

  const theme = useTheme()
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'))

  if (loading) {
    return (
      <Box p={2}>
        <RsSkeletonLoading height="100px" />
      </Box>
    )
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography variant="body2" color="text.secondary">
          Unable to load your commutes. Please try again later.
        </Typography>
      </Box>
    )
  }

  const commutes = data?.current_user?.commutes?.edges?.map(e => e.node) || []

  if (!commutes.length) {
    return (
      <Box p={2}>
        <Typography>
          <Link component={RouterLink} to="/profile">
            Add a commute
          </Link>{' '}
          to calculate travel times to your favorite locations.
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Box px={2} pb={1} pt={isLargerThanMd ? 0 : 1}>
        <Typography fontWeight="bold">Commutes</Typography>
      </Box>
      <Divider />
      <Stack direction="column" spacing={1} divider={<Divider />}>
        {commutes.map(commute => (
          <Box key={commute.id} sx={{ py: 1, px: 2 }}>
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
              {isLargerThanMd && (
                <Box flexShrink={1}>
                  <Avatar
                    sx={theme => ({
                      width: 35,
                      height: 35,
                      backgroundColor: theme.colors.primary.main,
                    })}
                  >
                    <LocationOnIcon />
                  </Avatar>
                </Box>
              )}

              <Box flexGrow={1}>
                <Typography pb={1}>{commute.address}</Typography>
                <RsBadge
                  label={friendlyCommuteType(commute.commute_type)}
                  color="primary"
                  size="small"
                />
              </Box>

              <Box flexShrink={1} maxWidth="25%">
                <CalculateCommuteButton
                  commuteId={commute.id}
                  latitude={latitude}
                  longitude={longitude}
                />
              </Box>
            </Stack>
          </Box>
        ))}
        <Box
          px={2}
          pb={1}
          textAlign="center"
          display="flex"
          justifyContent="center"
          alighItems="center"
        >
          <Link component={RouterLink} to="/profile">
            + Add Commute
          </Link>
        </Box>
      </Stack>
    </>
  )
}

export default Commutes
