import { Route, Switch } from 'react-router-dom'
import HomebuyerConversationThread from './Conversations/HomebuyerConversationThread'
import HomebuyerAttachmentsSidebar from './Conversations/HomebuyerAttachmentsSidebar'
import { Desktop, MobileAndTablet } from '../../Common/ResponsiveComponents'

const Conversations = () => (
  <div className="layout-content container-fluid" id="conversation-container">
    <div className="chat-wrapper container-p-y" style={{ flex: 'none' }}>
      <div className="card container-fluid">
        <div className="row no-gutters h-100">
          <MobileAndTablet>
            <Switch>
              <Route path="/conversations/attachments/:messageId" component={HomebuyerAttachmentsSidebar} />
              <Route path="/" component={HomebuyerConversationThread} />
            </Switch>
          </MobileAndTablet>

          <Desktop>
            <Switch>
              <Route path="/" component={HomebuyerConversationThread} />
            </Switch>
          </Desktop>
        </div>
      </div>
    </div>
  </div>
)

export default Conversations
