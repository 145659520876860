import { useState, useContext } from 'react'
import { gql, useMutation } from '@apollo/client'
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Button,
} from '@mui/material'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import RsCard from '../../../../Common/UIComponents/Card/RsCard'
import { basicPluralize } from '../../../../Utils/StringUtils'
import NotLoggedInView from './NotLoggedInView'
import OpenHouseCards from './OpenHouseCards'

export const LISTING_OPEN_HOUSES_FRAGMENT = gql`
  fragment listingOpenHousesFragment on Property {
    id
    address
    open_houses {
      total_count
      edges {
        node {
          id
          end_date_time
          start_date_time
          timezone_offset
        }
      }
    }
  }
`

const RECORD_OPEN_HOUSE_CLICK = gql`
  mutation recordOpenHouseClick($data: RecordOpenHouseClickInput!) {
    record_open_house_click(input: $data) {
      errors {
        key
        message
      }
    }
  }
`

const OpenHouses = ({ listingId, openHouses, address }) => {
  const theme = useTheme()
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down('lg'))
  const { displayRules, loggedIn, agent } = useContext(CurrentUserContext)
  const { suppress_open_house_info } = displayRules
  const [expanded, setExpanded] = useState(false)
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false)

  const [recordOpenHouseClick] = useMutation(RECORD_OPEN_HOUSE_CLICK)

  const showLoggedInSuppressionMessage = loggedIn && suppress_open_house_info
  const buttonText = showLoggedInSuppressionMessage
    ? 'Request Open House Dates & Times'
    : 'View Dates & Times'

  const showOpenHouseInfo = loggedIn && !suppress_open_house_info
  const notLoggedIn = !loggedIn

  const handleClick = () => {
    if (!expanded && !hasBeenExpanded && loggedIn) {
      recordOpenHouseClick({
        variables: { data: { property_id: listingId } },
      })
    }
    setExpanded(!expanded)
    setHasBeenExpanded(true)
  }

  return (
    <RsCard cardProps={{ sx: { boxShadow: 'none' } }}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ px: 3, py: isSmallerThanLg ? 1 : 0 }}
          onClick={handleClick}
        >
          <Box
            display="flex"
            flexDirection={isSmallerThanLg ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Box component="span">
              There {openHouses.total_count > 1 ? 'are' : 'is'}{' '}
              <Box component="span" fontWeight="bold">
                {openHouses.total_count} open {basicPluralize('house', openHouses.total_count)}
              </Box>{' '}
              scheduled for {address}.
            </Box>
            <Box sx={isSmallerThanLg ? { mt: 2, width: '100%' } : {}}>
              <Button variant="contained" fullWidth={isSmallerThanLg}>
                {buttonText}
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ p: 3 }}>
          {showLoggedInSuppressionMessage && (
            <Typography textAlign="center">
              {agent.name} has been notified of your interest in the open house schedule for this
              home. You may also suggest a time for a private showing.
            </Typography>
          )}
          {showOpenHouseInfo && <OpenHouseCards openHouses={openHouses.edges.map(oh => oh.node)} />}
          {notLoggedIn && <NotLoggedInView />}
        </AccordionDetails>
      </Accordion>
    </RsCard>
  )
}

export default OpenHouses
