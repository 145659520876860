import { FiltersContext } from '../../Contexts/FiltersContext'
import { SplitLayoutContext } from '../../Contexts/SplitLayoutContext'
import { useContext } from 'react'
import { Box, Divider, Button, Drawer } from '@mui/material'
import CommonFilters from './Filters/CommonFilters'
import { basicPluralize } from '../../../Utils/StringUtils'

const MobileSearchFilters = ({ filterModalOpen, setFilterModalOpen }) => {
  const { onResetFilter } = useContext(FiltersContext)
  const splitContext = useContext(SplitLayoutContext)

  return (
    <div>
      <Drawer anchor="bottom" id="search-filters-drawer" open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <Box sx={{ p: 2, mb: 8 }}>
          <CommonFilters />
        </Box>
        <Box sx={{ position: 'fixed', bottom: 0, backgroundColor: 'white', width: '100%', zIndex: 2 }}>
          <Divider />
          <Box padding={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button fullWidth variant="contained" onClick={() => setFilterModalOpen(false)}>
              {`See ${splitContext.total_count || splitContext.current_count} ${basicPluralize('Listing', splitContext.total_count || splitContext.current_count)}`}
            </Button>
            <Button fullWidth variant="text" size="small" sx={{ '&:focus': { outline: 'none' } }} onClick={onResetFilter}>
              Reset Filters
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

export default MobileSearchFilters
