import { useContext } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'
import LazyLoad from 'react-lazyload'
import { CircularProgress, Typography, Box, Stack, Grid, Container } from '@mui/material'
import Chat from './ListingDetail/Chat'
import SignUp from './ListingDetail/SignUp'
import Header, { LISTING_HEADER_FRAGMENT } from './Listing/Header'
import MainDetails, { LISTING_MAIN_DETAILS_FRAGMENT } from './Listing/MainDetails'
import Map, { LISTING_MAP_FRAGMENT } from './Listing/Map'
import FullDetails, { LISTING_FULL_DETAILS_FRAGMENT } from './Listing/FullDetails'
import Highlights, { LISTING_HIGHLIGHTS_FRAGMENT } from './Listing/Highlights'
import Feedback, { LISTING_FEEDBACK_FRAGMENT } from './Listing/Feedback'
import Schools from './Listing/Schools'
import History, { LISTING_HISTORY_FRAGMENT } from './Listing/History'
import AgentRemarks, { AGENT_REMARKS_FRAGMENT } from './Listing/AgentRemarks'
import AgentChatWidget from './ListingDetail/AgentChatWidget'
import BrokerageChatWidget from './ListingDetail/BrokerageChatWidget'
import OpenHouses, { LISTING_OPEN_HOUSES_FRAGMENT } from './Listing/OpenHouses'
import ListingPhotos from '../../Common/ListingDetail/ListingPhotos'
import { CurrentUserContext } from '../Contexts/CurrentUserContext'
import CollectionControls from './Listing/CollectionControls'
import { reportError } from '../../Common/ErrorCapture'
import SignUpModal from '../Layout/SignUpModal'
import ClimateScore from '../../Common/ListingDetail/ClimateScore'
import Unauthorized from './ListingDetail/Unauthorized'
import { TabletAndDesktop, Mobile } from '../../Common/ResponsiveComponents'
import Footer from './Listing/Footer'
import MortgageCalculator, {
  MORTGAGE_CALCULATOR_FRAGMENT,
} from '~/components/Common/ListingDetail/MortgageCalculator'
import { AGENT_FRAGMENT } from '../../Common/QuickMessageCard'

export const LISTING_ATTRIBUTION_FRAGMENT = gql`
  fragment listingAttributionUnderPhotoFragment on Property {
    id
    listing_agent_attribution
    display_rules {
      show_attribution_under_photo
    }
  }
`

const LISTING_QUERY = gql`
  query clientLDPListingQuery($id: ID!) {
    current_user {
      id
      ...mortgageCalculatorUserFragment
      can_view_cost_calculator
      agent {
        ...AgentFragment
      }
      property_by_id(id: $id) {
        ...listingHeaderFragment
        ...listingMainDetailsFragment
        ...listingOpenHousesFragment
        ...listingFullDetailsFragment
        ...mortgageCalculatorPropertyFragment
        ...listingAttributionUnderPhotoFragment
        ...listingHighlightsFragment
        ...listingHistoryFragment
        ...listingMapFragment
        ...listingFeedbackFragment
        ...agentRemarksFragment
        id
        mls_number
        mls_name
        mls_logo
        mls_display_name
        mls_disclaimer
        virtual_tour_urls
        messages {
          ...Message
        }
        agent_comment {
          id
          comment
        }
        user_interactions {
          id
          feedback {
            id
            category
            sentiment
          }
          conversation {
            id
          }
        }
        display_rules {
          show_attribution_under_photo
        }
        listing_agent {
          license_number
          name
        }
        listing_office {
          name
        }
        colisting_agent {
          name
        }
        selling_agent {
          name
        }
        coselling_agent {
          name
        }
        selling_office {
          name
        }
        updated_at
        feed_updated_at
        encoded_id
        list_price
        short_details {
          label
          value
        }
        details
        property_histories {
          change_type
          created_at
          id
          old_value
          new_value
        }
        address
        city
        state
        longitude
        latitude
        tags
        postal_code
        beds_total
        baths_total
        structure_sqft
        lot_size
        days_on_site
        display_status
        combined_display_status
        simple_listing_status
        latitude
        longitude
        is_exclusive
        idx_attribution_contact
        listing_agent_attribution
        co_listing_agent_attribution
        selling_agent_attribution
        co_selling_agent_attribution
        public_remarks
        sold_date
        sold_price
        unauthorized
        open_houses {
          edges {
            node {
              end_date_time
              start_date_time
              timezone_offset
              id
            }
          }
          total_count
        }
        verdict {
          id
          prose
          rating
        }
        slug
        photos {
          edges {
            node {
              id
              photo_url
              room_name
            }
          }
        }
      }
    }
  }
  ${Chat.fragments.messageFragment}
  ${AGENT_FRAGMENT}
  ${LISTING_HEADER_FRAGMENT}
  ${LISTING_MAIN_DETAILS_FRAGMENT}
  ${LISTING_OPEN_HOUSES_FRAGMENT}
  ${MORTGAGE_CALCULATOR_FRAGMENT}
  ${LISTING_FULL_DETAILS_FRAGMENT}
  ${LISTING_ATTRIBUTION_FRAGMENT}
  ${LISTING_HIGHLIGHTS_FRAGMENT}
  ${LISTING_HISTORY_FRAGMENT}
  ${LISTING_MAP_FRAGMENT}
  ${LISTING_FEEDBACK_FRAGMENT}
  ${AGENT_REMARKS_FRAGMENT}
`

const RECORD_LISTING_VIEW_MUTATION = gql`
  mutation recordPropertyView($data: RecordPropertyViewInput!) {
    record_property_view(input: $data) {
      property {
        id
      }
    }
  }
`

const ListingDetail = ({ match, isModal }) => {
  const { loggedIn, showingSignUp, displayRules, agent } = useContext(CurrentUserContext)
  const propertyId = match.params.id

  const [recordListingView] = useMutation(RECORD_LISTING_VIEW_MUTATION)

  const { data, loading } = useQuery(LISTING_QUERY, {
    variables: {
      id: propertyId,
    },
    onError: reportError,
    onCompleted: data => {
      if (loggedIn && !agent.agent_logged_in && data?.current_user?.property_by_id?.encoded_id) {
        recordListingView({
          variables: {
            data: {
              property_id: data?.current_user?.property_by_id?.encoded_id,
              context: 'ldp',
            },
          },
        })
      }
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const {
    id: userId,
    mortgage_rate_basis_points: mortgageRateBasisPoints,
    mortgage_type: mortgageType,
    down_payment_type: downPaymentType,
    down_payment_amount: downPaymentAmount,
  } = data?.current_user || {}

  const listing = data?.current_user?.property_by_id || {}
  const {
    unauthorized,
    is_exclusive: isExclusive,
    address,
    city,
    photos,
    agent_comment: agentComment,
    open_houses: openHouses,
    encoded_id: encodedId,
    latitude,
    longitude,
    details,
    messages,
    user_interactions: userInteractions,
    property_histories: propertyHistories,
    simple_listing_status: simpleListingStatus,
    days_on_site: daysOnSite,
    sold_date: soldDate,
    beds_total: bedsTotal,
    baths_total: bathsTotal,
    structure_sqft: structureSqft,
    sold_price: soldPrice,
    simple_type: propertyType,
    list_price: listPrice,
    listing_agent_attribution: listingAgentAttribution,
    display_rules: listingDisplayRules,
    display_status: displayStatus,
    combined_display_status: combinedDisplayStatus,
    state,
    postal_code: postalCode,
    lot_size_value: lotSizeValue,
    lot_size_units: lotSizeUnits,
    slug,
    virtual_tour_urls: virtualTourUrls,
    mls_logo: mlsLogo,
    mls_display_name: mlsDisplayName,
    public_remarks: publicRemarks,
    short_details: shortDetails,
    selling_office: sellingOffice,
    tags,
  } = listing || {}

  const showSignUpModal =
    isExclusive && !loggedIn && !showingSignUp && displayRules.exclusives_protected

  const showClimateScoreWidget = !displayRules.suppress_climate_check_widget
  const showOpenHouses = openHouses?.total_count > 0

  const showListingAttributionUnderPhoto =
    listingDisplayRules?.show_attribution_under_photo && listingAgentAttribution

  const showHighlights = !!tags?.length
  const showFeedback = loggedIn && userInteractions

  if (loading) {
    return (
      <Box mt={5} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        <CircularProgress />
        <Box mt={2}>
          <Typography variant="h6">Loading...</Typography>
        </Box>
      </Box>
    )
  }

  if (unauthorized) {
    return <Unauthorized listing={listing} />
  }

  if (!listing) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="column"
        spacing={2}
        sx={{
          mt: 5,
          textAlign: 'center',
        }}
      >
        <Typography variant="h3">Listing unavailable.</Typography>
        <Typography variant="h5">
          The listing may no longer be available, you may not have access, or there may have been an
          error.
        </Typography>
        <Typography variant="h6">The details have been reported to our team.</Typography>
      </Stack>
    )
  }

  return (
    <Box key={propertyId} id="listing-detail">
      <Helmet title={`${address}, ${city}`} />
      <CollectionControls isModal={isModal} id={propertyId} />
      <ListingPhotos photos={photos} />
      {showListingAttributionUnderPhoto && (
        <Box>
          <Container>
            <Box sx={{ position: 'relative' }}>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="right"
                lineHeight="1"
                sx={{
                  position: 'absolute',
                  right: 0,
                  pt: 0.25,
                }}
              >
                Listed by {listingAgentAttribution}
              </Typography>
            </Box>
          </Container>
        </Box>
      )}

      <Header
        simpleListingStatus={simpleListingStatus}
        daysOnSite={daysOnSite}
        soldDate={soldDate}
        bedsTotal={bedsTotal}
        bathsTotal={bathsTotal}
        structureSqft={structureSqft}
        soldPrice={soldPrice}
        listPrice={listPrice}
        listingDisplayRules={listingDisplayRules}
        displayStatus={displayStatus}
        combinedDisplayStatus={combinedDisplayStatus}
        address={address}
        city={city}
        state={state}
        postalCode={postalCode}
        lotSizeValue={lotSizeValue}
        lotSizeUnits={lotSizeUnits}
        slug={slug}
      />

      <TabletAndDesktop>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              {agentComment && (
                <Box mb={3}>
                  <AgentRemarks comment={agentComment.comment} />
                </Box>
              )}
              <Box mb={3}>
                <MainDetails
                  virtualTourUrls={virtualTourUrls?.filter(Boolean) || []}
                  isExclusive={isExclusive}
                  simpleListingStatus={simpleListingStatus}
                  mlsLogo={mlsLogo}
                  mlsDisplayName={mlsDisplayName}
                  publicRemarks={publicRemarks}
                  shortDetails={shortDetails}
                  listingAgentAttribution={listingAgentAttribution}
                  sellingOfficeName={sellingOffice?.name}
                />
              </Box>

              {showOpenHouses && (
                <Box mb={3}>
                  <OpenHouses openHouses={openHouses} listingId={encodedId} address={address} />
                </Box>
              )}

              <Box mb={3}>
                <Map latitude={latitude} longitude={longitude} />
              </Box>
              <FullDetails fullDetailsProp={details} />
            </Grid>
            <Grid item xs={5}>
              {loggedIn ? (
                <Box mb={3}>
                  <Chat messages={messages} listing={listing} userId={userId} />
                  {agent.use_legacy_messenger ? (
                    <BrokerageChatWidget
                      listingConversation={userInteractions?.conversation}
                      userId={userId}
                      listingId={listing.id}
                    />
                  ) : (
                    <AgentChatWidget
                      listingConversation={userInteractions?.conversation}
                      agent={data?.current_user?.agent}
                      listingId={listing.id}
                      listingEncodedId={encodedId}
                      listingStatus={simpleListingStatus}
                    />
                  )}
                </Box>
              ) : (
                <SignUp listing={listing} />
              )}
              {showFeedback && (
                <Box mb={3}>
                  <Feedback
                    listingId={encodedId}
                    address={address}
                    feedback={userInteractions.feedback}
                  />
                </Box>
              )}
              {data.current_user.can_view_cost_calculator && (
                <MortgageCalculator
                  userId={data.current_user.id}
                  initialListingPrice={listing.list_price}
                  initialInterestRate={
                    mortgageRateBasisPoints ? mortgageRateBasisPoints / 100.0 : undefined
                  }
                  initialMortgageType={mortgageType || undefined}
                  initialDownPaymentPercentage={downPaymentAmount || undefined}
                  propertyType={propertyType}
                />
              )}
              {showHighlights && (
                <LazyLoad height={300}>
                  <Box mb={3}>
                    <Highlights listingTags={tags} address={address} />
                  </Box>
                </LazyLoad>
              )}

              <LazyLoad height={300}>
                <Box mb={3}>
                  <Schools propertyId={propertyId} />
                </Box>
              </LazyLoad>
              <Box mb={3}>
                <History listingHistory={propertyHistories} />
              </Box>
              {showClimateScoreWidget && (
                <LazyLoad>
                  <ClimateScore agentView={false} propertyId={propertyId} />
                </LazyLoad>
              )}
            </Grid>
          </Grid>
        </Box>
      </TabletAndDesktop>

      <Mobile>
        <Box p={2}>
          {agentComment && (
            <Box mb={3}>
              <AgentRemarks comment={agentComment.comment} />
            </Box>
          )}
          {loggedIn ? (
            <Box mb={3}>
              <Chat messages={messages} listing={listing} userId={userId} />
              {agent.use_legacy_messenger ? (
                <BrokerageChatWidget
                  listingConversation={userInteractions?.conversation}
                  userId={userId}
                  listingId={listing.id}
                />
              ) : (
                <AgentChatWidget
                  listingConversation={userInteractions?.conversation}
                  agent={data?.current_user?.agent}
                  listingId={listing.id}
                  listingEncodedId={encodedId}
                  listingStatus={simpleListingStatus}
                />
              )}
            </Box>
          ) : (
            <SignUp listing={listing} />
          )}

          {showOpenHouses && (
            <Box mb={3}>
              <OpenHouses openHouses={openHouses} listingId={encodedId} address={address} />
            </Box>
          )}
          <Box mb={3}>
            <MainDetails
              virtualTourUrls={virtualTourUrls?.filter(Boolean) || []}
              isExclusive={isExclusive}
              simpleListingStatus={simpleListingStatus}
              mlsLogo={mlsLogo}
              mlsDisplayName={mlsDisplayName}
              publicRemarks={publicRemarks}
              shortDetails={shortDetails}
              listingAgentAttribution={listingAgentAttribution}
              sellingOfficeName={sellingOffice?.name}
            />
          </Box>
          <Box mb={3}>
            <Map latitude={latitude} longitude={longitude} />
          </Box>
          {showFeedback && (
            <Box mb={3}>
              <Feedback
                listingId={encodedId}
                address={address}
                feedback={userInteractions.feedback}
              />
            </Box>
          )}
          <Box mb={3}>
            <FullDetails fullDetailsProp={details} />
          </Box>
          <LazyLoad height={300}>
            <Box mb={3}>
              <Schools propertyId={propertyId} />
            </Box>
          </LazyLoad>
          <History listingHistory={propertyHistories} />
          {showClimateScoreWidget && (
            <LazyLoad>
              <ClimateScore agentView={false} propertyId={propertyId} />
            </LazyLoad>
          )}
          {data.current_user.can_view_cost_calculator && (
            <MortgageCalculator
              userId={data.current_user.id}
              initialListingPrice={listing.list_price}
              initialInterestRate={
                mortgageRateBasisPoints ? mortgageRateBasisPoints / 100.0 : undefined
              }
              initialMortgageType={mortgageType || undefined}
              initialDownPaymentPercentage={downPaymentAmount || undefined}
              propertyType={propertyType}
            />
          )}
        </Box>
      </Mobile>
      <Footer listing={listing} />
      {showSignUpModal && (
        <SignUpModal
          showingSignUp
          closeSignUp={() => {}}
          title="Sign Up"
          subtitle="You must be logged in to view this exclusive listing"
        />
      )}
    </Box>
  )
}

export default ListingDetail
