import {gql, useQuery} from '@apollo/client';
import SuggestionsSection from "./SuggestionsSection";
import {SUGGESTED_LISTINGS_SECTIONS} from './../SuggestedListings';
import {reportError} from "../../../Common/ErrorCapture";

const MATCHED_QUERY = gql`query MatchesSearch($data: SearcherInput!) {
                      current_user {
                        id
                        searcher:matches_search(searcher_arguments: $data) {
                          listings {
                            id
                            encoded_id
                            address
                            display_price
                            slug
                            photos(first: 1){
                              edges{
                                node{
                                  id
                                  photo_url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  `;

const MatchedListings = () => {

  const {loading, data, refetch} = useQuery(MATCHED_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: reportError,
    variables: {
      data: {sort: 'NEWEST'}
    }
  });

  return (
      <SuggestionsSection
          loading={loading}
          listings={data && data.current_user && data.current_user.searcher.listings}
          pageName={SUGGESTED_LISTINGS_SECTIONS.matched}
      />
  )
}

export default MatchedListings;
