import { Link as RouterLink } from 'react-router-dom'
import { Box, Stack, Typography, Button, Container, Divider } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import CompareIcon from '@mui/icons-material/Compare'
import DisplayStatusBadge from '../DisplayStatusBadge'
import { HbAnalyticsClass } from '../../../../../Common/AnalyticClasses'
import DaysOnSiteBadge from '../DaysOnSiteBadge'

const DesktopHeader = ({
  bedsString,
  bathsString,
  sqftString,
  isSold,
  soldDate,
  formattedDisplayPrice,
  displayStatus,
  combinedDisplayStatus,
  showListedAtPrice,
  formattedListedAtPrice,
  address,
  city,
  state,
  postalCode,
  lotSizeValue,
  lotSizeUnits,
  slug,
  userLoggedIn,
  showDaysOnSite,
  daysOnSite,
}) => (
  <Container sx={{ py: 3 }}>
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h1" color={isSold ? 'text.error' : 'text.primary'}>
            {formattedDisplayPrice}
          </Typography>
          <DisplayStatusBadge
            displayStatus={displayStatus}
            combinedDisplayStatus={combinedDisplayStatus}
            soldDate={soldDate}
          />

          {showDaysOnSite && <DaysOnSiteBadge daysOnSite={daysOnSite} />}
        </Stack>
        {showListedAtPrice && (
          <Typography variant="body2" color="text.secondary">
            Listed at {formattedListedAtPrice}
          </Typography>
        )}
        <Typography variant="h4" color="text.secondary" pt={0.5}>
          {address}, {city}, {state} {postalCode}
        </Typography>
        <Stack direction="row" spacing={1} pt={2} divider={<Divider orientation="vertical" />}>
          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="h5" color="text.primary">
              {bedsString}
            </Typography>
            <Typography variant="h5" color="text.primary" fontWeight="500">
              beds
            </Typography>
          </Stack>

          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="h5" color="text.primary">
              {bathsString}
            </Typography>
            <Typography variant="h5" color="text.primary" fontWeight="500">
              baths
            </Typography>
          </Stack>

          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="h5" color="text.primary">
              {sqftString}
            </Typography>
            <Typography variant="h5" color="text.primary" fontWeight="500">
              sqft
            </Typography>
          </Stack>

          <Stack direction="row" spacing={0.5} alignItems="baseline">
            <Typography variant="h5" color="text.primary">
              {lotSizeValue}
            </Typography>

            <Typography variant="h5" color="text.primary" fontWeight="500">
              {lotSizeUnits} lot
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Stack direction="column" justifyContent="space-between">
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          {userLoggedIn && (
            <Button
              component={RouterLink}
              variant="outlined"
              size="small"
              to={`/compare/${slug}`}
              className={HbAnalyticsClass('compare', 'ldp')}
              startIcon={<CompareIcon />}
            >
              Compare
            </Button>
          )}

          <Button
            color="primary"
            size="small"
            variant="outlined"
            href={`/prints/${slug}`}
            aria-label="Print View"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Stack>
      </Stack>
    </Box>
  </Container>
)

export default DesktopHeader
