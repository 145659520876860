import { gql } from '@apollo/client'
import { Stack, Typography, Box, Alert, Divider, Grid, Link } from '@mui/material'
import MonitorIcon from '@mui/icons-material/Monitor'
import RsCard from '../../../../Common/UIComponents/Card/RsCard'
import { OFF_MARKET, UNKNOWN } from '../../../../Common/Constants/PropertyConstants'

export const LISTING_MAIN_DETAILS_FRAGMENT = gql`
  fragment listingMainDetailsFragment on Property {
    id
    virtual_tour_urls
    is_exclusive
    simple_listing_status
    mls_logo
    mls_display_name
    public_remarks
    short_details {
      label
      value
    }
    listing_agent_attribution
    selling_office {
      name
    }
  }
`

const ShortDetailItem = ({ label, value }) => (
  <Stack direction="row" spacing={1} flexWrap="wrap">
    <Typography color="text.secondary">{label}:</Typography>
    <Typography textAlign="left">{value}</Typography>
  </Stack>
)

const MainDetails = ({
  virtualTourUrls,
  isExclusive,
  simpleListingStatus,
  mlsLogo,
  mlsDisplayName,
  publicRemarks,
  shortDetails,
  listingAgentAttribution,
  sellingOfficeName,
}) => {
  const isNotExclusive = !isExclusive
  const isExclusiveAndOffMarket = isExclusive && [UNKNOWN, OFF_MARKET].includes(simpleListingStatus)

  return (
    <RsCard cardProps={{ sx: { boxShadow: 'none' } }}>
      <Box p={3}>
        <Stack direction="column" spacing={2} divider={<Divider />}>
          {isExclusiveAndOffMarket && (
            <Alert severity="warning">
              This exclusive listing is no longer active, but may still be available. Contact your
              agent for more information.
            </Alert>
          )}
          {isExclusive && (
            <Stack direction="row" spacing={2} alignItems="center">
              {mlsLogo && (
                <Box
                  component="img"
                  src={mlsLogo}
                  alt={`${mlsDisplayName} Logo`}
                  sx={theme => ({ width: '100px', borderRadius: theme.spacing(0.5) })}
                />
              )}
              <Box>
                <Typography fontWeight="bold">{mlsDisplayName} Listing</Typography>
                <Typography color="text.secondary">
                  Your agent has access to exclusive listings.
                </Typography>
              </Box>
            </Stack>
          )}
          <Box>
            <Typography>
              <div dangerouslySetInnerHTML={{ __html: publicRemarks }} />
            </Typography>

            {!!virtualTourUrls.length && (
              <Stack pt={2} direction="column" spacing={0.5}>
                {virtualTourUrls.map((vtLink, i) => (
                  <Box key={`${i}-${vtLink}`}>
                    <Link href={vtLink} target="_blank" rel="noopener noreferrer">
                      <Stack direction="row" spacing={0.75} alignItems="center">
                        <MonitorIcon sx={{ fontSize: '18px', verticalAlign: 'middle' }} />

                        <span>
                          {virtualTourUrls.length > 1
                            ? `View Virtual/3D Tour ${i + 1} (External Site)`
                            : `View Virtual/3D Tour (External Site)`}
                        </span>
                      </Stack>
                    </Link>
                  </Box>
                ))}
              </Stack>
            )}
          </Box>

          <Grid container rowSpacing={0.5}>
            {shortDetails.map(shortDetailItem => (
              <Grid item xs={12} sm={12} md={12} lg={6} key={shortDetailItem.label}>
                <ShortDetailItem label={shortDetailItem.label} value={shortDetailItem.value} />
              </Grid>
            ))}
          </Grid>
          {isNotExclusive && (
            <Box>
              <Stack spacing={1} direction="row" alignItems="center">
                {mlsLogo && (
                  <Box
                    component="img"
                    src={mlsLogo}
                    alt={`${mlsDisplayName} Logo`}
                    sx={theme => ({ width: '50px', borderRadius: theme.spacing(0.5) })}
                  />
                )}
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Source: {mlsDisplayName}
                  </Typography>
                  {listingAgentAttribution && (
                    <Typography variant="body2" color="text.secondary" className="mls-disclaimer">
                      Listing Offered By: {listingAgentAttribution}
                    </Typography>
                  )}
                  {sellingOfficeName && (
                    <Typography variant="body2" color="text.secondary">
                      Bought with {sellingOfficeName}
                    </Typography>
                  )}
                </Box>
              </Stack>

              <Typography variant="body2" color="text.secondary" pt={1}>
                Listing attribution displayed in accordance with NAR policies
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </RsCard>
  )
}

export default MainDetails
