import { RealScoutTheme } from './Theme'
import { createTheme } from '@mui/material'

const HomebuyerThemeWithOverrides = createTheme({ ...RealScoutTheme })

Object.assign(HomebuyerThemeWithOverrides.components.MuiTypography.styleOverrides.root, {
  color: HomebuyerThemeWithOverrides.palette.text.primary,
})

export default HomebuyerThemeWithOverrides
