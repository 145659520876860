import {
  OMNI_RESULTS_CATEGORIES,
  OMNI_TYPES,
} from '@/components/Common/Constants/OmnisearchConstants'

export const categorizeAndFormatSearchResults = data => {
  const properties = data.properties_by_query.edges.map(edge => ({
    value: edge.node.id,
    slug: edge.node.slug,
    label: `${edge.node.address}, ${edge.node.city} (${edge.node.mls_number})`,
    type: OMNI_RESULTS_CATEGORIES.ADDRESSES,
  }))

  const places = data.search_location.edges.map(edge => {
    const { es_id, name, sub_name, state, __typename } = edge.node
    const label = `${name}${sub_name ? `, ${sub_name}` : ''}${state ? `, ${state}` : ''}`
    return {
      value: es_id,
      label: label,
      type: OMNI_TYPES[__typename],
    }
  })

  return [...places, ...properties]
}
