import React, {useEffect, useState} from 'react'
import SplitLayout, {listingSearchFragment} from '../Common/SplitLayout';
import {withApollo} from '@apollo/client/react/hoc';
import {gql, useQuery} from '@apollo/client';
import {Helmet} from "react-helmet";
import {SplitLayoutProvider} from "../Contexts/SplitLayoutContext";
import {reportError} from "../../Common/ErrorCapture";
import {toast} from "react-toastify";

const sortOptions = [
  {label: 'Recent Status/Price Changes', value: 'SIGNIFICANT_CHANGE' },
  {label: 'Newest On Site', value: 'NEWEST'},
  {label: 'Lowest Price', value: 'PRICE_LOW'},
  {label: 'Highest Price', value: 'PRICE_HIGH'}
];

const NoSearchesMsg = ({closeToast, history}) => (
    <div className="text-center text-dark p-2">
      <h4>No Saved Searches Available</h4>
      <p>Save a search to keep track of your listings that match your needs.</p>
      <div className="text-center">
        <button onClick={closeToast} className="btn btn-primary">Start Searching</button>
      </div>
    </div>
)

const SAVED_SEARCHES_CHECK_QUERY = gql`
       query savedSearches{
         current_user{
           id
           saved_searches(only_active: true) {
             total_count
           }
           home_reports {
             total_count
           }
         }
       }
     `

const MATCHES_QUERY = gql`
      query MatchesSearch($data: SearcherInput!) {
            current_user {
              id
              searcher:matches_search(searcher_arguments: $data) {
                ...ListingSearch
              }
            }
          }
          ${listingSearchFragment}
`

const Matches = ({history}) => {

  const [selectedSort, setSort] = useState(sortOptions[0])
  const [bounds, setBounds] = useState()
  const boundParams = bounds || {};

  const {loading, data, refetch} = useQuery(MATCHES_QUERY, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    onError: reportError,
    variables: {
      data: {
        sort: selectedSort.value,
        ...boundParams
      }
    }
  });

  useQuery(SAVED_SEARCHES_CHECK_QUERY, {
    onCompleted: (data) => {
      const hasNoSavedSearches = data?.current_user?.saved_searches?.total_count === 0
      const hasNoHomeReports = data?.current_user?.home_reports?.total_count === 0
      if (hasNoSavedSearches && hasNoHomeReports) {
        toast(<NoSearchesMsg history={history}/>, {
          autoClose: false,
          position: toast.POSITION.TOP_CENTER,
          onClose: () => history.push({pathname: '/map'}),
          closeButton: false
        });
      }
    }
  })

  const searchData = (data && data.current_user) ? data.current_user : {};
  const title = searchData.searcher ? `My Matches (${searchData.searcher.total_count})` : 'My Matches'
  return (
      <div>
        <Helmet title="My Matches"/>
        <SplitLayoutProvider title={title}
                             searchData={searchData.searcher || {listings: []}}
                             onMapMove={setBounds}
                             sortOptions={sortOptions}
                             onSortChange={setSort}
                             selectedSort={selectedSort}
                             loading={loading}
        >
          <SplitLayout/>
        </SplitLayoutProvider>
      </div>
  );
}

export default withApollo(Matches);
