import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Button, Typography, Box, useTheme, useMediaQuery } from '@mui/material'
import { CollectionContext } from '../../Contexts/CollectionContext'

const PrevNextButton = ({ title, subtitle, ...btnProps }) => (
  <Button {...btnProps} size="small">
    <Box>
      <Typography
        fontWeight="600"
        lineHeight="1"
        fontSize="15px"
        color={btnProps.disabled ? 'text.disabled' : 'text.primary'}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body2"
          color="text.secondary"
          lineHeight="1"
          fontWeight="500"
          pt={0.25}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  </Button>
)

const CollectionControls = ({ id, isModal }) => {
  const theme = useTheme()
  const isNotMobile = useMediaQuery(theme.breakpoints.not('xs'))

  const {
    setCurrentListing,
    collection,
    previousListing,
    nextListing,
    currentListing,
    currentListingIndex,
  } = useContext(CollectionContext)

  useEffect(() => {
    setCurrentListing(id)
  }, [id])

  if (!currentListing) {
    return null
  }

  if (![currentListing.slug, currentListing.id].includes(id)) {
    return null
  }

  const hasPreviousListing = !!previousListing
  const hasNextListing = !!nextListing

  if (collection && (hasPreviousListing || hasNextListing)) {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
        <PrevNextButton
          disabled={!hasPreviousListing}
          component={Link}
          to={{
            pathname: `/listings/${previousListing?.slug}`,
            state: { modal: isModal },
          }}
          startIcon={
            <ArrowBackIosIcon
              sx={theme => ({ color: theme.colors.alpha.black[hasPreviousListing ? 70 : 50] })}
            />
          }
          title="Prev Listing"
          subtitle={hasPreviousListing ? previousListing.address : null}
        />

        {isNotMobile && (
          <Box textAlign="center">
            <Typography variant="h5" lineHeight="1" fontWeight="600">
              {currentListing.address}
            </Typography>
          </Box>
        )}

        <Box>
          <PrevNextButton
            disabled={!hasNextListing}
            component={Link}
            to={{
              pathname: `/listings/${nextListing?.slug}`,
              state: { modal: isModal },
            }}
            endIcon={
              <ArrowForwardIosIcon
                sx={theme => ({ color: theme.colors.alpha.black[hasNextListing ? 70 : 50] })}
              />
            }
            title="Next Listing"
            subtitle={hasNextListing ? nextListing.address : null}
          />
        </Box>
      </Box>
    )
  }

  return null
}

export default CollectionControls
