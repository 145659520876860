import { Component } from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import {Helmet} from "react-helmet";
import UserSentiments from "./MyProfile/UserSentiments";
import ReviewFeaturePreferences from "../Common/ReviewFeaturePreferences";
import Commutes from "./MyProfile/Commutes";

const MY_PROFILE_QUERY = gql `
  query FeaturePreferencesQuery{
    current_user{
      id
      feature_preferences{
        name
        sentiment
      }
      agent{
        id
        feature_preferences_options
      }
    }
  }
`;

class MyProfile extends Component {
  state = {
    featurePreferences: [],
  };

  updateFeaturePreferences(newFeaturePreferences) {
    this.setState({ featurePreferences: newFeaturePreferences })
  }

  render() {
    return (
        <div>
          <Helmet title="My Profile"/>
          <Query
            query={MY_PROFILE_QUERY}
            onCompleted={(data) => this.setState({ featurePreferences: data.current_user.feature_preferences})}
          >
            {({data, loading}) => {
              if (!loading && data && data.current_user) {
              const featurePrefForReview = data.current_user.agent ? data.current_user.agent.feature_preferences_options : []
                return (
                  <div className="row px-4 my-4">
                    <div className="col">
                      <div className="card">
                        <div className="card-header">What do you think about this feature?</div>
                        <div className="card-body py-2">
                          <ReviewFeaturePreferences
                            allFeaturePreferences={featurePrefForReview}
                            featurePreferences={this.state.featurePreferences}
                            updateFeaturePreferences={(newFeaturePreferences) => this.updateFeaturePreferences(newFeaturePreferences)}
                          />
                        </div>
                      </div>
                      <UserSentiments
                        featurePreferences={this.state.featurePreferences}
                        updateFeaturePreferences={(newFeaturePreferences) => this.updateFeaturePreferences(newFeaturePreferences)}
                      />
                    </div>
                    <div className="col">
                      <Commutes/>
                    </div>
                  </div>
                )
              }
              return null;
            }}
          </Query>
        </div>
    );
  }
}

export default MyProfile;
