import { Route, Redirect } from 'react-router-dom'
import { CurrentUserContext } from '../Contexts/CurrentUserContext'
import { useContext } from 'react'

function PrivateRoute({ component: Component, redirectTo, ...rest }) {
  const { loggedIn } = useContext(CurrentUserContext)
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn ? <Component {...props} /> : <Redirect to={{ pathname: redirectTo || '/map' }} />
      }
    />
  )
}

export default PrivateRoute
