import { useState, useContext, useRef } from 'react'
import { Map, TileLayer, Marker, FeatureGroup, LayersControl } from 'react-leaflet'
import { divIcon } from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import LazyLoad from 'react-lazyload'
import { Box, Alert, Link, useTheme, useMediaQuery } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import EastIcon from '@mui/icons-material/East'
import { gql } from '@apollo/client'
import { TILE_URL, MAP_ATTRIBUTION, SATELLITE_TILE_URL } from '../../../../Utils/MapUtils'
import Commutes from './Commutes'
import { CurrentUserContext } from '../../../Contexts/CurrentUserContext'
import RsCard from '../../../../Common/UIComponents/Card/RsCard'

export const LISTING_MAP_FRAGMENT = gql`
  fragment listingMapFragment on Property {
    id
    latitude
    longitude
  }
`

const ListingMap = ({ latitude, longitude }) => {
  const mapPinRef = useRef(null)
  const { loggedIn } = useContext(CurrentUserContext)
  const [zoom, setZoom] = useState(15)

  const theme = useTheme()
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'))

  if (longitude === 0 && latitude === 0) {
    return <Alert severity="error">We're unable to display this listing on a map.</Alert>
  }

  const position = [latitude, longitude]
  const icon = divIcon({
    type: 'div',
    className: `search-marker`,
    popupAnchor: [2, -25],
    iconSize: [20, 20],
    iconAnchor: [21, 21],
    html: ReactDOMServer.renderToString(<HomeIcon sx={{ height: '20px', width: '20px' }} />),
  })

  return (
    <RsCard cardProps={{ sx: { boxShadow: 'none' } }}>
      <LazyLoad height={300}>
        <Map
          center={position}
          zoom={zoom}
          style={{ height: 300 }}
          scrollWheelZoom={false}
          id="listing-detail-map"
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Streets" checked>
              <TileLayer attribution={MAP_ATTRIBUTION} url={TILE_URL} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer attribution={MAP_ATTRIBUTION} url={SATELLITE_TILE_URL} />
            </LayersControl.BaseLayer>
          </LayersControl>
          <FeatureGroup ref={mapPinRef}>
            <Marker position={[latitude, longitude]} icon={icon} />
          </FeatureGroup>
        </Map>

        {isLargerThanMd && (
          <Box display="flex" justifyContent="flex-end" px={2}>
            <Link
              variant="body2"
              href={`http://www.google.com/maps?layer=c&cbll=${latitude},${longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Street View
              <EastIcon sx={{ fontSize: '12px', ml: 0.5 }} />
            </Link>
          </Box>
        )}

        {loggedIn && <Commutes latitude={latitude} longitude={longitude} />}
      </LazyLoad>
    </RsCard>
  )
}

export default ListingMap
