import React from 'react'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import VerdictWrapper from './../VerdictWrapper'
import SplitListingPhoto from './SplitListingPhoto'
import lozad from 'lozad'
import ThumbUp from '@mui/icons-material/ThumbUp'
import ThumbDown from '@mui/icons-material/ThumbDown'
import { Button, Card } from '@mui/material'
import { useEffect } from 'react'
import { analytics } from '../../../Common/Constants/AnalyticsDataAttributes'
import { HbAnalyticsClass } from '../../../Common/AnalyticClasses'
import { Desktop, MobileAndTablet } from '../../../Common/ResponsiveComponents'

const SplitCard = ({ listing }) => {
  useEffect(() => {
    //   // Use lozad to observe dynamic content
    const observer = lozad()
    observer.observe()
  }, [])

  return (
    <Card variant="outlined" sx={{ height: 313 }}>
      <Desktop>
        <Link
          to={{ pathname: `/listings/${listing.slug}`, state: { modal: true } }}
          className={HbAnalyticsClass('listing-click', 'split-layout-card')}
        >
          <SplitListingPhoto listing={listing} useLozad={true} />
        </Link>
      </Desktop>
      <MobileAndTablet>
        <Link
          to={{ pathname: `/listings/${listing.slug}`, state: { modal: false } }}
          className={HbAnalyticsClass('listing-click', 'split-layout-card')}
        >
          <SplitListingPhoto listing={listing} useLozad={true} />
        </Link>
      </MobileAndTablet>
      <div className="card-body pb-0 pt-2 px-0 text-center d-flex flex-column">
        <h6 className="mb-1 text-truncate">
          {listing.beds_total || '-'} Beds&nbsp;&nbsp;&nbsp;{listing.baths_total || '-'}{' '}
          Baths&nbsp;&nbsp;&nbsp;
          {listing.structure_sqft ? numeral(listing.structure_sqft).format('0,0') : '-'} Sqft
        </h6>
        <p className="small mb-0 text-truncate">
          {listing.address}, {listing.city}
        </p>
        <div className="text-center mt-2">
          <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
            {({ onSave, isSaved, isLoading }) => (
              <Button
                variant={isSaved ? 'contained' : 'outlined'}
                color="success"
                disabled={isLoading}
                onClick={() => {
                  onSave()
                  const eventName = isSaved
                    ? analytics.listingPreviewCard.undoInterestedListing
                    : analytics.listingPreviewCard.interestedListing
                  window.posthog?.capture(eventName)
                }}
                size="small"
                aria-label="Mark as Interested"
                startIcon={<ThumbUp fontSize="small" />}
              >
                Interested
              </Button>
            )}
          </VerdictWrapper>
          &nbsp;
          <VerdictWrapper verdict={listing.verdict} listingEncodedId={listing.encoded_id}>
            {({ onHide, isHidden, isLoading }) => (
              <Button
                variant={isHidden ? 'contained' : 'outlined'}
                color="error"
                disabled={isLoading}
                onClick={() => {
                  onHide()
                  const eventName = isHidden
                    ? analytics.listingPreviewCard.undoHideListing
                    : analytics.listingPreviewCard.hideListing
                  window.posthog?.capture(eventName)
                }}
                aria-label="Mark as Not Interested"
                size="small"
              >
                <ThumbDown fontSize="small" />
              </Button>
            )}
          </VerdictWrapper>
        </div>
        {listing.mls_single_disclaimer && (
          <div className="text-truncate text-muted px-2">
            <small>{listing.mls_single_disclaimer}</small>
          </div>
        )}
      </div>
    </Card>
  )
}

export default SplitCard
